// Home page (Before user auth)
import { useState } from "react";
import Footer from "../Footer/footer";
import Navbar from "./Navbar";
import classes from "./style.module.css";
import {
  Image,
  Container,
  Title,
  Button,
  Group,
  Text,
  List,
  Modal,
  rem,
  ThemeIcon,
  Grid,
  Card,
} from "@mantine/core";
import image from "./image.svg";
import Registration from "../Registration";
import { IconCheck } from "@tabler/icons-react";

export default function HomePage(props) {
  const [registerOpen, setRegisterOpen] = useState(false);

  function handleRegistrationSuccess() {
    setRegisterOpen(false); // Close the registration modal
  }

  return (
    <div>
      <Navbar />
      <Modal
        opened={registerOpen}
        onClose={() => setRegisterOpen(false)}
        withCloseButton={false}
      >
        <Registration onSuccess={handleRegistrationSuccess} />
      </Modal>
      <Container size="md">
        <div className={classes.inner}>
          <div className={classes.content}>
            <Title className={classes.title}>
              <Text
                component="span"
                variant="gradient"
                gradient={{ from: "blue", to: "cyan" }}
                inherit
              >
                Auctions & Marketplace
              </Text>{" "}
              in One Place
            </Title>
            <Text c="dimmed" mt="md">
              Discover an all-in-one platform for auctions and marketplace
              transactions. Bid, buy, and sell with ease at Assethub
              Marketplace!
            </Text>

            <List
              mt={30}
              spacing="sm"
              size="sm"
              className={classes.list}
              icon={
                <ThemeIcon size={20} radius="xl">
                  <IconCheck
                    style={{ width: rem(12), height: rem(12) }}
                    stroke={1.5}
                  />
                </ThemeIcon>
              }
            >
              <List.Item>
                <b>Diverse Product Range</b> – Our platform features a wide
                array of products, from vintage collectibles to the latest
                gadgets, catering to a broad range of interests and tastes.
              </List.Item>
              <List.Item>
                <b>Real-Time Bidding</b> – Experience the thrill of real-time
                bidding, with instant notifications to keep you informed about
                competing offers and bid updates.
              </List.Item>
              <List.Item>
                <b>User-Friendly Interface</b> – We offer an intuitive,
                easy-to-navigate interface, ensuring a seamless and enjoyable
                user experience for both buyers and sellers.
              </List.Item>
            </List>

            <Group mt={30}>
              <Button
                radius="xl"
                size="md"
                className={classes.control}
                onClick={() => setRegisterOpen(true)}
              >
                Get started
              </Button>
            </Group>
          </div>
          <Image src={image} className={classes.image} />
        </div>

        <div className={classes.howItWorksContainer}>
          <Title className={classes.title} style={{ marginBottom: "30px" }}>
            How it works
          </Title>
          <Grid grow>
            <Grid.Col span={4}>
              <Card
                shadow="sm"
                padding="xl"
                component="a"
                target="_blank"
                h="100%"
              >
                <Card.Section>
                  <Image
                    src="https://images.unsplash.com/photo-1516321318423-f06f85e504b3?auto=format&fit=crop&q=80&w=2670&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt="Find something that you like"
                    fit="contain"
                  />
                </Card.Section>

                <Text fw={500} size="lg" mt="md">
                  Step 1: Identify what you want
                </Text>

                <Text mt="xs" c="dimmed" size="sm">
                  Find out which item interest you before or during the auction.
                  Attend viewing beforehand to deem the condition of the item.
                </Text>
              </Card>
            </Grid.Col>
            <Grid.Col span={4}>
              <Card
                shadow="sm"
                padding="xl"
                component="a"
                target="_blank"
                h="100%"
              >
                <Card.Section>
                  <Image
                    src="https://images.unsplash.com/photo-1593115057322-e94b77572f20?auto=format&fit=crop&q=80&w=2671&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt="Bid or set up auto-bid"
                    fit="contain"
                  />
                </Card.Section>

                <Text fw={500} size="lg" mt="md">
                  Step 2: Bid for the item
                </Text>

                <Text mt="xs" c="dimmed" size="sm">
                  When the auction starts, log into the system to start bidding.
                  Set up auto-bid to let the system bid for you.
                </Text>
              </Card>
            </Grid.Col>
            <Grid.Col span={4}>
              <Card
                shadow="sm"
                padding="xl"
                component="a"
                target="_blank"
                h="100%"
              >
                <Card.Section>
                  <Image
                    src="https://images.unsplash.com/photo-1423666639041-f56000c27a9a?auto=format&fit=crop&q=80&w=2674&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt="Payment"
                    fit="contain"
                  />
                </Card.Section>

                <Text fw={500} size="lg" mt="md">
                  Step 3: Make payment
                </Text>

                <Text mt="xs" c="dimmed" size="sm">
                  We will issue an invoice to you and you will need to make
                  payment within 7 days either through PayNow or bank transfer.
                  We will then provide instructions on how you can collect the
                  items.
                </Text>
              </Card>
            </Grid.Col>
          </Grid>
        </div>
      </Container>
      <Footer />
    </div>
  );
}
