import { useState, useEffect } from "react";
import { supabase } from "../../supabase/supabaseClient";
import {
  Box,
  Header,
  Group,
  Grid,
  Container,
  Text,
  Space,
  Card,
  Table,
  Badge,
} from "@mantine/core";

export default function Onsite(props) {
  const [onsiteData, setOnsiteData] = useState([]);
  const [unsoldData, setUnsoldData] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    // All data
    const fetchOnsiteData = async () => {
      const { data, error } = await supabase
        .from("live_auction")
        .select()
        .order("lot_no", { ascending: true });

      if (data && data.length > 0) {
        setOnsiteData(data);

        // Create table data
        const row = data.map((element) => (
          <tr key={element.lot_no}>
            <td>
              <Text size="xl">{element.lot_no}</Text>
            </td>
            <td>
              <Text size="xl">{element.item_name}</Text>
            </td>
            <td>
              <Text size="xl">{element.bidding_price.toFixed(2)}</Text>
            </td>
            <td>
              <Text size="xl">{element.sold_to_paddle}</Text>
            </td>
            <td>
              {element.status === "sold" ? (
                <Badge size="xl" color="green">
                  Sold
                </Badge>
              ) : element.status === "unsold" ? (
                <Badge size="xl" color="yellow">
                  Unsold
                </Badge>
              ) : (
                <Badge size="xl" color="gray">
                  Void
                </Badge>
              )}
            </td>
          </tr>
        ));
        setTableData(row);
      }

      if (error) {
        console.log(error);
      }
    };

    // Unsold data
    const fetchOnsiteDataUnsold = async () => {
      const { data, error } = await supabase
        .from("live_auction")
        .select()
        .eq("status", "unsold")
        .order("lot_no", { ascending: true });

      if (data && data.length > 0) {
        setUnsoldData(data);
      }

      if (error) {
        console.log(error);
      }
    };

    fetchOnsiteData();
    fetchOnsiteDataUnsold();

    // Function to revalidate the state when relevant tables are updated
    const revalidateStateOnTableUpdate = () => {
      fetchOnsiteData();
      fetchOnsiteDataUnsold();
    };

    const onsiteDataSubscription = supabase
      .channel("onsite_data_change")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "live_auction",
        },
        (payload) => revalidateStateOnTableUpdate()
      )
      .subscribe();
  }, []);

  if (onsiteData && onsiteData.length > 0) {
    return (
      <div>
        <Box pb={30}>
          <Header height={60} px="md">
            <Group position="apart" sx={{ height: "100%" }}>
              <img
                alt="Logo"
                style={{ width: 228, height: 53 }}
                src="https://i1.wp.com/assethub.sg/wp-content/uploads/2019/02/cropped-AssetHub-Logo-Rec-e1551345217372-1.png?fit=3299%2C802"
              />
            </Group>
          </Header>
        </Box>

        <Grid>
          <Grid.Col span={6}>
            <Container>
              <Card shadow="sm" padding={0} radius="md" withBorder>
                {unsoldData.length > 0 ? (
                  <>
                    <img
                      src={unsoldData[0].image_url}
                      width="auto"
                      height={700}
                      alt={unsoldData[0].item_name}
                    />
                    <Container>
                      <Text size="35px" td="underline">
                        Lot {unsoldData[0].lot_no}
                      </Text>
                      <Text size="40px">{unsoldData[0].item_name}</Text>
                      <Space h="xl" />
                      <Text size="50px" color="blue">
                        {unsoldData[0].bidding_price
                          ? `Current Bid: ${unsoldData[0].bidding_price.toFixed(
                              2
                            )}`
                          : "Current Bid: -"}
                      </Text>
                    </Container>
                  </>
                ) : (
                  <>
                    <img
                      src="https://mmbgljfxwvdcgkjwhwre.supabase.co/storage/v1/object/public/Documents/Onsite/DSC01167.jpg"
                      width="auto"
                      height={700}
                      alt="SJL6145U - Hyundai / HD Avante 1.6A S/R (Refer to catalogue)"
                    />
                    <Container>
                      <Text size="35px" td="underline">
                        Lot 12
                      </Text>
                      <Text size="40px">
                        SJL6145U - Hyundai / HD Avante 1.6A S/R (Refer to
                        catalogue)
                      </Text>
                      <Space h="xl" />
                      <Text size="50px" color="blue">
                        {`Current Bid: ${onsiteData[11].bidding_price}`}
                      </Text>
                    </Container>
                  </>
                )}
              </Card>
            </Container>
          </Grid.Col>

          <Grid.Col span={6}>
            <Container>
              <Table verticalSpacing="sm" withColumnBorders withBorder>
                <thead>
                  <tr>
                    <th>
                      <Text size="xl">Lot Number</Text>
                    </th>
                    <th>
                      <Text size="xl">Item Name</Text>
                    </th>
                    <th>
                      <Text size="xl">Bidding Price</Text>
                    </th>
                    <th>
                      <Text size="xl">Sold to Paddle</Text>
                    </th>
                    <th>
                      <Text size="xl">Status</Text>
                    </th>
                  </tr>
                </thead>
                <tbody>{tableData}</tbody>
              </Table>
            </Container>
          </Grid.Col>
        </Grid>
      </div>
    );
  }
}
