import { Button, Flex, Paper, PasswordInput, TextInput } from "@mantine/core";
import { useAuth } from "../auth";
import { supabase } from "../../supabase/supabaseClient";
import { useState } from "react";
import { Notifications } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";

export default function ResetPassword(props) {
  const { user } = useAuth();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  // Reset password
  async function resetPasswordFunc(email) {
    const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: "http://localhost:3000/reset",
    });
  }
  const form = useForm({
    initialValues: {
      password: "",
      confirmPassword: "",
    },

    validate: {
      password: (val) =>
        val.length <= 7
          ? "Password should include at least 8 characters"
          : null,

      confirmPassword: (value, values) =>
        value !== values.password ? "Passwords did not match" : null,
    },
  });

  async function resetPasswordConfirmed(finalPassword) {
    const { data, error } = await supabase.auth.updateUser({
      password: finalPassword,
    });
    Notifications.show({
      color: "green",
      title: "Password successfully changed!",
      autoClose: 5000,
    });
    navigate("/");
  }

  if (user) {
    return (
      <div>
        <Flex
          mih={500}
          gap="sm"
          justify="center"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Paper shadow="xs" p="md" w={350}>
            <form
              onSubmit={form.onSubmit((e) => {
                resetPasswordConfirmed(e.password);
              })}
            >
              <PasswordInput
                label="New Password"
                required
                {...form.getInputProps("password")}
                mb={10}
              />
              <PasswordInput
                label="Confirm Password"
                required
                {...form.getInputProps("confirmPassword")}
                mb={10}
              />
              <Button type="submit" fullWidth>
                Save
              </Button>
            </form>
          </Paper>
        </Flex>
      </div>
    );
  } else {
    return (
      <div>
        <Flex
          mih={500}
          gap="sm"
          justify="center"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Paper shadow="xs" p="md">
            <form
              onSubmit={function () {
                resetPasswordFunc(email);
                Notifications.show({
                  color: "green",
                  title: "Check your email to reset your password",
                  autoClose: false,
                });
                navigate("/");
              }}
            >
              <TextInput
                mb={10}
                withAsterisk
                required
                label="Your email"
                pattern="^\S+@\S+\.\S+$"
                title="Please key in a valid email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button w="100%" type="submit">
                Submit
              </Button>
            </form>
          </Paper>
        </Flex>
      </div>
    );
  }
}
