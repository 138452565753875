import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./components/router/PrivateRoute";
import PrivateRouteAdmin from "./components/router/PrivateRouterAdmin";
import { AuthProvider } from "./components/auth";
import MainPage from "./components/MainPage/MainPage";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import UserManagement from "./components/UserManagement/UserManagement";
import ResetPassword from "./components/ResetPasswordPage/ResetPassword";
import ErrorPage from "./components/404/404";
import EventManagement from "./components/EventManagement/EventManagement";
import AddItemPage from "./components/EventManagement/AddItemPage";
import ShowEvent from "./components/MainPage/Event/showEvent";
import Auction from "./components/MainPage/Auction/auction";
import BuyerInvoiceManagement from "./components/InvoiceManagement/BuyerInvoiceManagement";
import SellerInvoiceManagement from "./components/InvoiceManagement/SellerInvoiceManagement";
import Onsite from "./components/Onsite/Onsite";
import AboutUs from "./components/AboutUs/AboutUs";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <Notifications />
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route exact path="/front" element={<PrivateRoute />}>
              <Route exact path="/front" element={<MainPage />} />
            </Route>
            <Route exact path="/front" element={<PrivateRoute />}>
              <Route
                exact
                path="/front/event/:eventID"
                element={<ShowEvent />}
              />
            </Route>
            <Route exact path="/front" element={<PrivateRoute />}>
              <Route exact path="/front/onsite" element={<PrivateRouteAdmin />}>
                <Route exact path="/front/onsite" element={<Onsite />} />
              </Route>
            </Route>
            <Route exact path="/front" element={<PrivateRoute />}>
              <Route
                exact
                path="/front/auction/:eventID"
                element={<Auction />}
              />
            </Route>
            <Route exact path="/front" element={<PrivateRoute />}>
              <Route
                exact
                path="/front/user-management"
                element={<PrivateRouteAdmin />}
              >
                <Route
                  exact
                  path="/front/user-management"
                  element={<UserManagement />}
                />
              </Route>
            </Route>
            <Route exact path="/front" element={<PrivateRoute />}>
              <Route
                exact
                path="/front/seller-invoice-management"
                element={<PrivateRouteAdmin />}
              >
                <Route
                  exact
                  path="/front/seller-invoice-management"
                  element={<SellerInvoiceManagement />}
                />
              </Route>
            </Route>
            <Route exact path="/front" element={<PrivateRoute />}>
              <Route
                exact
                path="/front/buyer-invoice-management"
                element={<PrivateRouteAdmin />}
              >
                <Route
                  exact
                  path="/front/buyer-invoice-management"
                  element={<BuyerInvoiceManagement />}
                />
              </Route>
            </Route>
            <Route exact path="/front" element={<PrivateRoute />}>
              <Route
                exact
                path="/front/event-management"
                element={<PrivateRouteAdmin />}
              >
                <Route
                  exact
                  path="/front/event-management"
                  element={<EventManagement />}
                />
              </Route>
            </Route>
            <Route exact path="/front" element={<PrivateRoute />}>
              <Route
                exact
                path="/front/event-management/:eventID"
                element={<PrivateRouteAdmin />}
              >
                <Route
                  exact
                  path="/front/event-management/:eventID"
                  element={<AddItemPage />}
                />
              </Route>
            </Route>
            <Route path="/" element={<App />} />
            <Route path="/home/about-us" element={<AboutUs />} />
            <Route path="/reset" element={<ResetPassword />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </MantineProvider>
  </React.StrictMode>
);
