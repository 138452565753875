import { supabase } from "../../supabase/supabaseClient";

// Change the column value in event_items when the status is changed to completed
// Status changed to sold and unsold. Final price and bidder_no are also updated
export default async function handleCompletion(actual_event_id) {
  try {
    const { error } = await supabase.rpc("auction_complete_func", {
      p_event_id: actual_event_id,
    });
    if (error) {
      console.error("Error calling SQL function:", error.message);
    }
  } catch (error) {
    console.error("Error:", error.message);
  }
}
