import { supabase } from "../../supabase/supabaseClient";
import { useEffect, useState } from "react";
import { Anchor, Text, ScrollArea, Table } from "@mantine/core";
import LoadingScreen from "../LoadingScreen/LoadingScreen";

function convertTime(time) {
  // UTC timestamp string
  var utcTimestamp = time;

  // Convert UTC timestamp to JavaScript Date object
  var utcDate = new Date(utcTimestamp);

  // Define Singapore timezone offset in minutes (UTC+8)
  var sgTimezoneOffset = 480;

  // Calculate Singapore timestamp by adding the timezone offset
  var sgTimestamp = new Date(utcDate.getTime() + sgTimezoneOffset * 60 * 1000);

  // Format Singapore timestamp as "dd-mm-yyyy HH:MM"
  var formattedTimestamp = sgTimestamp.toLocaleDateString("en-SG", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  // Output the result
  return formattedTimestamp;
}

export default function CompletedTable(props) {
  const [eventData, setEventData] = useState(null);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const fetchEventData = async () => {
      setReload(true);
      const { data, error } = await supabase
        .from("event")
        .select()
        .eq("status", "completed")
        .order("start_datetime", { ascending: true });
      if (data) {
        setEventData(data);
      }

      if (error) {
        setEventData(null);
      }
    };

    fetchEventData();
    setReload(false);
  }, [reload]);

  if (eventData) {
    const rows = eventData.map((item) => (
      <tr key={item.event_id}>
        <td>
          <Anchor component="button" size="sm">
            {convertTime(item.start_datetime)}
          </Anchor>
        </td>
        <td>
          <Anchor component="button" size="sm">
            {convertTime(item.end_datetime)}
          </Anchor>
        </td>
        <td>
          <Anchor component="button" size="sm">
            {item.name}
          </Anchor>
        </td>
        <td>
          <Anchor component="button" size="sm">
            {item.event_type}
          </Anchor>
        </td>
        <td>
          <Anchor component="button" size="sm">
            {item.status}
          </Anchor>
        </td>
        <td>
          <Anchor component="button" size="sm">
            {item.gst_percentage}
          </Anchor>
        </td>
        <td>
          <Anchor component="button" size="sm">
            {item.buyer_premium_percentage}
          </Anchor>
        </td>
        <td>
          <Anchor component="button" size="sm">
            {item.consignment_fee_percentage}
          </Anchor>
        </td>
        <td>
          <Anchor component="button" size="sm">
            {item.overtime_extension_min}
          </Anchor>
        </td>
        <td>
          <Anchor component="button" size="sm">
            {item.display_reserved_not_met ? "Yes" : "No"}
          </Anchor>
        </td>
      </tr>
    ));

    return (
      <div>
        <ScrollArea>
          <Text fz="xl" fw={700} mb={25}>
            Completed Events
          </Text>
          <Table
            sx={{ minWidth: 800 }}
            verticalSpacing="sm"
            withBorder
            withColumnBorders
            highlightOnHover
          >
            <thead>
              <tr>
                <th>Start Datetime</th>
                <th>End Datetime</th>
                <th>Name</th>
                <th>Type</th>
                <th>Status</th>
                <th>GST %</th>
                <th>Buyer Premium %</th>
                <th>Consignment Fee %</th>
                <th>Overtime Extension (Min)</th>
                <th>Display Reserved Price Not Met</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </ScrollArea>
      </div>
    );
  } else {
    return (
      <div>
        <div>
          <LoadingScreen />
        </div>
      </div>
    );
  }
}
