import { useParams } from "react-router-dom";
import Navbar from "../MainPage/Navbar";
import { useEffect, useState, useRef } from "react";
import { useAuth } from "../auth";
import { supabase } from "../../supabase/supabaseClient";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import {
  Text,
  Paper,
  Center,
  SimpleGrid,
  ActionIcon,
  TextInput,
  Anchor,
  FileButton,
  Group,
  rem,
  Modal,
  Tooltip,
  Button,
  Table,
  ScrollArea,
  createStyles,
} from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import { Dropzone, MS_EXCEL_MIME_TYPE } from "@mantine/dropzone";
import {
  IconUpload,
  IconX,
  IconPhoto,
  IconHelpCircleFilled,
} from "@tabler/icons-react";
import ErrorPage from "../404/404";
import { read, utils } from "xlsx";
import { Notifications } from "@mantine/notifications";
import { useDisclosure } from "@mantine/hooks";
import "handsontable/dist/handsontable.full.min.css";
import { registerAllModules } from "handsontable/registry";
import { HotTable } from "@handsontable/react";
import "handsontable/dist/handsontable.full.css";
registerAllModules();

function convertTime(time) {
  // UTC timestamp string
  var utcTimestamp = time;

  // Convert UTC timestamp to JavaScript Date object
  var utcDate = new Date(utcTimestamp);

  // Define Singapore timezone offset in minutes (UTC+8)
  var sgTimezoneOffset = 480;

  // Calculate Singapore timestamp by adding the timezone offset
  var sgTimestamp = new Date(utcDate.getTime() + sgTimezoneOffset * 60 * 1000);

  // Format Singapore timestamp as "dd-mm-yyyy HH:MM"
  var formattedTimestamp = sgTimestamp.toLocaleDateString("en-SG", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  // Output the result
  return formattedTimestamp;
}

function addMinutesToEndTime(times, end_datetime) {
  // Convert the original time to JavaScript Date object
  var originalDate = new Date(end_datetime);

  var sgTimezoneOffset = 480;

  // Add 10 minutes to the original time
  var newDate = new Date(
    originalDate.getTime() + (times * 1 + sgTimezoneOffset) * 60 * 1000
  );

  // Get the updated time string
  var updatedTime = newDate.toISOString();

  return updatedTime;
}

// Function to check if the file name is in the format "number_number.jpg" or "number_number.png"
const isValidFileName = (fileName) => {
  const pattern = /^\d+_\d+\.(jpg|png|jpeg)$/i;
  return pattern.test(fileName);
};

const extractFileName = (url) => {
  if (!url) {
    return "Empty";
  }

  const fileNameStartIndex = url.lastIndexOf("/") + 1;
  const fileName = url.substring(fileNameStartIndex + 11);
  return fileName;
};

function generateRandomNumber() {
  const randomNumber = Math.floor(Math.random() * 9000000000) + 1000000000;
  return randomNumber.toString();
}

const extractNumber = (fileName) => {
  const match = fileName.match(/^\d+_\d+/);
  return match ? match[0] : "";
};

const extractDigits = (fileName) => {
  const match = fileName.match(/^\d+/);
  return match ? match[0] : "";
};

function replaceLastNumberWithOne(url) {
  const regex = /(\d+)$/; // Match one or more digits at the end of the string
  const match = url.match(regex);

  if (match) {
    const lastNumber = match[0];
    const newLastNumber = "1";
    const updatedURL = url.replace(regex, newLastNumber);
    return updatedURL;
  } else {
    // If there is no number at the end of the URL, return the original URL
    return url;
  }
}

const useStyles = createStyles((theme) => ({
  disabled: {
    backgroundColor: theme.colors.gray[0],
    borderColor: theme.colors.gray[2],
    cursor: "not-allowed",

    "& *": {
      color: theme.colors.gray[5],
    },
  },
}));

export default function AddItemPage(props) {
  let params = useParams();
  const [eventData, setEventData] = useState(null);
  const [catalogTable, setCatalogTable] = useState(null);
  const [errorCode, setErrorCode] = useState(null);
  const [fileName, setFileName] = useState("Empty");
  const [catalogFileName, setCatalogFileName] = useState("Empty");
  const [incrementData, setIncrementData] = useState(null);
  const [promoFileName, setPromoFileName] = useState("Empty");
  const [reload, setReload] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [catalogErrorMessage, setCatalogErrorMessage] = useState("");
  const [promoErrorMessage, setPromoErrorMessage] = useState("");
  const [opened, { open, close }] = useDisclosure(false);
  const [catalogData, setCatalogData] = useState([]);
  const [registeredBidder, setRegisteredBidder] = useState(null);
  const hotRef = useRef(null);
  const hotRefIncrement = useRef(null);
  const randomNumber = generateRandomNumber();
  const { user } = useAuth();
  const { classes } = useStyles();
  const [itemImages, setItemImages] = useState(null);

  async function fetchImageUrls(itemId) {
    const { data, error } = await supabase
      .from("images")
      .select("url")
      .filter("event_id", "eq", params.eventID)
      .filter("item_lot_no", "eq", itemId);
    if (data && data.length > 0) {
      return data.map((item) => item.url);
    } else {
      return [
        "https://mmbgljfxwvdcgkjwhwre.supabase.co/storage/v1/object/public/Documents/Images/Image_not_available.png",
      ];
    }
  }

  useEffect(() => {
    setReload(true);

    const fetchData = async () => {
      try {
        const [
          eventResponse,
          tableResponse,
          incrementResponse,
          bidderResponse,
        ] = await Promise.all([
          supabase.from("event").select().eq("event_id", params.eventID),
          supabase
            .from("event_items")
            .select()
            .eq("event_id", params.eventID)
            .order("item_lot_no", { ascending: true }),
          supabase
            .from("bidding_increment")
            .select()
            .eq("event_id", params.eventID),
          supabase
            .from("bidder")
            .select(
              `
            bidder_no,
            created_on,
            users (name)
          `
            )
            .eq("event_id", params.eventID),
        ]);

        if (eventResponse.data) {
          setEventData(eventResponse.data);
          setErrorCode(null);
        }

        if (tableResponse.data) {
          setCatalogTable(tableResponse.data);
          // Fetch image URLs for each item using Promise.all
          const itemPromises = tableResponse.data.map((item) =>
            fetchImageUrls(item.item_lot_no)
          );
          const itemImages = await Promise.all(itemPromises);
          setItemImages(itemImages);
        }

        if (incrementResponse.data) {
          setIncrementData(incrementResponse.data);
        }

        if (bidderResponse.data) {
          setRegisteredBidder(bidderResponse.data);
        }

        setReload(false);
      } catch (error) {
        setErrorCode(error.code);
        setEventData(null);
        setCatalogTable(null);
        setIncrementData(null);
        setRegisteredBidder(null);
      }
    };

    fetchData();
  }, [reload]);

  const bidderRows = registeredBidder
    ? registeredBidder.map((item) => (
        <tr key={item.uuid}>
          <td>{item.bidder_no}</td>
          <td>{item.users.name}</td>
          <td>{convertTime(item.created_on)}</td>
        </tr>
      ))
    : [];

  if (eventData && !errorCode && itemImages) {
    const rows = catalogTable
      ? catalogTable.map((item, index) => (
          <tr key={item.item_id}>
            <td>{item.item_lot_no}</td>
            <td>{item.category}</td>
            <td>{item.name}</td>
            <td>{item.starting_price}</td>
            <td>{item.reserved_price}</td>
            <td>{item.estimate_min}</td>
            <td>{item.estimate_max}</td>
            <td>{item.status}</td>
            <td>{item.batch}</td>
            <td>{convertTime(item.item_end_time)}</td>
            <td>
              {itemImages && itemImages[index] ? (
                <Carousel
                  maw={200}
                  mx="auto"
                  withIndicators
                  styles={{
                    control: {
                      "&[data-inactive]": {
                        opacity: 0,
                        cursor: "default",
                      },
                    },
                  }}
                >
                  {itemImages[index].map((imageUrl, imageIndex) => (
                    <img
                      key={imageIndex}
                      src={
                        imageUrl
                          ? `${imageUrl}?${Date.now()}`
                          : "https://mmbgljfxwvdcgkjwhwre.supabase.co/storage/v1/object/public/Documents/Images/Image_not_available.png"
                      }
                      alt={item.name}
                      style={{ width: "100%", height: "100%" }}
                    />
                  ))}
                </Carousel>
              ) : (
                // Render a placeholder or empty state when itemImages is not available
                <div>No images available</div>
              )}
            </td>
          </tr>
        ))
      : [];

    return (
      <div>
        <Navbar />
        <Modal opened={opened} onClose={close} title="Edit" size="80%">
          <HotTable
            data={catalogData}
            ref={hotRef}
            rowHeaders={true}
            colHeaders={[
              "LOT_NO",
              "CATEGORIES",
              "NAME",
              "STARTING_PRICE",
              "RESERVED_PRICE",
              "ESTIMATE_PRICE_MIN",
              "ESTIMATE_PRICE_MAX",
              "STATUS",
              "BATCH",
            ]}
            height={500}
            width="auto"
            colWidths={[75, 100, 300, 130, 130, 180, 180, 75, 75]}
            contextMenu={true}
            licenseKey="non-commercial-and-evaluation" // for non-commercial use only
            columns={[
              {
                data: "LOT_NO",
                type: "numeric",
              },
              {
                data: "CATEGORIES",
                type: "dropdown",
                source: [
                  "Art Painting",
                  "Bicycles",
                  "Branded Watch",
                  "Camera",
                  "Coins",
                  "Construction Machine",
                  "Costume Jewellery",
                  "Electronics",
                  "General Item",
                  "Jewellery",
                  "Normal Watch",
                  "Used Premium Watch",
                  "Vehicle",
                ],
              },
              {
                data: "NAME",
              },
              {
                data: "STARTING_PRICE",
              },
              {
                data: "RESERVED_PRICE",
              },
              {
                data: "ESTIMATE_PRICE_MIN",
              },
              {
                data: "ESTIMATE_PRICE_MAX",
              },
              {
                data: "STATUS",
                type: "dropdown",
                source: ["Active", "Inactive", "Void", "Completed"],
              },
              {
                data: "BATCH",
                type: "dropdown",
                source: Array.from({ length: 100 }, (v, k) => k + 1),
              },
            ]}
          />
          <Button
            mt={20}
            w="100%"
            onClick={async function () {
              const finalArray = hotRef.current.hotInstance.getData();
              const transformedJson = finalArray.map((item) => {
                return {
                  item_lot_no: item[0],
                  event_id: params.eventID,
                  category: item[1],
                  name: item[2],
                  starting_price: item[3],
                  reserved_price: item[4],
                  estimate_min: item[5],
                  estimate_max: item[6],
                  status: item[7],
                  batch: item[8],
                  created_by: user.id,
                  item_end_time: addMinutesToEndTime(
                    item[8] - 1,
                    eventData[0].end_datetime
                  ),
                  item_final_end_time: addMinutesToEndTime(
                    item[8] - 1,
                    eventData[0].end_datetime
                  ),
                };
              });

              // Delete existing event id first (Edit feature)
              await supabase
                .from("event_items")
                .delete()
                .eq("event_id", params.eventID);

              const { error } = await supabase
                .from("event_items")
                .insert(transformedJson);
              setReload(true);
              close();
              Notifications.show({
                color: "green",
                title: "Catalog uploaded successfully.",
                autoClose: 5000,
              });
            }}
          >
            Save
          </Button>
        </Modal>
        <Center>
          <Paper shadow="md" p="md" radius="md" mb={50} w="60%">
            <Center>
              <h2>{eventData[0].name}</h2>
            </Center>
            <SimpleGrid cols={4}>
              <div>
                <Text>
                  <b>Start Datetime: </b>
                  {convertTime(eventData[0].start_datetime)}
                </Text>
              </div>
              <div>
                <Text>
                  <b>End Datetime: </b>
                  {convertTime(eventData[0].end_datetime)}
                </Text>
              </div>
              <div>
                <Text>
                  <b>Status: </b>
                  {eventData[0].status}
                </Text>
              </div>
              <div>
                <Text>
                  <b>Type: </b>
                  {eventData[0].event_type}
                </Text>
              </div>
              <div>
                <Text>
                  <b>GST (%): </b>
                  {eventData[0].gst_percentage}
                </Text>
              </div>
              <div>
                <Text>
                  <b>Buyer Premium (%): </b>
                  {eventData[0].buyer_premium_percentage}
                </Text>
              </div>
              <div>
                <Text>
                  <b>Consignment Fee (%): </b>
                  {eventData[0].consignment_fee_percentage}
                </Text>
              </div>
              <div>
                <Text>
                  <b>Overtime extension (Min): </b>
                  {eventData[0].overtime_extension_min}
                </Text>
              </div>
              <div>
                <Text>
                  <b>Total Lots: </b>
                  {catalogTable ? catalogTable.length : 0}
                </Text>
              </div>
              <div>
                <Text>
                  <b>Total Registered Bidders: </b>
                  {registeredBidder ? registeredBidder.length : 0}
                </Text>
              </div>
              <div>
                <Text>
                  <b>Display Reserved Price Not Met: </b>
                  {eventData[0].display_reserved_price_not_met ? "Yes" : "No"}
                </Text>
              </div>
              <div>
                <Text>
                  <b>Created on: </b>
                  {convertTime(eventData[0].created_on)}
                </Text>
              </div>
            </SimpleGrid>
          </Paper>
        </Center>
        <Center>
          <Paper shadow="md" p="md" radius="md" w="60%" mb={50}>
            <Center>
              <h2>Files</h2>
            </Center>
            <Group
              align="flex-end"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Anchor href={eventData[0].tos_url}>
                <TextInput
                  w="100%"
                  label="TOS"
                  value={extractFileName(eventData[0].tos_url)}
                  error={errorMessage}
                  readOnly
                />
              </Anchor>
              <FileButton
                onChange={async function (file) {
                  setFileName(fileName.name);
                  const { data, error } = await supabase.storage
                    .from("Documents/TOS")
                    .upload(`./${randomNumber}_${file.name}`, file, {
                      upsert: true,
                    });
                  if (error) {
                    // Handle error
                    setErrorMessage(error.message);
                  }
                  if (data) {
                    // Handle success
                    const { data } = supabase.storage
                      .from("Documents")
                      .getPublicUrl(`TOS/${randomNumber}_${file.name}`);

                    // Update the TOS URL on the event table
                    const { error } = await supabase
                      .from("event")
                      .update({
                        tos_url: data.publicUrl,
                      })
                      .eq("event_id", params.eventID);
                    setReload(true);
                    Notifications.show({
                      color: "green",
                      title: "TOS uploaded successfully.",
                      autoClose: 5000,
                    });
                  }
                }}
                accept="application/pdf"
              >
                {(props) => (
                  <ActionIcon {...props} variant="light" color="blue" mr={50}>
                    <IconUpload />
                  </ActionIcon>
                )}
              </FileButton>

              <Anchor href={eventData[0].catalog_url}>
                <TextInput
                  w="100%"
                  label="Catalog"
                  value={extractFileName(eventData[0].catalog_url)}
                  error={catalogErrorMessage}
                  readOnly
                />
              </Anchor>
              <FileButton
                onChange={async function (file) {
                  setCatalogFileName(catalogFileName.name);
                  const { data, error } = await supabase.storage
                    .from("Documents/Catalog")
                    .upload(`./${randomNumber}_${file.name}`, file, {
                      upsert: true,
                    });
                  if (error) {
                    // Handle error
                    setCatalogErrorMessage(error.message);
                    console.log(error);
                  }
                  if (data) {
                    // Handle success
                    const { data } = supabase.storage
                      .from("Documents")
                      .getPublicUrl(`Catalog/${randomNumber}_${file.name}`);

                    // Update the Catalog URL on the event table
                    const { error } = await supabase
                      .from("event")
                      .update({
                        catalog_url: data.publicUrl,
                      })
                      .eq("event_id", params.eventID);
                    setReload(true);
                    Notifications.show({
                      color: "green",
                      title: "Catalog uploaded successfully.",
                      autoClose: 5000,
                    });
                  }
                }}
                accept="application/pdf"
              >
                {(props) => (
                  <ActionIcon {...props} variant="light" color="blue" mr={50}>
                    <IconUpload />
                  </ActionIcon>
                )}
              </FileButton>

              <Anchor href={eventData[0].promo_material_url}>
                <TextInput
                  w="100%"
                  label="Promo Material"
                  value={extractFileName(eventData[0].promo_material_url)}
                  error={promoErrorMessage}
                  readOnly
                />
              </Anchor>
              <FileButton
                onChange={async function (file) {
                  setPromoFileName(promoFileName.name);
                  const { data, error } = await supabase.storage
                    .from("Documents/Promo")
                    .upload(`./${randomNumber}_${file.name}`, file, {
                      upsert: true,
                    });
                  if (error) {
                    // Handle error
                    setPromoErrorMessage(error.message);
                  }
                  if (data) {
                    // Handle success
                    const { data } = supabase.storage
                      .from("Documents")
                      .getPublicUrl(`Promo/${randomNumber}_${file.name}`);

                    // Update the Promo Material URL on the event table
                    const { error } = await supabase
                      .from("event")
                      .update({
                        promo_material_url: data.publicUrl,
                      })
                      .eq("event_id", params.eventID);
                    setReload(true);
                    Notifications.show({
                      color: "green",
                      title: "Promo material uploaded successfully.",
                      autoClose: 5000,
                    });
                  }
                }}
                accept="application/pdf"
              >
                {(props) => (
                  <ActionIcon {...props} variant="light" color="blue" mr={50}>
                    <IconUpload />
                  </ActionIcon>
                )}
              </FileButton>
            </Group>
          </Paper>
        </Center>

        <Center>
          <Paper shadow="md" p="md" radius="md" mb={50} w="60%">
            <Center>
              <h2>Event Listings</h2>
              <Tooltip
                label="Import catalog excel file with the column names (All CAPS) in the following order: LOT_NO, CATEGORIES, NAME, STARTING_PRICE, RESERVED_PRICE, ESTIMATE_PRICE_MIN, ESTIMATE_PRICE_MAX,STATUS and BATCH"
                position="right"
                color="blue"
              >
                <IconHelpCircleFilled />
              </Tooltip>
            </Center>
            {catalogTable && catalogTable.length === 0 ? (
              <Dropzone
                onDrop={async function (files) {
                  const targetFile = files[0];
                  const data = await targetFile.arrayBuffer();
                  const workBook = read(data);
                  const workSheet = workBook.Sheets[workBook.SheetNames[0]];
                  const jsonData = utils.sheet_to_json(workSheet);
                  setCatalogData(jsonData);

                  // Check that the col names on excel is correct
                  if (
                    Object.keys(jsonData[0]).toString() ===
                    [
                      "LOT_NO",
                      "CATEGORIES",
                      "NAME",
                      "STARTING_PRICE",
                      "RESERVED_PRICE",
                      "ESTIMATE_PRICE_MIN",
                      "ESTIMATE_PRICE_MAX",
                      "STATUS",
                      "BATCH",
                    ].toString()
                  ) {
                    open();
                  } else {
                    Notifications.show({
                      color: "red",
                      title:
                        "Column name in excel must be LOT_NO, CATEGORIES, NAME, STARTING_PRICE, RESERVED_PRICE, ESTIMATE_PRICE_MIN, ESTIMATE_PRICE_MAX,STATUS and BATCH!",
                      autoClose: 5000,
                    });
                  }
                }}
                onReject={() =>
                  Notifications.show({
                    color: "red",
                    title: "File must be in excel format!",
                    autoClose: 5000,
                  })
                }
                accept={MS_EXCEL_MIME_TYPE}
              >
                <Group
                  position="center"
                  spacing="xl"
                  style={{ minHeight: rem(220), pointerEvents: "none" }}
                >
                  <Dropzone.Accept>
                    <IconUpload size="3.2rem" stroke={1.5} color="green" />
                  </Dropzone.Accept>
                  <Dropzone.Reject>
                    <IconX size="3.2rem" stroke={1.5} color="red" />
                  </Dropzone.Reject>
                  <Dropzone.Idle>
                    <IconPhoto size="3.2rem" stroke={1.5} />
                  </Dropzone.Idle>

                  <div>
                    <Text size="xl" inline>
                      Drag Excel file here or click to select files
                    </Text>
                    <Text size="sm" color="dimmed" inline mt={7}>
                      File should not exceed 5mb
                    </Text>
                  </div>
                </Group>
              </Dropzone>
            ) : (
              <ScrollArea>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text fz="xl" fw={700} mb={25}>
                    Catalog Items
                  </Text>
                  <Button
                    onClick={function () {
                      const editData = catalogTable.map((item) => {
                        const transformedItem = {
                          LOT_NO: item.item_lot_no,
                          CATEGORIES:
                            item.category.charAt(0).toUpperCase() +
                            item.category.slice(1),
                          NAME: item.name,
                          STARTING_PRICE: item.starting_price,
                          RESERVED_PRICE: item.reserved_price,
                          ESTIMATE_PRICE_MIN: item.estimate_min,
                          ESTIMATE_PRICE_MAX: item.estimate_max,
                          STATUS:
                            item.status.charAt(0).toUpperCase() +
                            item.status.slice(1),
                          BATCH: item.batch,
                          IMAGE_URL: item.image_url,
                        };
                        return transformedItem;
                      });
                      setCatalogData(editData);
                      open();
                    }}
                  >
                    Edit
                  </Button>
                </div>
                <Table
                  sx={{ minWidth: 800 }}
                  verticalSpacing="sm"
                  withBorder
                  withColumnBorders
                  highlightOnHover
                >
                  <thead>
                    <tr>
                      <th>LOT_NO</th>
                      <th>CATEGORIES</th>
                      <th>NAME</th>
                      <th>STARTING_PRICE</th>
                      <th>RESERVED_PRICE</th>
                      <th>ESTIMATE_PRICE_MIN</th>
                      <th>ESTIMATE_PRICE_MAX</th>
                      <th>STATUS</th>
                      <th>BATCH</th>
                      <th>ITEM_ENDTIME</th>
                      <th>IMAGES</th>
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </Table>
              </ScrollArea>
            )}
          </Paper>
        </Center>

        <Center>
          <Paper shadow="md" p="md" radius="md" mb={50} w="60%">
            <Center>
              <h2>Images</h2>
              <Tooltip
                label="Import images with file name correspond to lot no and variation. Eg. Lot 1 has 3 JPG images. You should name them 1_1.jpg, 1_2.jpg and 1_3.jpg"
                position="right"
                color="blue"
              >
                <IconHelpCircleFilled />
              </Tooltip>
            </Center>
            {catalogTable && catalogTable.length > 0 ? (
              <Dropzone
                onDrop={async function (files) {
                  const invalidFiles = files.filter(
                    (file) => !isValidFileName(file.name)
                  );
                  // Filter files to include only valid file names
                  const validFiles = files.filter((file) =>
                    isValidFileName(file.name)
                  );

                  if (invalidFiles.length > 0) {
                    Notifications.show({
                      color: "red",
                      title: "Invalid file name(s) detected.",
                      message:
                        "Only file names in the format 'number_number.jpg' or 'number_number.png' are allowed. Eg: 1_1.png or 1_1.jpg/jpeg",
                      autoClose: false,
                    });
                  } else {
                    Notifications.show({
                      color: "green",
                      title: "Images uploaded successfully.",
                      autoClose: 5000,
                    });

                    await supabase
                      .from("images")
                      .delete()
                      .eq("event_id", params.eventID);

                    await Promise.all(
                      validFiles.map(async (file) => {
                        await supabase.storage
                          .from("Documents/Images")
                          .upload(
                            `./${params.eventID}_${extractNumber(file.name)}`,
                            file,
                            {
                              upsert: true,
                            }
                          );

                        const { data } = await supabase.storage
                          .from("Documents")
                          .getPublicUrl(
                            `Images/${params.eventID}_${extractNumber(
                              file.name
                            )}`
                          );

                        await supabase
                          .from("event_items")
                          .update({
                            image_url: replaceLastNumberWithOne(data.publicUrl),
                          })
                          .filter("event_id", "eq", params.eventID)
                          .filter(
                            "item_lot_no",
                            "eq",
                            extractDigits(file.name)
                          );

                        await supabase.from("images").insert({
                          event_id: params.eventID,
                          item_lot_no: extractDigits(file.name),
                          url: data.publicUrl,
                        });
                      })
                    );

                    setReload(true);
                  }
                }}
                onReject={() =>
                  Notifications.show({
                    color: "red",
                    title: "File must be in png or jpg/jpeg format!",
                    autoClose: 5000,
                  })
                }
                accept={["image/png", "image/jpeg"]}
              >
                <Group
                  position="center"
                  spacing="xl"
                  style={{ minHeight: rem(220), pointerEvents: "none" }}
                >
                  <Dropzone.Accept>
                    <IconUpload size="3.2rem" stroke={1.5} color="green" />
                  </Dropzone.Accept>
                  <Dropzone.Reject>
                    <IconX size="3.2rem" stroke={1.5} color="red" />
                  </Dropzone.Reject>
                  <Dropzone.Idle>
                    <IconPhoto size="3.2rem" stroke={1.5} />
                  </Dropzone.Idle>

                  <div>
                    <Text size="xl" inline>
                      Drag Image file here or click to select files
                    </Text>
                    <Text size="sm" color="dimmed" inline mt={7}>
                      File should not exceed 5mb
                    </Text>
                  </div>
                </Group>
              </Dropzone>
            ) : (
              <Dropzone disabled className={classes.disabled}>
                <Group
                  position="center"
                  spacing="xl"
                  style={{ minHeight: rem(220), pointerEvents: "none" }}
                >
                  <Dropzone.Idle>
                    <IconPhoto size="3.2rem" stroke={1.5} />
                  </Dropzone.Idle>

                  <div>
                    <Text size="xl" inline>
                      You need to upload auction listings first before you can
                      upload image
                    </Text>
                  </div>
                </Group>
              </Dropzone>
            )}
          </Paper>
        </Center>

        <Center>
          <Paper shadow="md" p="md" radius="md" mb={50} w="60%">
            <Center>
              <Text
                fw="bold"
                fz="1.5em"
                color={
                  incrementData && incrementData.length !== 0 ? "black" : "red"
                }
              >
                Bidding Increment
              </Text>
            </Center>
            <HotTable
              data={
                incrementData && incrementData.length !== 0
                  ? incrementData
                  : [
                      {
                        from: 0,
                        to: 99,
                        increment: 5,
                      },
                      {
                        from: 100,
                        to: 199,
                        increment: 10,
                      },
                      {
                        from: 200,
                        to: 499,
                        increment: 20,
                      },
                      {
                        from: 500,
                        to: 999,
                        increment: 50,
                      },
                      {
                        from: 1000,
                        to: 1999,
                        increment: 100,
                      },
                      {
                        from: 2000,
                        to: 4999,
                        increment: 200,
                      },
                      {
                        from: 5000,
                        to: 9999,
                        increment: 250,
                      },
                      {
                        from: 10000,
                        to: 19999,
                        increment: 350,
                      },
                      {
                        from: 20000,
                        to: 49999,
                        increment: 500,
                      },
                      {
                        from: 50000,
                        to: 99999,
                        increment: 1000,
                      },
                      {
                        from: 100000,
                        to: 199999,
                        increment: 2000,
                      },
                      {
                        from: 200000,
                        to: 10000000,
                        increment: 2000,
                      },
                    ]
              }
              ref={hotRefIncrement}
              rowHeaders={true}
              colHeaders={["FROM (INCLUSIVE)", "TO (INCLUSIVE)", "INCREMENT"]}
              height="auto"
              width="auto"
              colWidths={[450, 450, 350]}
              contextMenu={true}
              licenseKey="non-commercial-and-evaluation" // for non-commercial use only
              columns={[
                {
                  data: "from",
                  type: "numeric",
                },
                {
                  data: "to",
                  type: "numeric",
                },
                {
                  data: "increment",
                  type: "numeric",
                },
              ]}
            />
            <Button
              w="20%"
              mt={10}
              color={
                incrementData && incrementData.length !== 0 ? "blue" : "red"
              }
              onClick={async function () {
                const incrementArray =
                  hotRefIncrement.current.hotInstance.getData();
                const transformedIncrementJson = incrementArray.map((item) => {
                  return {
                    event_id: params.eventID,
                    from: item[0],
                    to: item[1],
                    increment: item[2],
                    created_by: user.id,
                  };
                });
                // Delete existing event id first (Edit feature)
                await supabase
                  .from("bidding_increment")
                  .delete()
                  .eq("event_id", params.eventID);

                const { error } = await supabase
                  .from("bidding_increment")
                  .insert(transformedIncrementJson);

                setReload(true);

                Notifications.show({
                  color: "green",
                  title: "Bidding increment updated successfully.",
                  autoClose: 5000,
                });
              }}
            >
              {incrementData && incrementData.length !== 0
                ? "Save changes"
                : "Save"}
            </Button>
          </Paper>
        </Center>

        <Center>
          <Paper shadow="md" p="md" radius="md" mb={50} w="60%">
            <Center>
              <h2>Registered Bidders</h2>
            </Center>
            <ScrollArea>
              <Table
                sx={{ minWidth: 800 }}
                verticalSpacing="sm"
                withBorder
                withColumnBorders
                highlightOnHover
              >
                <thead>
                  <tr>
                    <th>Bidder No</th>
                    <th>Name</th>
                    <th>Registered on</th>
                  </tr>
                </thead>
                <tbody>{bidderRows}</tbody>
              </Table>
            </ScrollArea>
          </Paper>
        </Center>
      </div>
    );
  } else if (!eventData && errorCode === "22P02") {
    //22P02 error code represent invalid eventID. This validates the eventID that was manually keyed.
    return (
      <div>
        <ErrorPage />
      </div>
    );
  } else {
    return (
      <div>
        <LoadingScreen />
      </div>
    );
  }
}
