import Navbar from "../MainPage/Navbar";
import {
  Group,
  Paper,
  Center,
  createStyles,
  Select,
  SimpleGrid,
  Container,
  Text,
  ThemeIcon,
  Table,
  Badge,
  Tooltip,
  ActionIcon,
} from "@mantine/core";
import {
  IconReportMoney,
  IconHelpHexagonFilled,
  IconPackages,
  IconCashOff,
  IconCash,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { supabase } from "../../supabase/supabaseClient";
import { Notifications } from "@mantine/notifications";

const useStyles = createStyles((theme) => ({
  // smaller than sm: Mobile
  // smaller than lg: Tablet, smaller screen PC display
  container: {
    width: "100%",
    margin: "20px",
    [theme.fn.smallerThan("lg")]: {},
    [theme.fn.smallerThan("sm")]: {},
  },
  select: {
    width: "100%",
    marginTop: "20px",
  },
  table: {
    marginTop: "20px",
  },
}));

const calculateFunc = (data, gst, consignmentFee, buyerPremium) => {
  // Initialize variables to store the results
  let totalSoldPrice = 0;
  let totalFinalPrice = 0;
  let unsoldCount = 0;
  let soldCount = 0;
  let unpaidTotal = 0;

  // Iterate through the data and calculate the total final price, unsold, and sold counts
  data.forEach((item) => {
    if (item.status === "sold" && item.final_price !== null) {
      totalSoldPrice += item.final_price;
      totalFinalPrice +=
        item.final_price +
        item.final_price * consignmentFee * 0.01 +
        item.final_price * gst * 0.01 +
        item.final_price * buyerPremium * 0.01;
      soldCount++;
    } else if (item.status === "unsold") {
      unsoldCount++;
    }

    // Check if payment_status is "unpaid" and final_price is not null
    if (item.payment_status === "unpaid" && item.final_price !== null) {
      unpaidTotal +=
        item.final_price +
        item.final_price * consignmentFee * 0.01 +
        item.final_price * gst * 0.01 +
        item.final_price * buyerPremium * 0.01;
    }
  });

  // Create a new object to store the results
  const resultObject = {
    totalSoldPrice,
    totalFinalPrice,
    unsoldCount,
    soldCount,
    unpaidTotal,
  };

  return resultObject;
};

export default function SellerInvoiceManagement(props) {
  const { classes } = useStyles();
  const [completedEvents, setCompletedEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [gst, setGst] = useState(0);
  const [buyerPremium, setBuyerPremium] = useState(0);
  const [consignmentFee, setConsignmentFee] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalSoldAmount, setTotalSoldAmount] = useState(0);
  const [unpaidTotal, setUnpaidTotal] = useState(0);
  const [totalLot, setTotalLot] = useState(0);
  const [totalSold, setTotalSold] = useState(0);
  const [totalUnsold, setTotalUnsold] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    setReload(true);
    const fetchCompletedEventData = async () => {
      const { data, error } = await supabase
        .from("event")
        .select("event_id, name")
        .eq("status", "completed");

      if (data && data.length > 0) {
        // Rename the keys to value and label to fit the parameters in the select component
        const renamedData = data.map((item) => ({
          value: item.event_id,
          label: item.name,
        }));
        setCompletedEvents(renamedData);
      }

      if (error) {
        console.log(error);
      }
    };

    const fetchEventItems = async () => {
      if (selectedEvent) {
        const { data: eventData } = await supabase
          .from("event")
          .select(
            "gst_percentage, buyer_premium_percentage, consignment_fee_percentage"
          )
          .eq("event_id", selectedEvent);
        if (eventData) {
          setGst(eventData[0].gst_percentage);
          setBuyerPremium(eventData[0].buyer_premium_percentage);
          setConsignmentFee(eventData[0].consignment_fee_percentage);
        }

        const { data, error } = await supabase
          .from("event_items")
          .select()
          .eq("event_id", selectedEvent)
          .order("item_lot_no", { ascending: true });

        if (data && data.length > 0) {
          const arrayData = calculateFunc(
            data,
            gst,
            buyerPremium,
            consignmentFee
          );
          setTotalAmount(arrayData.totalFinalPrice);
          setTotalSoldAmount(arrayData.totalSoldPrice);
          setTotalSold(arrayData.soldCount);
          setTotalUnsold(arrayData.unsoldCount);
          setUnpaidTotal(arrayData.unpaidTotal);
          setTotalLot(data.length);

          // Create row data for table
          const row = data.map((element) => (
            <tr key={element.item_id}>
              <td>{element.item_lot_no}</td>
              <td>{element.name}</td>
              <td>{element.category}</td>
              <td>{element.final_price}</td>
              <td>{element.final_price * gst * 0.01}</td>
              <td>{element.final_price * buyerPremium * 0.01}</td>
              <td>{element.final_price * consignmentFee * 0.01}</td>
              <td>
                {element.final_price +
                  element.final_price * consignmentFee * 0.01 +
                  element.final_price * gst * 0.01 +
                  element.final_price * buyerPremium * 0.01}
              </td>
              <td>
                {element.status === "sold" ? (
                  <Badge color="green">Sold</Badge>
                ) : (
                  <Badge color="yellow">Unsold</Badge>
                )}
              </td>
              <td>
                {element.payment_status === "paid" ? (
                  <Badge color="green">Paid</Badge>
                ) : element.payment_status === "unpaid" ? (
                  <Badge color="red">Unpaid</Badge>
                ) : (
                  <Badge color="gray">N/A</Badge>
                )}
              </td>
              <td>
                {element.payment_status === "paid" ? (
                  <Tooltip label="Change to Unpaid" color="red" openDelay={500}>
                    <ActionIcon
                      color="red"
                      onClick={async function () {
                        const { error } = await supabase
                          .from("event_items")
                          .update({
                            payment_status: "unpaid",
                            paid_datetime: null,
                          })
                          .eq("item_id", element.item_id);
                        setReload(true);
                        Notifications.show({
                          color: "green",
                          title: "Status changed to Unpaid.",
                          autoClose: 5000,
                        });
                      }}
                    >
                      <IconCashOff size="1rem" stroke={1.5} />
                    </ActionIcon>
                  </Tooltip>
                ) : element.payment_status === "unpaid" ? (
                  <Tooltip label="Change to Paid" color="green" openDelay={500}>
                    <ActionIcon
                      color="green"
                      onClick={async function () {
                        const { error } = await supabase
                          .from("event_items")
                          .update({
                            payment_status: "paid",
                            paid_datetime: new Date().toISOString(),
                          })
                          .eq("item_id", element.item_id);
                        setReload(true);
                        Notifications.show({
                          color: "green",
                          title: "Status changed to Paid.",
                          autoClose: 5000,
                        });
                      }}
                    >
                      <IconCash size="1rem" stroke={1.5} />
                    </ActionIcon>
                  </Tooltip>
                ) : (
                  ""
                )}
              </td>
            </tr>
          ));
          setTableData(row);
        }

        if (error) {
          console.log(error);
        }
      }
    };

    fetchCompletedEventData();
    fetchEventItems();
    setReload(false);
  }, [selectedEvent, reload]);

  return (
    <div>
      <Navbar />
      <Center>
        <Container className={classes.container}>
          <SimpleGrid spacing="md" cols={1}>
            <div>
              <Select
                className={classes.select}
                label="Completed Event:"
                data={completedEvents}
                placeholder="Choose event"
                searchable
                nothingFound="No options"
                onChange={setSelectedEvent}
                maxDropdownHeight={280}
              />
            </div>
            <Group grow>
              <Tooltip
                label={`Total Sold: $${totalSoldAmount}, GST: $${
                  totalSoldAmount * gst * 0.01
                }, Buyer Premium: $${
                  totalSoldAmount * buyerPremium * 0.01
                }, Consignment Fee: $${
                  totalSoldAmount * consignmentFee * 0.01
                }`}
                position="left"
                color="blue"
              >
                {PaperStats(
                  "Total Amount",
                  totalAmount.toFixed(2),
                  false,
                  <IconReportMoney size="1.8rem" stroke={1.5} />
                )}
              </Tooltip>
              {PaperStats(
                "Unpaid Total",
                unpaidTotal.toFixed(2),
                false,
                <IconHelpHexagonFilled size="1.8rem" stroke={1.5} />
              )}
              {PaperStats(
                "Total lot",
                totalLot,
                true,
                <IconPackages size="1.8rem" stroke={1.5} />,
                totalSold,
                totalUnsold
              )}
            </Group>
          </SimpleGrid>
          {tableData ? (
            <Table
              className={classes.table}
              verticalSpacing="sm"
              withColumnBorders
              withBorder
            >
              <thead>
                <tr>
                  <th>Lot Number</th>
                  <th>Name</th>
                  <th>Category</th>
                  <th>Sold Price</th>
                  <th>GST ({gst}%)</th>
                  <th>Buyer Premium ({buyerPremium}%)</th>
                  <th>Consignment Fee ({consignmentFee}%)</th>
                  <th>Total Price</th>
                  <th>Sold/Unsold</th>
                  <th>Paid/Unpaid</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>{tableData}</tbody>
            </Table>
          ) : (
            ""
          )}
        </Container>
      </Center>
    </div>
  );
}

function PaperStats(
  title,
  value,
  is_total_lot = false,
  icon,
  sold_value = 0,
  unsold_value = 0
) {
  return (
    <Paper shadow="md" p="md" radius="md" h="100%" withBorder>
      <Group position="apart">
        <div>
          <Text c="dimmed" tt="uppercase" fw={700} fz="xs">
            {title}
          </Text>
          <Text fw={700} fz="xl">
            {value}
          </Text>
          {is_total_lot && ( // Add an if statement here
            <Text c="dimmed" fz="sm">
              {`${sold_value} sold | ${unsold_value} unsold`}
            </Text>
          )}
        </div>

        <ThemeIcon color="blue" variant="light" size={38} radius="md">
          {icon}
        </ThemeIcon>
      </Group>
    </Paper>
  );
}
