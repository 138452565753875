import {
  Center,
  Anchor,
  Group,
  ScrollArea,
  Table,
  ActionIcon,
  Text,
  Loader,
  Modal,
  TextInput,
  Select,
  Button,
  Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useState, useEffect } from "react";
import { supabase } from "../../supabase/supabaseClient";
import { IconX, IconPencil } from "@tabler/icons-react";
import { Notifications } from "@mantine/notifications";

export default function ApprovedTable(props) {
  const [userData, setUserData] = useState(null);
  const [opened, { open, close }] = useDisclosure(false);
  const [uuid, setUuid] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [nric, setNric] = useState("");
  const [address, setAddress] = useState("");
  const [status, setStatus] = useState("");
  const [refreshData, setRefreshData] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const { data, error } = await supabase
        .from("users")
        .select()
        .eq("status", "approved")
        .order("name", { ascending: true });

      if (data) {
        setUserData(data);
      }

      if (error) {
        setUserData(null);
      }
    };

    fetchUserData();
  }, [refreshData]);

  if (userData) {
    const rows = userData.map((item) => (
      <tr key={item.uuid}>
        <td>
          <Anchor component="button" size="sm">
            {item.name}
          </Anchor>
        </td>
        <td>
          <Anchor component="button" size="sm">
            {item.email}
          </Anchor>
        </td>
        <td>
          <Anchor component="button" size="sm">
            {item.mobile}
          </Anchor>
        </td>
        <td>
          <Anchor component="button" size="sm">
            {item.last_four_nric}
          </Anchor>
        </td>
        <td>
          <Anchor component="button" size="sm">
            {item.address}
          </Anchor>
        </td>
        <td>
          <Anchor component="button" size="sm">
            {item.status}
          </Anchor>
        </td>
        <td>
          <Group spacing={0} position="center">
            <Tooltip label="Reject" color="red" openDelay={500}>
              <ActionIcon
                color="red"
                onClick={async function () {
                  const { error } = await supabase
                    .from("users")
                    .update({ status: "rejected" })
                    .eq("uuid", item.uuid);
                  setRefreshData((prev) => !prev);
                  Notifications.show({
                    color: "green",
                    title: "User has been rejected.",
                    autoClose: 5000,
                  });
                }}
              >
                <IconX size="1rem" stroke={1.5} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Edit" color="yellow" openDelay={500}>
              <ActionIcon
                onClick={function () {
                  open();
                  setUuid(item.uuid);
                  setEmail(item.email);
                  setName(item.name);
                  setMobile(item.mobile);
                  setNric(item.last_four_nric);
                  setAddress(item.address);
                  setStatus(item.status);
                }}
              >
                <IconPencil size="1rem" stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Group>
        </td>
      </tr>
    ));

    return (
      <div>
        <Modal opened={opened} onClose={close} title="Edit">
          <form
            onSubmit={async function () {
              /* Update public.users data */
              const { error } = await supabase
                .from("users")
                .update({
                  name: name,
                  mobile: mobile,
                  address: address,
                  last_four_nric: nric,
                  status: status,
                })
                .eq("uuid", uuid);
              setRefreshData((prev) => !prev);
              Notifications.show({
                color: "green",
                title: "Edit saved.",
                autoClose: 5000,
              });
            }}
          >
            <TextInput
              label="Name"
              value={name}
              mb={5}
              withAsterisk
              required
              onChange={(e) => setName(e.target.value)}
            />
            <TextInput
              disabled
              label="Email"
              value={email}
              mb={5}
              required
              pattern="^\S+@\S+\.\S+$"
              title="Please key in a valid email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextInput
              label="Mobile"
              value={mobile}
              mb={5}
              withAsterisk
              required
              pattern="^[89]\d{7}$"
              title="Please key in your Singapore-registered mobile number"
              onChange={(e) => setMobile(e.target.value)}
            />
            <TextInput
              label="Last 4 NRIC"
              value={nric}
              mb={5}
              withAsterisk
              required
              onChange={(e) => setNric(e.target.value)}
            />
            <TextInput
              label="Address"
              value={address}
              mb={5}
              withAsterisk
              required
              onChange={(e) => setAddress(e.target.value)}
            />
            <Select
              defaultValue={status}
              label="Status"
              pb={10}
              onChange={(e) => setStatus(e)}
              data={[
                { value: "pending", label: "Pending" },
                { value: "approved", label: "Approve" },
                { value: "rejected", label: "Reject" },
                { value: "blacklisted", label: "Blacklist" },
              ]}
            />
            <Button type="submit" radius="lg" fullWidth>
              Save
            </Button>
          </form>
        </Modal>

        <Center>
          <ScrollArea>
            <Text fz="xl" fw={700} mb={25}>
              Approved Users
            </Text>
            <Table
              sx={{ minWidth: 800 }}
              verticalSpacing="sm"
              withBorder
              withColumnBorders
              highlightOnHover
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile No</th>
                  <th>NRIC</th>
                  <th>Address</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </ScrollArea>
        </Center>
      </div>
    );
  } else {
    return (
      <div>
        <div>
          <Text fz={"xl"}>Please Wait...</Text>
          <Loader variant="dots" />
        </div>
      </div>
    );
  }
}
