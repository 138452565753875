import { Text } from "@mantine/core";
import React from "react";

const DateTimeDisplay = ({ value, type, isDanger }) => {
  return (
    <div className={isDanger ? "countdown danger" : "countdown"}>
      <Text fz={isDanger ? "lg" : "sm"} color={isDanger ? "red" : ""}>
        <b>{value}</b>
        {type}
      </Text>
    </div>
  );
};

export default DateTimeDisplay;
