import { useState } from "react";
import { useAuth } from "./auth";
import { useToggle, upperFirst } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import emailjs from "@emailjs/browser";
import {
  TextInput,
  PasswordInput,
  Text,
  Paper,
  Group,
  Button,
  Checkbox,
  Anchor,
  Stack,
  Center,
  LoadingOverlay,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { Notifications } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";

export default function SignIn(props) {
  const { signIn, signUp } = useAuth();
  const [type, toggle] = useToggle(["login", "register"]);
  const [loading, setLoading] = useState(false);
  const [textError, setTextError] = useState("");
  const navigate = useNavigate();
  const { onSuccess } = props;

  const form = useForm({
    initialValues: {
      email: "",
      name: "",
      password: "",
      mobile: "",
      nric: "",
      address: "",
      terms: true,
    },

    validate: {
      email: (val) =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          val
        )
          ? null
          : "Invalid email",
      password: (val) =>
        val.length <= 7
          ? "Password should include at least 8 characters"
          : null,
    },
  });

  // Handle Sign In
  async function handleSignIn(email, password) {
    try {
      setLoading(true);
      const { error } = await signIn({
        email: email,
        password: password,
      });
      setLoading(false);

      if (error) {
        setTextError(error.message);
      } else {
        navigate("/front");
        Notifications.show({
          color: "green",
          title: "Welcome back!",
          autoClose: 3000,
          icon: <IconCheck />,
        });
      }
    } catch (error) {
      setTextError(error.message);
    }
  }

  // Handle registration
  async function handleRegistration(
    email,
    password,
    name,
    mobile,
    nric,
    address
  ) {
    // Supabase registration
    try {
      setLoading(true);
      // Data: Provide uuid, email, metadata from registered user
      const { data, error } = await signUp({
        email: email,
        password: password,
        options: {
          data: {
            name: name,
            mobile: mobile,
            last_four_nric: nric,
            address: address,
          },
        },
      });
      setLoading(false);

      if (error) {
        alert(error);
      } else {
        Notifications.show({
          color: "green",
          title: "Registration Successful",
          message: "Please verify your email and wait for admin approval.",
          autoClose: false,
        });
        onSuccess(); // Call the onSuccess callback function
      }
    } catch (error) {
      alert(error);
    }

    // Email to admin for approval
    try {
      const result = await emailjs.send(
        "registration_approval",
        "template_ku4uf65",
        {
          new_user_email: email,
        },
        process.env.REACT_APP_EMAILJS_KEY
      );
    } catch (error) {
      alert(error);
    }
  }

  return (
    <div>
      <Center>
        <Paper radius="md" p="xl" withBorder {...props} pos="relative">
          <LoadingOverlay visible={loading} overlayBlur={2} />
          <Text size="lg" weight={500} mb={10}>
            Welcome to AssetHubMarketplace, {type} with
          </Text>
          <form
            onSubmit={form.onSubmit((e) => {
              type === "register"
                ? handleRegistration(
                    e.email,
                    e.password,
                    e.name,
                    e.mobile,
                    e.nric,
                    e.address
                  )
                : handleSignIn(e.email, e.password);
            })}
          >
            <Stack>
              {type === "register" && (
                <TextInput
                  required
                  label="Name"
                  placeholder="Your name"
                  value={form.values.name}
                  onChange={(event) =>
                    form.setFieldValue("name", event.currentTarget.value)
                  }
                  radius="md"
                />
              )}

              {type === "register" && (
                <TextInput
                  required
                  label="Mobile No"
                  placeholder="Your Mobile Number"
                  value={form.values.mobile_no}
                  onChange={(event) =>
                    form.setFieldValue("mobile_no", event.currentTarget.value)
                  }
                  radius="md"
                  pattern="^[89]\d{7}$"
                  title="Please key in your Singapore-registered mobile number"
                />
              )}

              {type === "register" && (
                <TextInput
                  required
                  label="Last 4 Digits of NRIC"
                  placeholder="123C"
                  value={form.values.nric}
                  onChange={(event) =>
                    form.setFieldValue("nric", event.currentTarget.value)
                  }
                  radius="md"
                />
              )}

              {type === "register" && (
                <TextInput
                  required
                  label="Address"
                  placeholder="123 My Street, #12-345, Singapore 612345"
                  value={form.values.address}
                  onChange={(event) =>
                    form.setFieldValue("address", event.currentTarget.value)
                  }
                  radius="md"
                />
              )}

              <TextInput
                required
                label="Email"
                placeholder="james@gmail.com"
                value={form.values.email}
                onChange={(event) =>
                  form.setFieldValue("email", event.currentTarget.value)
                }
                error={form.errors.email && "Invalid email"}
                radius="md"
              />

              <PasswordInput
                required
                label="Password"
                placeholder="Your password"
                value={form.values.password}
                onChange={(event) =>
                  form.setFieldValue("password", event.currentTarget.value)
                }
                error={
                  form.errors.password &&
                  "Password should include at least 8 characters"
                }
                description={textError}
                radius="md"
                inputWrapperOrder={["label", "input", "description", "error"]}
                styles={(theme) => ({
                  description: {
                    color: "red",
                  },
                })}
              />

              {type === "register" && (
                <Checkbox
                  required
                  label="I accept terms and conditions"
                  checked={form.values.terms}
                  onChange={(event) =>
                    form.setFieldValue("terms", event.currentTarget.checked)
                  }
                />
              )}
            </Stack>

            <Group position="apart" mt="xl">
              <Anchor
                component="button"
                type="button"
                color="dimmed"
                onClick={() => toggle()}
                size="xs"
              >
                {type === "register"
                  ? "Already have an account? Login"
                  : "Don't have an account? Register"}
              </Anchor>
              {type === "login" ? (
                <Anchor
                  component="button"
                  type="button"
                  size="sm"
                  onClick={() => navigate("/reset")}
                >
                  Forgot Password
                </Anchor>
              ) : (
                ""
              )}
              <Button type="submit" radius="xl">
                {upperFirst(type)}
              </Button>
            </Group>
          </form>
        </Paper>
      </Center>
    </div>
  );
}
