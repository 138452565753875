import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../auth";
import { useEffect, useState } from "react";
import { supabase } from "../../supabase/supabaseClient";
import LoadingScreen from "../LoadingScreen/LoadingScreen";

export default function PrivateRouteAdmin() {
  const { user } = useAuth();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const { data } = await supabase
        .from("users")
        .select()
        .eq("uuid", user.id);

      if (data && data.length > 0) {
        setUserData(data[0]);
      }
    };
    fetchUser();
  }, [user.id]);

  if (userData === null) {
    return (
      <div>
        <LoadingScreen />
      </div>
    );
  }

  if (userData && userData.employee_bool) {
    return <Outlet />;
  } else {
    return <Navigate to="/front" />;
  }
}
