import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../auth";
import { useEffect, useState } from "react";
import { supabase } from "../../supabase/supabaseClient";
import { Notifications } from "@mantine/notifications";
import { ExclamationMark } from "tabler-icons-react";
import LoadingScreen from "../LoadingScreen/LoadingScreen";

export default function PrivateRoute() {
  const { user } = useAuth();
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (user) {
      supabase
        .from("users")
        .select()
        .eq("uuid", user.id)
        .then((value) => {
          setStatus(value.data[0].status);
        });
    }
  }, []);

  if (user && user.email_confirmed_at && status === "approved") {
    return <Outlet />;
  } else if (
    user &&
    user.email_confirmed_at &&
    status &&
    status !== "approved"
  ) {
    Notifications.show({
      title: "Pending Approval",
      message: "Please kindly wait for approval from admin",
      color: "orange",
      icon: <ExclamationMark />,
      autoClose: false,
    });
    return <Navigate to="/" />;
  } else if (user && user.email_confirmed_at && !status) {
    return (
      <div>
        <LoadingScreen />
      </div>
    );
  } else {
    return <Navigate to="/" />;
  }
}
