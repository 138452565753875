import React from "react";
import useCountdown from "./useCountdown";
import DateTimeDisplay from "../Countdown/DateTimeDisplay";
import { Text, Group } from "@mantine/core";

const ShowCounter = ({ days, hours, minutes, seconds, type = "long" }) => {
  if (type === "long") {
    return (
      <div className="show-counter">
        <Group spacing="xs">
          <DateTimeDisplay value={days} type={"Days"} isDanger={false} />
          <Text>:</Text>
          <DateTimeDisplay value={hours} type={"Hours"} isDanger={false} />
          <Text>:</Text>
          <DateTimeDisplay value={minutes} type={"Mins"} isDanger={false} />
          <Text>:</Text>
          <DateTimeDisplay value={seconds} type={"Seconds"} isDanger={false} />
        </Group>
      </div>
    );
  } else {
    return (
      <div className="show-counter">
        <Group spacing="2px">
          <Text fz="sm">End in:</Text>
          <DateTimeDisplay value={days} type={"D"} isDanger={false} />
          <Text fz="xs">:</Text>
          <DateTimeDisplay value={hours} type={"H"} isDanger={false} />
          <Text fz="xs">:</Text>
          <DateTimeDisplay value={minutes} type={"M"} isDanger={false} />
          <Text fz="xs">:</Text>
          <DateTimeDisplay
            value={seconds}
            type={"S"}
            isDanger={
              days == 0 && hours == 0 && minutes == 0 && seconds < 31
                ? true
                : false
            }
          />
        </Group>
      </div>
    );
  }
};

const CountdownTimer = ({ targetDate, type, onCloseModal }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    if (type === "long") {
      return (
        <>
          {onCloseModal && onCloseModal(true)} {/* Call the callback */}
          <Text>0 Days 0 Hours 0 Minutes 0 Seconds</Text>
        </>
      );
    } else {
      return (
        <>
          {onCloseModal && onCloseModal(true)} {/* Call the callback */}
          <Text fz="sm">End in: 0D 0H 0M 0S</Text>
        </>
      );
    }
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
        type={type}
      />
    );
  }
};

export default CountdownTimer;
