// Main component after successful log in
import Navbar from "./Navbar";
import Footer from "../Footer/footer";
import FrontPage from "./Event/FrontPage";

export default function MainPage(props) {
  return (
    <div>
      <Navbar />
      <FrontPage />
      <Footer />
    </div>
  );
}
