import { useState } from "react";
import {
  TextInput,
  Select,
  Button,
  NumberInput,
  Group,
  FileButton,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { IconPercentage } from "@tabler/icons-react";
import { supabase } from "../../supabase/supabaseClient";
import { useAuth } from "../auth";
import { Notifications } from "@mantine/notifications";

function generateRandomNumber() {
  const randomNumber = Math.floor(Math.random() * 9000000000) + 1000000000;
  return randomNumber.toString();
}

export default function AddEvent(props) {
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [type, setType] = useState("online");
  const [startDatetime, setStartDatetime] = useState(null);
  const [endDatetime, setEndDatetime] = useState(null);
  const [gst, setGst] = useState(null);
  const [buyerPremium, setBuyerPremium] = useState(null);
  const [consignmentFee, setConsignmentFee] = useState(null);
  const [overtimeExtensionMin, setOvertimeExtensionMin] = useState(null);
  const [displayReservedNotMet, setDisplayReservedNotMet] = useState(false);
  const [file, setFile] = useState(null);
  const randomNumber = generateRandomNumber();
  const { user } = useAuth();

  return (
    <>
      <DateTimePicker
        withAsterisk
        required
        dropdownType="modal"
        label="Start Datetime:"
        mx="auto"
        onChange={(e) => setStartDatetime(e)}
      />
      <DateTimePicker
        withAsterisk
        required
        dropdownType="modal"
        label="End Datetime:"
        mx="auto"
        onChange={(e) => setEndDatetime(e)}
      />
      <TextInput
        label="Event Name:"
        value={name}
        mb={5}
        withAsterisk
        required
        onChange={(e) => setName(e.target.value)}
      />
      <TextInput
        label="Event Description:"
        value={description}
        mb={5}
        withAsterisk
        required
        onChange={(e) => setDescription(e.target.value)}
      />
      <Group align="flex-end" grow>
        <TextInput
          label="Image File:"
          value={file ? file.name : ""}
          required
          disabled
        />
        <FileButton onChange={setFile} accept="image/png,image/jpeg">
          {(props) => <Button {...props}>Upload image</Button>}
        </FileButton>
      </Group>

      <Select
        withAsterisk
        required
        defaultValue={type}
        label="Type:"
        pb={10}
        onChange={(e) => setType(e)}
        data={[
          { value: "online", label: "Online" },
          { value: "online_and_live", label: "Online and Live" },
        ]}
      />
      <Group>
        <NumberInput
          required
          label="GST:"
          withAsterisk
          hideControls
          onChange={(e) => setGst(e)}
          rightSection={<IconPercentage size="2rem" stroke={1.5} />}
          maw={200}
        />
        <NumberInput
          required
          label="Buyer Premium:"
          withAsterisk
          hideControls
          onChange={(e) => setBuyerPremium(e)}
          rightSection={<IconPercentage size="2rem" stroke={1.5} />}
          maw={200}
        />
      </Group>

      <NumberInput
        required
        label="Consignment Fee:"
        withAsterisk
        hideControls
        onChange={(e) => setConsignmentFee(e)}
        rightSection={<IconPercentage size="2rem" stroke={1.5} />}
      />
      <NumberInput
        required
        label="Overtime Extension (Minute):"
        withAsterisk
        hideControls
        onChange={(e) => setOvertimeExtensionMin(e)}
      />
      <Select
        withAsterisk
        required
        defaultValue={displayReservedNotMet}
        label="Display reserved price not met:"
        pb={10}
        onChange={(e) => setDisplayReservedNotMet(e)}
        data={[
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
      />
      <Button
        onClick={async function () {
          if (
            !startDatetime ||
            !endDatetime ||
            !name ||
            !description ||
            !file ||
            !overtimeExtensionMin
          ) {
            Notifications.show({
              color: "red",
              title: "You need to fill in the required field!",
              autoClose: 5000,
            });
          } else {
            // Update the event image
            const { data } = await supabase.storage
              .from("Documents/Images")
              .upload(`./${randomNumber}_${file.name}`, file, {
                upsert: true,
              });

            if (data) {
              const { data: event_image_data } = supabase.storage
                .from("Documents")
                .getPublicUrl(`Images/${randomNumber}_${file.name}`);

              /* Update public.users data */
              const { error } = await supabase.from("event").insert({
                name: name,
                event_description: description,
                start_datetime: startDatetime,
                end_datetime: endDatetime,
                event_type: type,
                gst_percentage: gst,
                buyer_premium_percentage: buyerPremium,
                consignment_fee_percentage: consignmentFee,
                overtime_extension_min: overtimeExtensionMin,
                display_reserved_not_met: displayReservedNotMet,
                event_image_url: event_image_data.publicUrl,
                created_by: user.id,
                status: "not_started",
              });
            }
            props.closeModal();
          }
        }}
        radius="lg"
        fullWidth
      >
        Create Event
      </Button>
    </>
  );
}
