import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  BlobProvider,
  Image,
  Font,
} from "@react-pdf/renderer";
import { Button } from "@mantine/core";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
  },
  header: {
    flexDirection: "row",
  },
  section: {
    marginLeft: 20,
    marginTop: 10,
    padding: 10,
    paddingRight: 0,
    flexGrow: 1,
    width: 100,
    height: 100,
  },
  header_text: {
    fontSize: "10px",
  },
  tableView: {
    display: "flex",
    alignItems: "flex-start",
    paddingTop: 5,
  },
  overallView: {
    display: "flex",
    alignItems: "flex-start",
    marginTop: 55,
    marginLeft: 315,
    borderStyle: "solid",
    borderTop: 1,
  },
  overFont: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: "bold",
  },
  grandTotalFont: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: "bold",
  },
  grandTotalContainer: {
    marginTop: 5,
    borderStyle: "solid",
    borderTop: 1,
    borderBottom: 2,
  },
  itemTableView: {
    display: "flex",
    alignItems: "center",
    marginTop: 50,
    marginLeft: 80,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
  },
  headerCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#EEEEEE",
  },
  tableCell: {
    marginHorizontal: 2,
    marginTop: 1,
    marginBottom: 1,
    fontSize: 10,
    textAlign: "justify",
  },
  itemLotCol: {
    width: "20%",
    maxHeight: 100,
    borderStyle: "solid",
    borderWidth: 1,
  },
  itemLotHeader: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#EEEEEE",
  },
  itemPriceCol: {
    width: "20%",
    maxHeight: 100,
    borderStyle: "solid",
    borderWidth: 1,
  },
  itemPriceHeader: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#EEEEEE",
  },
  itemTableCol: {
    width: "40%",
    maxHeight: 100,
    borderStyle: "solid",
    borderWidth: 1,
  },
  itemHeaderCol: {
    width: "40%",
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#EEEEEE",
  },
  itemTableHeaderCell: {
    margin: "auto",
    marginTop: 2,
    marginBottom: 2,
    fontSize: 10,
  },
  itemTableCell: {
    fontSize: 10,
    display: "flex",
    alignSelf: "center",
    margin: 10,
  },
  itemTableName: {
    padding: 10,
    fontSize: 10,
    display: "flex",
    alignSelf: "center",
  },
  paymentOverview: {
    borderStyle: "solid",
    border: 1,
    marginTop: 10,
    padding: 5,
    marginLeft: 315,
  },
  paymentText: {
    fontSize: 10,
  },
});

// Create Document Component
const Template = (props) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <View style={styles.section}>
          <Image
            alt="Logo"
            style={{ width: 150, height: 34 }}
            src="https://i1.wp.com/assethub.sg/wp-content/uploads/2019/02/cropped-AssetHub-Logo-Rec-e1551345217372-1.png?fit=3299%2C802"
          />
        </View>
        <View style={styles.section}>
          <Text
            style={{ fontSize: "15px", color: "#0E4192", fontWeight: "bold" }}
          >
            AssetHub Pte Ltd
          </Text>
          <Text style={{ color: "#797979", fontSize: "10px" }}>
            (Registration No: 201811649D)
          </Text>
          <Text style={styles.header_text}>
            80 Changi Road, #02-58, Centropod @ Changi
          </Text>
          <Text style={styles.header_text}>Singapore 419715</Text>
          <Text style={styles.header_text}>Tel: 6909 5357</Text>
          <Text style={styles.header_text}>Fax: 6909 5367</Text>
          <Text style={styles.header_text}>www.AssetHub.sg</Text>
        </View>
      </View>
      <View style={styles.header}>
        <View style={styles.section}>
          <Text style={{ fontSize: "12px", padding: 5 }}>Bill To:</Text>
          <Text style={{ fontSize: "10px", paddingLeft: 5 }}>
            {props.buyer_name}
          </Text>
          <Text style={{ fontSize: "10px", paddingLeft: 5 }}>
            {props.buyer_address}
          </Text>
        </View>

        <View style={styles.section}>
          <View style={styles.tableView}>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.headerCol}>
                  <Text style={styles.tableCell}>Invoice No</Text>
                </View>
                <View style={styles.headerCol}>
                  <Text style={styles.tableCell}>Invoice Date</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{props.invoice_no}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{props.invoice_date}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>

      <View style={styles.header}>
        <View style={styles.itemTableView}>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.itemLotHeader}>
                <Text style={styles.itemTableHeaderCell}>Lot No</Text>
              </View>
              <View style={styles.itemHeaderCol}>
                <Text style={styles.itemTableHeaderCell}>Item Name</Text>
              </View>
              <View style={styles.itemPriceHeader}>
                <Text style={styles.itemTableHeaderCell}>Price Sold</Text>
              </View>
            </View>
            {props.buyerDataArray
              ? props.buyerDataArray.map((element) => (
                  <View style={styles.tableRow}>
                    <View style={styles.itemLotCol}>
                      <Text style={styles.itemTableCell}>
                        {element.item_lot_no}
                      </Text>
                    </View>
                    <View style={styles.itemTableCol}>
                      <Text style={styles.itemTableName}>
                        {element.item_name}
                      </Text>
                    </View>
                    <View style={styles.itemPriceCol}>
                      <Text style={styles.itemTableCell}>
                        {element.final_price.toFixed(2)}
                      </Text>
                    </View>
                  </View>
                ))
              : ""}
          </View>
        </View>
      </View>

      <View style={styles.header}>
        <View style={styles.overallView}>
          <Text style={styles.overFont}>
            Total Amount Sold: $ {props.totalAmountSold.toFixed(2)}
          </Text>
          <Text style={styles.overFont}>
            GST ({props.gst}%): ${" "}
            {(props.totalAmountSold * props.gst * 0.01).toFixed(2)}
          </Text>
          <Text style={styles.overFont}>
            Buyer Premium ({props.buyerPremium}%): ${" "}
            {(props.totalAmountSold * props.buyerPremium * 0.01).toFixed(2)}
          </Text>
          <Text style={styles.overFont}>
            Consignment Fee ({props.consignmentFee}%): ${" "}
            {(props.totalAmountSold * props.consignmentFee * 0.01).toFixed(2)}
          </Text>
          <View style={styles.grandTotalContainer}>
            <Text style={styles.grandTotalFont}>
              Grand Total Payable: ${" "}
              {(
                props.totalAmountSold +
                props.totalAmountSold * props.gst * 0.01 +
                props.totalAmountSold * props.buyerPremium * 0.01 +
                props.totalAmountSold * props.consignmentFee * 0.01
              ).toFixed(2)}
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.header}>
        <View style={styles.paymentOverview}>
          <Text style={{ fontSize: 12, marginBottom: 5 }}>
            Thank you for participating!
          </Text>
          <Text style={styles.paymentText}>You can pay by:</Text>
          <Text style={styles.paymentText}>
            Bank Transfer: UOB Bank A/C No. 365 308 582 4
          </Text>
          <Text style={styles.paymentText}>PayNow: UEN 201811649D</Text>
        </View>
      </View>
    </Page>
  </Document>
);

const PDFDocument = (props) => (
  <div>
    <BlobProvider
      document={
        <Template
          buyer_name={props.buyer_name}
          buyer_address={props.buyer_address}
          invoice_no={props.invoice_no}
          invoice_date={props.invoice_date}
          buyerDataArray={props.buyerDataArray}
          totalAmountSold={props.totalAmountSold}
          gst={props.gst}
          buyerPremium={props.buyerPremium}
          consignmentFee={props.consignmentFee}
        />
      }
    >
      {({ url }) => (
        <Button
          disabled={props.disable}
          component="a"
          target="_blank"
          rel="noreferrer"
          href={url}
          my={10}
          color="blue"
        >
          Generate Invoice
        </Button>
      )}
    </BlobProvider>
  </div>
);

export default PDFDocument;
