import { useParams } from "react-router-dom";
import Navbar from "../Navbar";
import {
  Center,
  Paper,
  SimpleGrid,
  Text,
  Card,
  Button,
  Group,
  Divider,
  Tooltip,
  Container,
  Grid,
  Tabs,
  Modal,
  NumberInput,
  Flex,
  Skeleton,
  createStyles,
  Anchor,
  Table,
} from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import { useEffect, useState } from "react";
import { supabase } from "../../../supabase/supabaseClient";
import ErrorPage from "../../404/404";
import AccessDenied from "../../403/accessDenied";
import {
  IconCalculator,
  IconGavel,
  IconCurrencyDollar,
  IconAward,
  IconConfetti,
  IconInfoCircle,
  IconFlag,
} from "@tabler/icons-react";
import CountdownTimer from "./Countdown/CountdownTimer";
import { useAuth } from "../../auth";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import { Notifications } from "@mantine/notifications";
import { useViewportSize } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
  // smaller than sm: Mobile
  // smaller than lg: Tablet, smaller screen PC display
  itemName: {
    fontSize: "17px",
    [theme.fn.smallerThan("lg")]: {
      fontSize: "15px",
    },
    [theme.fn.smallerThan("sm")]: {},
  },
  biddingCount: {
    fontSize: "17px",
    [theme.fn.smallerThan("lg")]: {
      fontSize: "15px",
    },
    [theme.fn.smallerThan("sm")]: {},
  },
  biddingPrice: {
    fontSize: "15px",
    [theme.fn.smallerThan("lg")]: {
      fontSize: "13px",
    },
    [theme.fn.smallerThan("sm")]: {},
  },
  buttonText: {
    fontSize: "15px",
    [theme.fn.smallerThan("lg")]: {
      fontSize: "13px",
    },
    [theme.fn.smallerThan("sm")]: {},
  },
  countdownText: {
    fontSize: "15px",
    [theme.fn.smallerThan("lg")]: {
      fontSize: "13px",
    },
    [theme.fn.smallerThan("sm")]: {},
  },
}));

function cardWidth(width) {
  if (width > 1240) {
    return 4;
  } else if (width < 1240 && width > 630) {
    return 6;
  } else {
    return 12;
  }
}

function convertTime(time) {
  // UTC timestamp string
  var utcTimestamp = time;

  // Convert UTC timestamp to JavaScript Date object
  var utcDate = new Date(utcTimestamp);

  // Define Singapore timezone offset in minutes (UTC+8)
  var sgTimezoneOffset = 480;

  // Calculate Singapore timestamp by adding the timezone offset
  var sgTimestamp = new Date(utcDate.getTime() + sgTimezoneOffset * 60 * 1000);

  // Format Singapore timestamp as "dd-mm-yyyy HH:MM"
  var formattedTimestamp = sgTimestamp.toLocaleDateString("en-SG", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  // Output the result
  return formattedTimestamp;
}

function convertTimeToMilliseconds(time) {
  // UTC timestamp string
  var utcTimestamp = time;

  // Convert UTC timestamp to JavaScript Date object
  var utcDate = new Date(utcTimestamp);

  // Define Singapore timezone offset in minutes (UTC+8)
  var sgTimezoneOffset = 480;

  // Calculate the Singapore timestamp by applying the timezone offset
  var sgTimestampMilliseconds =
    utcDate.getTime() + sgTimezoneOffset * 60 * 1000;

  // Output the result
  return sgTimestampMilliseconds;
}

export const revalidate = 2;

export default function Auction(props) {
  let params = useParams();
  const { classes } = useStyles();
  const { height, width } = useViewportSize();
  const [eventData, setEventData] = useState(null);
  const [errorCode, setErrorCode] = useState(null);
  const [catalogData, setCatalogData] = useState(null);
  const [incrementData, setIncrementData] = useState(null);
  const [itemImages, setItemImages] = useState(null);
  const [favouriteItemImages, setfavouriteItemImages] = useState([]);
  const [joinedEvent, setJoinedEvent] = useState(null);
  const [favouriteItem, setFavouriteItem] = useState(null);
  const [reload, setReload] = useState(false);
  const [maxBids, setMaxBids] = useState({});
  const [totalBid, setTotalBid] = useState({});
  const [highestBidder, setHighestBidder] = useState({});
  const [topTwoMaxBid, setTopTwoMaxBid] = useState(null);
  const [bidderNo, setBidderNo] = useState(null);
  const [userMaxBid, setUserMaxBid] = useState(null);
  const [currentEndTime, setCurrentEndTime] = useState({});
  const { user } = useAuth();
  const [numberInputError, setNumberInputError] = useState(null);
  const [maxBidInput, setMaxBidInput] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  const [modalCurrentBidCount, setModalCurrentBidCount] = useState(null);
  const [modalItemId, setModalItemId] = useState(null);
  const [clickCount, setClickCount] = useState(0);
  const [rows, setRows] = useState(null);
  const [clickCountSecond, setClickCountSecond] = useState(0);
  const [clickCountThird, setClickCountThird] = useState(0);
  const [openBiddingModal, setOpenBiddingModal] = useState(false);
  const shouldDisplayTimer =
    currentEndTime[modalItemId] >= new Date().toISOString();
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const closeSecondModal = () => setIsSecondModalOpen(false);
  const openSecondModal = () => {
    // Close the first modal and open the second modal
    setIsModalOpen(false);
    setIsSecondModalOpen(true);
  };
  const openFirstModal = () => {
    // Close the second modal and open the first modal
    setIsModalOpen(true);
    setIsSecondModalOpen(false);
  };
  async function fetchImageUrls(itemId) {
    const { data } = await supabase
      .from("images")
      .select("url")
      .filter("event_id", "eq", params.eventID)
      .filter("item_lot_no", "eq", itemId);
    if (data && data.length > 0) {
      return data.map((item) => item.url);
    } else {
      return [
        "https://mmbgljfxwvdcgkjwhwre.supabase.co/storage/v1/object/public/Documents/Images/Image_not_available.png",
      ];
    }
  }

  function getNearestValidBid(currentBid, increment, bidderPrice) {
    // Calculate the valid bid values based on the provided increment
    const validBids = [];
    for (let i = currentBid + increment; i <= bidderPrice; i += increment) {
      validBids.push(i);
    }

    // Check if the bidderPrice falls within the valid bid values
    if (validBids.includes(bidderPrice)) {
      return 0; // The bidderPrice is within the valid bids, return 0
    } else {
      // Find the nearest correct value and return it
      if (validBids.length === 0) {
        return currentBid + increment; // Return the smallest valid bid
      }

      let nearestBid = validBids.reduce((prev, curr) => {
        return Math.abs(curr - bidderPrice) < Math.abs(prev - bidderPrice)
          ? curr
          : prev;
      }, validBids[0]); // Provide initial value

      return nearestBid;
    }
  }

  function biddingTargetPrice(
    increment,
    max_manual_bid,
    second_highest_auto_bid,
    highest_auto_bid,
    starting_price
  ) {
    if (
      second_highest_auto_bid == 0 &&
      max_manual_bid > 0 &&
      max_manual_bid > highest_auto_bid
    ) {
      return 0;
    } else if (!max_manual_bid || max_manual_bid == 0) {
      return starting_price;
    } else if (
      second_highest_auto_bid > max_manual_bid &&
      second_highest_auto_bid < highest_auto_bid
    ) {
      return second_highest_auto_bid + increment;
    } else if (
      max_manual_bid > second_highest_auto_bid &&
      max_manual_bid < highest_auto_bid
    ) {
      return max_manual_bid + increment;
    } else if (
      max_manual_bid < highest_auto_bid &&
      second_highest_auto_bid === highest_auto_bid
    ) {
      return highest_auto_bid;
    } else if (max_manual_bid > highest_auto_bid) {
      // No need to auto bid
      return 0;
    } else if (
      max_manual_bid === second_highest_auto_bid &&
      max_manual_bid === highest_auto_bid
    ) {
      return max_manual_bid + increment;
    } else if (
      max_manual_bid === second_highest_auto_bid &&
      max_manual_bid < highest_auto_bid
    ) {
      return max_manual_bid + increment;
    } else if (
      max_manual_bid === highest_auto_bid &&
      max_manual_bid !== second_highest_auto_bid
    ) {
      return max_manual_bid;
    } else {
      return 0;
    }
  }

  function subtractMinutesFromDate(date, minutes) {
    // Convert UTC date to local time in Singapore (UTC+8)
    const singaporeOffset = 8 * 60; // Offset in minutes

    const a = new Date(date);
    const singaporeDate = new Date(a.getTime() + singaporeOffset * 60 * 1000);

    // Add the desired minutes
    singaporeDate.setMinutes(singaporeDate.getMinutes() - minutes);

    return singaporeDate;
  }

  function addMinutesFromDate(date, minutes) {
    // Convert UTC date to local time in Singapore (UTC+8)
    const singaporeOffset = 8 * 60; // Offset in minutes

    const a = new Date(date);
    const singaporeDate = new Date(a.getTime() + singaporeOffset * 60 * 1000);

    // Add the desired minutes
    singaporeDate.setMinutes(singaporeDate.getMinutes() + minutes);

    return singaporeDate;
  }

  // Extended Bidding (Check if bid falls between closing time - extension time and closing time) + (Check if bid is made during the extended time)
  async function extendedBidding(item_id) {
    const { data } = await supabase
      .from("event_items")
      .select()
      .filter("event_id", "eq", params.eventID)
      .filter("item_id", "eq", item_id);

    const { data: bidding_log_data } = await supabase
      .from("bidding_log")
      .select()
      .filter("event_id", "eq", params.eventID)
      .filter("item_id", "eq", item_id)
      .order("bidding_datetime", { ascending: false })
      .limit(1);

    // Check for first extension period (item_end_time - extension_time)
    if (
      bidding_log_data[0].bidding_datetime >=
        subtractMinutesFromDate(
          data[0].item_end_time,
          eventData[0].overtime_extension_min
        ) &&
      bidding_log_data[0].bidding_datetime <= data[0].item_end_time
    ) {
      await supabase
        .from("event_items")
        .update({
          item_final_end_time: addMinutesFromDate(
            bidding_log_data[0].bidding_datetime,
            eventData[0].overtime_extension_min
          ),
        })
        .filter("event_id", "eq", params.eventID)
        .filter("item_id", "eq", item_id);
    } else if (
      bidding_log_data[0].bidding_datetime > data[0].item_end_time &&
      bidding_log_data[0].bidding_datetime <= data[0].item_final_end_time
    ) {
      await supabase
        .from("event_items")
        .update({
          item_final_end_time: addMinutesFromDate(
            bidding_log_data[0].bidding_datetime,
            eventData[0].overtime_extension_min
          ),
        })
        .filter("event_id", "eq", params.eventID)
        .filter("item_id", "eq", item_id);
    } else {
      return "No data";
    }
  }

  async function maxBiddingPrice(itemId) {
    const { data } = await supabase
      .from("bidding_log")
      .select("price")
      .filter("event_id", "eq", params.eventID)
      .filter("item_id", "eq", itemId)
      .order("price", { ascending: false }) // Order each group by price in descending order
      .limit(1); // Limit each group to only one row (the one with the max price)
    if (data && data.length > 0) {
      return data;
    } else {
      return 0;
    }
  }

  async function itemEndTime(item_id) {
    const { data } = await supabase
      .from("event_items")
      .select("item_final_end_time")
      .filter("event_id", "eq", params.eventID)
      .filter("item_id", "eq", item_id);

    if (data && data.length > 0) {
      return data;
    } else {
      return 0;
    }
  }

  async function fetchHighestBidderNotif(modalItemId) {
    const data = await fetchHighestBidder(modalItemId);
    if (data && data.length > 0) {
      return data[0].bidder_no;
    }
  }

  // Return highest bidder no
  async function fetchHighestBidder(item_id) {
    const { data } = await supabase
      .from("bidding_log")
      .select()
      .filter("event_id", "eq", params.eventID)
      .filter("item_id", "eq", item_id)
      .order("price", { ascending: false }) // Order each group by price in descending order
      .order("bidding_datetime", { ascending: false }) // Order each group by price in descending order
      .limit(1); // Limit each group to only one row (the one with the max price)

    if (data && data.length > 0) {
      return data;
    } else {
      return 0;
    }
  }

  async function fetchHighestManualBiddingPrice(item_id) {
    const { data } = await supabase
      .from("bidding_log")
      .select("price")
      .filter("event_id", "eq", params.eventID)
      .filter("item_id", "eq", item_id)
      .order("price", { ascending: false }) // Order each group by price in descending order
      .limit(1); // Limit each group to only one row (the one with the max price)
    if (data && data.length > 0) {
      return data[0].price;
    } else {
      return 0;
    }
  }

  async function fetchHighestManualBiddingPriceBidderNo(item_id) {
    const { data } = await supabase
      .from("bidding_log")
      .select("bidder_no")
      .filter("event_id", "eq", params.eventID)
      .filter("item_id", "eq", item_id)
      .order("price", { ascending: false }) // Order each group by price in descending order
      .limit(1); // Limit each group to only one row (the one with the max price)
    if (data && data.length > 0) {
      return data[0].bidder_no;
    } else {
      return 0;
    }
  }

  async function fetchStartingPrice(item_id) {
    const { data } = await supabase
      .from("event_items")
      .select("starting_price")
      .filter("event_id", "eq", params.eventID)
      .filter("item_id", "eq", item_id);
    if (data && data.length > 0) {
      return data[0].starting_price;
    } else {
      return 0;
    }
  }

  async function fetchAutoBidData(item_id) {
    const { data } = await supabase
      .from("max_bid")
      .select()
      .filter("event_id", "eq", params.eventID)
      .filter("item_id", "eq", item_id)
      .order("price", { ascending: false }) // Order each group by price in descending order
      .limit(2); // Limit each group to only 2 rows
    if (data && data.length > 0) {
      setTopTwoMaxBid(data);
      return data;
    } else {
      setTopTwoMaxBid(null);
      return null;
    }
  }

  async function fetchMaxBiddingPrice(item_id, bidder_no) {
    const { data } = await supabase
      .from("max_bid")
      .select("price")
      .filter("event_id", "eq", params.eventID)
      .filter("item_id", "eq", item_id)
      .filter("bidder_no", "eq", bidder_no);
    if (data && data.length > 0) {
      setUserMaxBid(data[0].price);
      return data[0].price;
    } else {
      setUserMaxBid("");
    }
  }

  async function bidCount(itemId) {
    const { data } = await supabase
      .from("bidding_log")
      .select()
      .filter("event_id", "eq", params.eventID)
      .filter("item_id", "eq", itemId);

    if (data && data.length > 0) {
      return data.length;
    } else {
      return 0;
    }
  }

  function findIncrement(data, value) {
    try {
      for (const item of data) {
        if (item.from <= value && value <= item.to) {
          return item.increment;
        }
      }
    } catch (error) {}
    return null;
  }

  useEffect(() => {
    const fetchData = async () => {
      const [
        eventResponse,
        catalogResponse,
        bidderResponse,
        favouriteResponse,
        incrementResponse,
      ] = await Promise.all([
        supabase
          .from("event")
          .select()
          .filter("event_id", "eq", params.eventID)
          .filter("status", "eq", "in_progress"),

        supabase
          .from("event_items")
          .select()
          .eq("event_id", params.eventID)
          .order("item_lot_no", { ascending: true }),

        supabase.from("bidder").select().filter("uuid", "eq", user.id),

        supabase
          .from("favourite_item")
          .select()
          .filter("event_id", "eq", params.eventID)
          .filter("user_uuid", "eq", user.id)
          .order("item_lot_no", { ascending: true }),

        supabase
          .from("bidding_increment")
          .select()
          .filter("event_id", "eq", params.eventID),
      ]);

      const { data: eventData, error: eventError } = eventResponse;
      const { data: catalogData } = catalogResponse;
      const { data: joinedEvent } = bidderResponse;
      const { data: favouriteItem } = favouriteResponse;
      const { data: incrementData } = incrementResponse;

      if (eventData) {
        setEventData(eventData);
      }

      if (eventError) {
        setErrorCode(eventError.code);
      }

      if (catalogData) {
        setCatalogData(catalogData);
        // Fetch image URLs for each item using Promise.all
        const itemPromises = catalogData.map((item) =>
          fetchImageUrls(item.item_lot_no)
        );
        const itemImages = await Promise.all(itemPromises);
        setItemImages(itemImages);
      }

      if (joinedEvent) {
        // Set bidder no
        const bidderNumber = joinedEvent
          .filter((item) => item.event_id === params.eventID)
          .map((item) => item.bidder_no);
        setBidderNo(bidderNumber[0]);

        const arrayData = joinedEvent.map((item) => item.event_id);
        setJoinedEvent(arrayData);

        // Fetch Highest bidder for each item
        const fetchHighestBidderPromises = catalogData.map((item) =>
          fetchHighestBidder(item.item_id)
        );
        const highestBidderData = await Promise.all(fetchHighestBidderPromises);

        // Create an object to store the maximum bidding price for each item
        const highestBidderObj = {};
        catalogData.forEach((item, index) => {
          highestBidderObj[item.item_id] =
            highestBidderData[index] && highestBidderData[index].length > 0
              ? highestBidderData[index][0].bidder_no
              : 0;
        });
        setHighestBidder(highestBidderObj);

        // Fetch maximum bidding price for each item using Promise.all
        const maxBidPromises = catalogData.map((item) =>
          maxBiddingPrice(item.item_id)
        );
        const maxBidsData = await Promise.all(maxBidPromises);

        // Create an object to store the maximum bidding price for each item
        const maxBidsObj = {};
        catalogData.forEach((item, index) => {
          maxBidsObj[item.item_id] =
            maxBidsData[index] && maxBidsData[index].length > 0
              ? maxBidsData[index][0].price
              : catalogData[index].starting_price;
        });
        setMaxBids(maxBidsObj);

        // Fetch maximum bid count for each item using Promise.all
        const bidCountPromises = catalogData.map((item) =>
          bidCount(item.item_id)
        );
        const bidCountData = await Promise.all(bidCountPromises);

        // Create an object to store the maximum bidding count for each item
        const bidCountObj = {};
        catalogData.forEach((item, index) => {
          bidCountObj[item.item_id] = bidCountData[index]
            ? bidCountData[index]
            : 0;
        });
        setTotalBid(bidCountObj);

        // Fetch current end time for each item using Promise.all
        const endTimePromises = catalogData.map((item) =>
          itemEndTime(item.item_id)
        );
        const endTimeData = await Promise.all(endTimePromises);

        // Create an object to store the item end time for each item
        const endTimeObj = {};
        catalogData.forEach((item, index) => {
          endTimeObj[item.item_id] =
            endTimeData[index] && endTimeData[index].length > 0
              ? endTimeData[index][0].item_final_end_time
              : null;
        });
        setCurrentEndTime(endTimeObj);
      }

      if (favouriteItem) {
        setFavouriteItem(favouriteItem);
        // Fetch image URLs for each item using Promise.all
        const FavItemPromises = favouriteItem.map((item) =>
          fetchImageUrls(item.item_lot_no)
        );
        const FavItemImages = await Promise.all(FavItemPromises);
        setfavouriteItemImages(FavItemImages);
      }

      if (incrementData) {
        setIncrementData(incrementData);
      }
    };
    fetchData();
    setReload(false);

    // Function to revalidate the state when relevant tables are updated
    const revalidateStateOnTableUpdate = () => {
      fetchData();
    };

    // Subscribe to real-time updates on relevant tables
    const tableSubscription = supabase
      .channel("bidding_log_changes")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "bidding_log",
        },
        (payload) => revalidateStateOnTableUpdate()
      )
      .subscribe();

    const eventItemSubscription = supabase
      .channel("event_item_change")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "event_items",
        },
        (payload) => revalidateStateOnTableUpdate()
      )
      .subscribe();

    const maxBidSubscription = supabase
      .channel("max_bid_changes")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "max_bid",
        },
        (payload) => revalidateStateOnTableUpdate()
      )
      .subscribe();
  }, [params.eventID, reload]);

  if (
    eventData &&
    eventData.length > 0 &&
    !errorCode &&
    itemImages &&
    joinedEvent.includes(params.eventID)
  ) {
    return (
      <div>
        <Navbar />
        <Modal
          opened={openBiddingModal}
          onClose={() => setOpenBiddingModal(false)}
          title="Bidding History"
          size="auto"
        >
          <Table
            verticalSpacing="xs"
            horizontalSpacing="xs"
            withBorder
            withColumnBorders
            highlightOnHover
          >
            <thead>
              <tr>
                <th>Bidding time</th>
                <th>Bidder No</th>
                <th>Bid Price</th>
                <th>Bid Type</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </Modal>
        <Modal
          opened={isModalOpen}
          onClose={function () {
            closeModal();
            setClickCount(0);
            setNumberInputError(null);
            setMaxBidInput("");
          }}
          title={
            <Text fw={500} td="underline">
              Place your bid
            </Text>
          }
          size="md"
          centered
        >
          <Container pl={0}>
            <Group spacing="xs">
              <Flex gap="none" direction="column">
                <Text fw={700}>SGD {maxBids[modalItemId]}</Text>
                <Text fz="xs" color="grey">
                  Bid Price
                </Text>
              </Flex>
              <Text>+</Text>
              <Flex gap="none" direction="column">
                <Text fw={700}>
                  SGD{" "}
                  {(
                    maxBids[modalItemId] *
                    0.01 *
                    eventData[0].gst_percentage
                  ).toFixed(2)}
                </Text>
                <Text fz="xs" color="grey">
                  GST
                </Text>
              </Flex>
              <Text>+</Text>
              <Flex gap="none" direction="column">
                <Text fw={700}>
                  SGD{" "}
                  {(
                    maxBids[modalItemId] *
                    0.01 *
                    eventData[0].buyer_premium_percentage
                  ).toFixed(2)}
                </Text>
                <Text fz="xs" color="grey">
                  Buyer Premium
                </Text>
              </Flex>
            </Group>

            <Grid mt={10}>
              <Grid.Col span={4}>
                <Text>
                  <b>{totalBid[modalItemId]}</b> bids
                </Text>
              </Grid.Col>
              <Grid.Col span={2}></Grid.Col>
              <Grid.Col span={4}>
                <Group spacing="xs" position="right">
                  <CountdownTimer
                    targetDate={convertTimeToMilliseconds(
                      currentEndTime[modalItemId]
                    )}
                    type={"short"}
                    onCloseModal={(shouldClose) => {
                      if (shouldClose) {
                        closeModal(); // Close the modal based on the callback
                      }
                    }}
                  />
                </Group>
              </Grid.Col>
            </Grid>

            {highestBidder[modalItemId] === bidderNo ? (
              <Group spacing="xs">
                <IconAward size="2rem" color="green" />
                <Text fz="lg" fw={700} color="green">
                  You are highest bidder
                </Text>
              </Group>
            ) : (
              <div>
                <Group spacing="md" mt={20} mb={20} grow>
                  <Button
                    color={clickCount > 0 ? "green" : "blue"}
                    radius="md"
                    onClick={async function () {
                      setClickCount(clickCount + 1);
                      setClickCountSecond(0);
                      setClickCountThird(0);
                      fetchAutoBidData(modalItemId);
                      if (clickCount === 1) {
                        const { error } = await supabase
                          .from("bidding_log")
                          .insert({
                            item_id: modalItemId,
                            bidder_no: bidderNo,
                            price:
                              totalBid[modalItemId] > 0
                                ? maxBids[modalItemId] +
                                  findIncrement(
                                    incrementData,
                                    maxBids[modalItemId]
                                  )
                                : maxBids[modalItemId],
                            user_uuid: user.id,
                            event_id: params.eventID,
                          });

                        await extendedBidding(modalItemId);

                        const { data } = await supabase
                          .from("max_bid")
                          .select()
                          .filter("event_id", "eq", params.eventID)
                          .filter("item_id", "eq", modalItemId)
                          .order("price", { ascending: false }) // Order each group by price in descending order
                          .limit(2); // Limit each group to only 2 rows

                        if (data.length > 0) {
                          const biddingTarget = biddingTargetPrice(
                            findIncrement(incrementData, maxBids[modalItemId]),
                            await fetchHighestManualBiddingPrice(modalItemId),
                            data[1] ? await data[1].price : 0,
                            await data[0].price,
                            await fetchStartingPrice(modalItemId)
                          );

                          if (biddingTarget > 0) {
                            const { error } = await supabase
                              .from("bidding_log")
                              .insert({
                                item_id: modalItemId,
                                bidder_no: data[0].bidder_no,
                                price: biddingTarget,
                                auction_type: "auto_bid",
                                event_id: params.eventID,
                              });
                          }
                        }
                        setIsModalOpen(false);
                        setClickCount(0);
                        setReload(true);
                        (await fetchHighestBidderNotif(modalItemId)) ===
                        bidderNo
                          ? Notifications.show({
                              icon: <IconConfetti size="1rem" />,
                              color: "green",
                              title: "You are the highest bidder!",
                              autoClose: 5000,
                            })
                          : Notifications.show({
                              icon: <IconFlag size="1rem" />,
                              color: "yellow",
                              title:
                                "You have been outbid! You may want to raise your bid.",
                              autoClose: 15000,
                            });
                      }
                    }}
                  >
                    {clickCount === 0 ? (
                      totalBid[modalItemId] > 0 ? (
                        `Bid ${
                          maxBids[modalItemId] +
                          findIncrement(incrementData, maxBids[modalItemId])
                        }`
                      ) : (
                        `Bid ${maxBids[modalItemId]}`
                      )
                    ) : (
                      <Text>Confirm ?</Text>
                    )}
                  </Button>
                  <Button
                    color={clickCountSecond > 0 ? "green" : "blue"}
                    radius="md"
                    onClick={async function () {
                      setClickCountSecond(clickCountSecond + 1);
                      setClickCount(0);
                      setClickCountThird(0);
                      fetchAutoBidData(modalItemId);
                      if (clickCountSecond === 1) {
                        const { error } = await supabase
                          .from("bidding_log")
                          .insert({
                            item_id: modalItemId,
                            bidder_no: bidderNo,
                            price:
                              totalBid[modalItemId] > 0
                                ? maxBids[modalItemId] +
                                  findIncrement(
                                    incrementData,
                                    maxBids[modalItemId]
                                  ) +
                                  findIncrement(
                                    incrementData,
                                    maxBids[modalItemId] +
                                      findIncrement(
                                        incrementData,
                                        maxBids[modalItemId]
                                      )
                                  )
                                : maxBids[modalItemId] +
                                  findIncrement(
                                    incrementData,
                                    maxBids[modalItemId]
                                  ),
                            user_uuid: user.id,
                            event_id: params.eventID,
                          });
                        const biddingTarget = biddingTargetPrice(
                          findIncrement(incrementData, maxBids[modalItemId]),
                          await fetchHighestManualBiddingPrice(modalItemId),
                          topTwoMaxBid[1].price,
                          topTwoMaxBid[0].price,
                          await fetchStartingPrice(modalItemId)
                        );
                        if (biddingTarget > 0) {
                          const { error } = await supabase
                            .from("bidding_log")
                            .insert({
                              item_id: modalItemId,
                              bidder_no: topTwoMaxBid[0].bidder_no,
                              price: biddingTarget,
                              auction_type: "auto_bid",
                              event_id: params.eventID,
                            });
                        }
                        setIsModalOpen(false);
                        setClickCountSecond(0);
                        setReload(true);
                        (await fetchHighestBidderNotif(modalItemId)) ===
                        bidderNo
                          ? Notifications.show({
                              icon: <IconConfetti size="1rem" />,
                              color: "green",
                              title: "You are the highest bidder!",
                              autoClose: 5000,
                            })
                          : Notifications.show({
                              icon: <IconFlag size="1rem" />,
                              color: "yellow",
                              title:
                                "You have been outbid! You may want to raise your bid.",
                              autoClose: 15000,
                            });
                      }
                    }}
                  >
                    {clickCountSecond === 0 ? (
                      totalBid[modalItemId] > 0 ? (
                        `Bid ${
                          maxBids[modalItemId] +
                          findIncrement(incrementData, maxBids[modalItemId]) +
                          findIncrement(
                            incrementData,
                            maxBids[modalItemId] +
                              findIncrement(incrementData, maxBids[modalItemId])
                          )
                        }`
                      ) : (
                        `Bid ${
                          maxBids[modalItemId] +
                          findIncrement(incrementData, maxBids[modalItemId])
                        }`
                      )
                    ) : (
                      <Text>Confirm ?</Text>
                    )}
                  </Button>
                  <Button
                    color={clickCountThird > 0 ? "green" : "blue"}
                    radius="md"
                    onClick={async function () {
                      setClickCountThird(clickCountThird + 1);
                      setClickCount(0);
                      setClickCountSecond(0);
                      fetchAutoBidData(modalItemId);
                      if (clickCountThird === 1) {
                        const { error } = await supabase
                          .from("bidding_log")
                          .insert({
                            item_id: modalItemId,
                            bidder_no: bidderNo,
                            price:
                              totalBid[modalItemId] > 0
                                ? maxBids[modalItemId] +
                                  findIncrement(
                                    incrementData,
                                    maxBids[modalItemId]
                                  ) +
                                  findIncrement(
                                    incrementData,
                                    maxBids[modalItemId] +
                                      findIncrement(
                                        incrementData,
                                        maxBids[modalItemId]
                                      )
                                  ) +
                                  findIncrement(
                                    incrementData,
                                    maxBids[modalItemId] +
                                      findIncrement(
                                        incrementData,
                                        maxBids[modalItemId]
                                      ) +
                                      findIncrement(
                                        incrementData,
                                        maxBids[modalItemId] +
                                          findIncrement(
                                            incrementData,
                                            maxBids[modalItemId]
                                          )
                                      )
                                  )
                                : maxBids[modalItemId] +
                                  findIncrement(
                                    incrementData,
                                    maxBids[modalItemId]
                                  ) +
                                  findIncrement(
                                    incrementData,
                                    maxBids[modalItemId] +
                                      findIncrement(
                                        incrementData,
                                        maxBids[modalItemId]
                                      )
                                  ),
                            user_uuid: user.id,
                            event_id: params.eventID,
                          });
                        const biddingTarget = biddingTargetPrice(
                          findIncrement(incrementData, maxBids[modalItemId]),
                          await fetchHighestManualBiddingPrice(modalItemId),
                          topTwoMaxBid[1].price,
                          topTwoMaxBid[0].price,
                          maxBids[modalItemId]
                        );
                        if (biddingTarget > 0) {
                          const { error } = await supabase
                            .from("bidding_log")
                            .insert({
                              item_id: modalItemId,
                              bidder_no: topTwoMaxBid[0].bidder_no,
                              price: biddingTarget,
                              auction_type: "auto_bid",
                              event_id: params.eventID,
                            });
                        }
                        setIsModalOpen(false);
                        setClickCountThird(0);
                        setReload(true);
                        (await fetchHighestBidderNotif(modalItemId)) ===
                        bidderNo
                          ? Notifications.show({
                              icon: <IconConfetti size="1rem" />,
                              color: "green",
                              title: "You are the highest bidder!",
                              autoClose: 5000,
                            })
                          : Notifications.show({
                              icon: <IconFlag size="1rem" />,
                              color: "yellow",
                              title:
                                "You have been outbid! You may want to raise your bid.",
                              autoClose: 15000,
                            });
                      }
                    }}
                  >
                    {clickCountThird === 0 ? (
                      totalBid[modalItemId] > 0 ? (
                        `Bid ${
                          maxBids[modalItemId] +
                          findIncrement(incrementData, maxBids[modalItemId]) +
                          findIncrement(
                            incrementData,
                            maxBids[modalItemId] +
                              findIncrement(incrementData, maxBids[modalItemId])
                          ) +
                          findIncrement(
                            incrementData,
                            maxBids[modalItemId] +
                              findIncrement(
                                incrementData,
                                maxBids[modalItemId]
                              ) +
                              findIncrement(
                                incrementData,
                                maxBids[modalItemId] +
                                  findIncrement(
                                    incrementData,
                                    maxBids[modalItemId]
                                  )
                              )
                          )
                        }`
                      ) : (
                        `Bid ${
                          maxBids[modalItemId] +
                          findIncrement(incrementData, maxBids[modalItemId]) +
                          findIncrement(
                            incrementData,
                            maxBids[modalItemId] +
                              findIncrement(incrementData, maxBids[modalItemId])
                          )
                        }`
                      )
                    ) : (
                      <Text>Confirm ?</Text>
                    )}
                  </Button>
                </Group>
              </div>
            )}
            <Divider my="xs" mb={20} label="or" labelPosition="center" />
            <Text fw={500} underline>
              Your Max Bid
            </Text>
            <NumberInput
              mt={20}
              icon={<IconCurrencyDollar size="1rem" />}
              error={numberInputError}
              placeholder={userMaxBid}
              value={maxBidInput}
              precision={2}
              onChange={(event) => setMaxBidInput(event)}
              hideControls
              data-autoFocus
            />
            <Button
              radius="md"
              fullWidth
              mt={20}
              onClick={async function () {
                if (!/^[0-9]+(\.[0-9]+)?$/.test(maxBidInput)) {
                  setNumberInputError("Please key in number only!");
                } else if (
                  /^[0-9]+(\.[0-9]+)?$/.test(maxBidInput) &&
                  maxBidInput <= maxBids[modalItemId]
                ) {
                  setNumberInputError(
                    `Bid price must be more than ${maxBids[modalItemId]}`
                  );
                } else if (
                  getNearestValidBid(
                    maxBids[modalItemId],
                    findIncrement(incrementData, maxBidInput),
                    maxBidInput
                  ) > 0
                ) {
                  setNumberInputError(
                    `Please bid in increment of ${findIncrement(
                      incrementData,
                      maxBidInput
                    )}. The nearest valid bid is $${getNearestValidBid(
                      maxBids[modalItemId],
                      findIncrement(incrementData, maxBidInput),
                      maxBidInput
                    )}.`
                  );
                } else if (
                  (await fetchMaxBiddingPrice(modalItemId, bidderNo)) >=
                  maxBidInput
                ) {
                  setNumberInputError("Please bid more than your max bid!");
                } else {
                  setNumberInputError(null);
                  openSecondModal();
                }
              }}
            >
              Review Bid
            </Button>
            <Text fz="xs" mt={20}>
              By confirming your bid, you commit to purchasing the item if you
              win, indicating your acceptance of our sales terms; non-compliance
              will lead to platform suspension.
            </Text>
          </Container>
        </Modal>

        <Modal
          opened={isSecondModalOpen}
          onClose={closeSecondModal}
          title={
            <Text fw={500} td="underline">
              Review Your Bid
            </Text>
          }
          size="md"
          centered
        >
          <Container pl={0}>
            <Group spacing="xs" mb={10}>
              <Text fw={700}>Current Bid: </Text>
              <Text>SGD {maxBids[modalItemId]}</Text>
            </Group>
            <Group spacing="xs" mb={10}>
              <Text fw={700}>Total Bid: </Text>
              <Text>{modalCurrentBidCount}</Text>
            </Group>
            <Group spacing="xs" mb={10}>
              <Text fw={700}>Your Max Bid: </Text>
              <Text>SGD {maxBidInput}</Text>
            </Group>
            <Group spacing="xs" mb={10}>
              <Text fw={700}>Time left: </Text>
              <Text>
                {
                  <CountdownTimer
                    targetDate={convertTimeToMilliseconds(
                      currentEndTime[modalItemId]
                    )}
                    type={"short"}
                    onCloseModal={(shouldClose) => {
                      if (shouldClose) {
                        setIsSecondModalOpen(false); // Close the modal based on the callback
                      }
                    }}
                  />
                }
              </Text>
            </Group>
            <Group spacing="xs" mb={20}>
              <Text fw={700}>Estimated Total: </Text>
              <Text fw={700}>
                SGD{" "}
                {maxBidInput
                  ? (
                      maxBidInput +
                      maxBidInput * 0.01 * eventData[0].gst_percentage +
                      maxBidInput * 0.01 * eventData[0].buyer_premium_percentage
                    ).toFixed(2)
                  : ""}
              </Text>
              <Tooltip
                label={`Inclusive of GST  $${(
                  maxBidInput *
                  0.01 *
                  eventData[0].gst_percentage
                ).toFixed(2)} + Buyer Premium $${(
                  maxBidInput *
                  0.01 *
                  eventData[0].buyer_premium_percentage
                ).toFixed(2)}`}
              >
                <IconInfoCircle size="1rem" />
              </Tooltip>
            </Group>
            <Group spacing="md" position="right">
              <Button
                variant="outline"
                onClick={function () {
                  openFirstModal();
                }}
              >
                Edit Bid
              </Button>
              <Button
                onClick={async function () {
                  if (await fetchMaxBiddingPrice(modalItemId, bidderNo)) {
                    const { error } = await supabase
                      .from("max_bid")
                      .update({
                        price: maxBidInput,
                        updated_at: new Date().toISOString(),
                      })
                      .filter("bidder_no", "eq", bidderNo)
                      .filter("event_id", "eq", params.eventID)
                      .filter("item_id", "eq", modalItemId);

                    if (error) {
                      console.log(error);
                    }
                  } else {
                    const { error } = await supabase.from("max_bid").insert({
                      bidder_no: bidderNo,
                      event_id: params.eventID,
                      item_id: modalItemId,
                      price: maxBidInput,
                    });

                    if (error) {
                      console.log(error);
                    }
                  }

                  const { data } = await supabase
                    .from("max_bid")
                    .select()
                    .filter("event_id", "eq", params.eventID)
                    .filter("item_id", "eq", modalItemId)
                    .order("price", { ascending: false }) // Order each group by price in descending order
                    .limit(2); // Limit each group to only 2 rows

                  if (data.length > 0) {
                    const biddingTarget = biddingTargetPrice(
                      findIncrement(incrementData, maxBids[modalItemId]),
                      await fetchHighestManualBiddingPrice(modalItemId),
                      data[1] ? await data[1].price : 0,
                      await data[0].price,
                      maxBids[modalItemId]
                    );

                    // 1. There are 2 bidders with max bids
                    if (
                      biddingTarget &&
                      biddingTarget > 0 &&
                      data.length > 1 &&
                      data[0].bidder_no !== bidderNo
                    ) {
                      const { error } = await supabase
                        .from("bidding_log")
                        .insert({
                          item_id: modalItemId,
                          bidder_no: data[0].bidder_no,
                          price: biddingTarget,
                          auction_type: "auto_bid",
                          event_id: params.eventID,
                        });
                      // 2. There are no max bid but there are manual bids (Manual bid not done by user)
                    } else if (
                      biddingTarget &&
                      biddingTarget > 0 &&
                      maxBids[modalItemId] &&
                      (await fetchHighestManualBiddingPriceBidderNo(
                        modalItemId
                      )) !== bidderNo &&
                      data.length === 1
                    ) {
                      const { error } = await supabase
                        .from("bidding_log")
                        .insert({
                          item_id: modalItemId,
                          bidder_no: data[0].bidder_no,
                          price: biddingTarget,
                          auction_type: "auto_bid",
                          event_id: params.eventID,
                        });
                      // 3. There are no max bid and no manual bids
                    } else if (
                      biddingTarget &&
                      biddingTarget > 0 &&
                      !maxBids[modalItemId] &&
                      data.length === 1
                    ) {
                      const { error } = await supabase
                        .from("bidding_log")
                        .insert({
                          item_id: modalItemId,
                          bidder_no: data[0].bidder_no,
                          price: biddingTarget,
                          auction_type: "auto_bid",
                          event_id: params.eventID,
                        });
                    }
                  }

                  setIsSecondModalOpen(false);
                  setReload(true);
                  Notifications.show({
                    color: "green",
                    title: "Max bid saved!",
                    autoClose: 5000,
                  });
                  (await fetchHighestBidderNotif(modalItemId)) === bidderNo
                    ? Notifications.show({
                        icon: <IconConfetti size="1rem" />,
                        color: "green",
                        title: "You are the highest bidder!",
                        autoClose: 5000,
                      })
                    : Notifications.show({
                        icon: <IconFlag size="1rem" />,
                        color: "yellow",
                        title:
                          "You have been outbid! You may want to raise your bid.",
                        autoClose: 15000,
                      });
                }}
              >
                Confirm Bid
              </Button>
            </Group>
          </Container>
        </Modal>
        <Center>
          <Paper shadow="md" p="md" radius="md" mb={50} w="90%">
            <Center>
              <h2>{eventData[0].name}</h2>
            </Center>
            <SimpleGrid cols={width > 1080 ? 4 : 2}>
              <div>
                <Text fz="md">
                  <b>Start Datetime: </b>
                  {convertTime(eventData[0].start_datetime)}
                </Text>
              </div>
              <div>
                <Text>
                  <b>End Datetime: </b>
                  {convertTime(eventData[0].end_datetime)}
                </Text>
              </div>
              <div>
                <Text>
                  <b>Total Lots: </b>
                  {catalogData.length}
                </Text>
              </div>
              <div>
                <Text>
                  <b>Auction Ends In: </b>
                  <CountdownTimer
                    targetDate={convertTimeToMilliseconds(
                      eventData[0].end_datetime
                    )}
                    type="long"
                  />
                </Text>
              </div>
            </SimpleGrid>
          </Paper>
        </Center>
        <Center>
          <Paper shadow="md" p="md" radius="md" mb={50} w="90%">
            <Tabs defaultValue="listings">
              <Tabs.List position="center">
                <Tabs.Tab value="listings">
                  <Text>Listings</Text>
                </Tabs.Tab>
                <Tabs.Tab value="favourite">
                  <Text>Favourite</Text>
                </Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel value="listings" pt="xs">
                <Grid grow>
                  {catalogData.map((item, index) => (
                    <Grid.Col span={cardWidth(width)}>
                      <Card
                        shadow="sm"
                        padding="md"
                        radius="md"
                        withBorder
                        style={{ height: "auto" }}
                      >
                        <Card.Section style={{ height: "auto" }}>
                          <Carousel
                            align="start"
                            slideSize="100%"
                            styles={{
                              control: {
                                "&[data-inactive]": {
                                  opacity: 0,
                                  cursor: "default",
                                },
                              },
                            }}
                          >
                            {itemImages[index].map((imageUrl, imageIndex) => (
                              <img
                                key={imageIndex}
                                src={
                                  imageUrl
                                    ? `${imageUrl}`
                                    : "https://mmbgljfxwvdcgkjwhwre.supabase.co/storage/v1/object/public/Documents/Images/Image_not_available.png"
                                }
                                alt={item.name}
                                style={{
                                  width: "100%",
                                  height: "21vw",
                                  objectFit: "contain",
                                }}
                                loading="lazy"
                              />
                            ))}
                          </Carousel>
                        </Card.Section>
                        <Text fz="md" color="gray">
                          Lot {item.item_lot_no}
                        </Text>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Text className={classes.itemName}>{item.name}</Text>
                        </div>
                        <div>
                          <Anchor
                            className={classes.biddingCount}
                            component="button"
                            onClick={async function () {
                              const { data } = await supabase.rpc(
                                "get_bidding_data",
                                {
                                  item_id: item.item_id,
                                }
                              );

                              const rows = data.map((item) => (
                                <tr key={item.bidding_datetime}>
                                  <td>{convertTime(item.bidding_datetime)}</td>
                                  <td>{item.bidder_no}</td>
                                  <td>{item.price}</td>
                                  <td>{item.auction_type}</td>
                                </tr>
                              ));
                              setRows(rows);
                              setOpenBiddingModal(true);
                            }}
                          >
                            {totalBid[item.item_id]}{" "}
                            {totalBid[item.item_id] === 0 ? "Bid" : "Bids"}
                          </Anchor>
                        </div>

                        <Divider />
                        <Skeleton
                          visible={
                            !maxBids[item.item_id] &&
                            !currentEndTime[item.item_id]
                          }
                        >
                          <Group
                            py={10}
                            spacing={0.2}
                            style={{
                              display: "flex",
                              flexDirection: "column", // Change to column direction on smaller screens
                              alignItems: "center",
                            }}
                          >
                            <Tooltip
                              label={
                                <Text fz="md">
                                  Estimate SGD ${item.estimate_min} - SGD $
                                  {item.estimate_max}
                                </Text>
                              }
                            >
                              <IconCalculator size="1rem" />
                            </Tooltip>
                            <Text className={classes.biddingPrice}>
                              {totalBid[item.item_id] > 0
                                ? "Current Bid"
                                : "Starting Bid"}
                              : <b>SGD {maxBids[item.item_id]}</b>
                            </Text>
                            <div
                              style={{
                                marginTop: "10px", // Add some space between the previous item and the CountdownTimer
                              }}
                            >
                              <CountdownTimer
                                targetDate={convertTimeToMilliseconds(
                                  currentEndTime[item.item_id]
                                )}
                                type={"short"}
                              />
                            </div>
                          </Group>
                        </Skeleton>
                        <Divider />
                        <Container py={10} px={0}>
                          <Grid grow>
                            <Grid.Col span={6} mt={0}>
                              {favouriteItem
                                ?.map((target) => target.item_id)
                                .includes(item.item_id) ? (
                                <Tooltip
                                  label="Remove from favourite"
                                  color="blue"
                                  position="left"
                                >
                                  <Button
                                    w="100%"
                                    color="green"
                                    onClick={async function () {
                                      await supabase
                                        .from("favourite_item")
                                        .delete()
                                        .filter("user_uuid", "eq", user.id)
                                        .filter(
                                          "event_id",
                                          "eq",
                                          params.eventID
                                        )
                                        .filter(
                                          "item_lot_no",
                                          "eq",
                                          item.item_lot_no
                                        );
                                      setReload(true);
                                      Notifications.show({
                                        color: "orange",
                                        title:
                                          "Item removed from favourite list!",
                                        autoClose: 5000,
                                      });
                                    }}
                                  >
                                    Unlike
                                  </Button>
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  label="Add to favourite"
                                  color="blue"
                                  position="left"
                                >
                                  <Button
                                    w="100%"
                                    color="red"
                                    onClick={async function () {
                                      await supabase
                                        .from("favourite_item")
                                        .insert({
                                          user_uuid: user.id,
                                          event_id: params.eventID,
                                          item_id: item.item_id,
                                          name: item.name,
                                          item_lot_no: item.item_lot_no,
                                          starting_price: item.starting_price,
                                          item_end_time: item.item_end_time,
                                          item_final_end_time:
                                            item.item_final_end_time,
                                          estimate_min: item.estimate_min,
                                          estimate_max: item.estimate_max,
                                          image_url: item.image_url,
                                        });
                                      setReload(true);
                                      Notifications.show({
                                        color: "green",
                                        title: "Item added to favourite list!",
                                        autoClose: 5000,
                                      });
                                    }}
                                  >
                                    Like
                                  </Button>
                                </Tooltip>
                              )}
                            </Grid.Col>

                            <Grid.Col span={6}>
                              {item.item_final_end_time >
                              new Date().toISOString() ? (
                                <Button
                                  leftIcon={<IconGavel size="1rem" />}
                                  w="100%"
                                  onClick={async function () {
                                    await fetchMaxBiddingPrice(
                                      item.item_id,
                                      bidderNo
                                    );
                                    openModal();
                                    setModalCurrentBidCount(
                                      totalBid[item.item_id]
                                    );
                                    setModalItemId(item.item_id);
                                  }}
                                >
                                  <Text className={classes.buttonText}>
                                    Bid
                                  </Text>
                                </Button>
                              ) : (
                                <Button
                                  leftIcon={<IconGavel size="1rem" />}
                                  w="100%"
                                  disabled
                                >
                                  <Text className={classes.buttonText}>
                                    Ended
                                  </Text>
                                </Button>
                              )}
                            </Grid.Col>
                          </Grid>
                        </Container>
                      </Card>
                    </Grid.Col>
                  ))}
                </Grid>
              </Tabs.Panel>

              {/* Favourite panel */}
              <Tabs.Panel value="favourite" pt="xs">
                <Grid>
                  {favouriteItem?.map((item, index) => (
                    <Grid.Col span={cardWidth(width)}>
                      <Card
                        shadow="sm"
                        padding="md"
                        radius="md"
                        style={{ height: "auto" }}
                        withBorder
                      >
                        <Card.Section style={{ height: "auto" }}>
                          <Carousel
                            align="start"
                            slideSize="100%"
                            styles={{
                              control: {
                                "&[data-inactive]": {
                                  opacity: 0,
                                  cursor: "default",
                                },
                              },
                            }}
                          >
                            {favouriteItemImages[index] &&
                              favouriteItemImages[index]?.map(
                                (imageUrl, imageIndex) => (
                                  <img
                                    key={imageIndex}
                                    src={
                                      imageUrl
                                        ? `${imageUrl}`
                                        : "https://mmbgljfxwvdcgkjwhwre.supabase.co/storage/v1/object/public/Documents/Images/Image_not_available.png"
                                    }
                                    alt={item.name}
                                    style={{
                                      width: "100%",
                                      height: "21vw",
                                      objectFit: "contain",
                                    }}
                                    loading="lazy"
                                  />
                                )
                              )}
                          </Carousel>
                        </Card.Section>
                        <Text fz="md" color="gray">
                          Lot {item.item_lot_no}
                        </Text>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Text className={classes.itemName}>{item.name}</Text>
                        </div>
                        <div>
                          <Anchor
                            className={classes.biddingCount}
                            component="button"
                            onClick={async function () {
                              const { data } = await supabase.rpc(
                                "get_bidding_data",
                                {
                                  item_id: item.item_id,
                                }
                              );

                              const rows = data.map((item) => (
                                <tr key={item.bidding_datetime}>
                                  <td>{convertTime(item.bidding_datetime)}</td>
                                  <td>{item.bidder_no}</td>
                                  <td>{item.price}</td>
                                  <td>{item.auction_type}</td>
                                </tr>
                              ));
                              setRows(rows);
                              setOpenBiddingModal(true);
                            }}
                          >
                            {totalBid[item.item_id]}{" "}
                            {totalBid[item.item_id] === 0 ? "Bid" : "Bids"}
                          </Anchor>
                        </div>

                        <Divider />
                        <Skeleton
                          visible={
                            !maxBids[item.item_id] &&
                            !currentEndTime[item.item_id]
                          }
                        >
                          <Group
                            py={10}
                            spacing={0.2}
                            style={{
                              display: "flex",
                              flexDirection: "column", // Change to column direction on smaller screens
                              alignItems: "center",
                            }}
                          >
                            <Tooltip
                              label={
                                <Text fz="md">
                                  Estimate SGD ${item.estimate_min} - SGD $
                                  {item.estimate_max}
                                </Text>
                              }
                            >
                              <IconCalculator size="1rem" />
                            </Tooltip>
                            <Text className={classes.biddingPrice}>
                              {totalBid[item.item_id] > 0
                                ? "Current Bid"
                                : "Starting Bid"}
                              : <b>SGD {maxBids[item.item_id]}</b>
                            </Text>
                            <div
                              style={{
                                marginTop: "10px", // Add some space between the previous item and the CountdownTimer
                              }}
                            >
                              <CountdownTimer
                                targetDate={convertTimeToMilliseconds(
                                  currentEndTime[item.item_id]
                                )}
                                type={"short"}
                              />
                            </div>
                          </Group>
                        </Skeleton>
                        <Divider />
                        <Container py={10} px={0}>
                          <Grid grow>
                            <Grid.Col span={4} mt={0}>
                              <Tooltip
                                label="Remove from favourite"
                                color="blue"
                                position="left"
                              >
                                <Button
                                  color="green"
                                  w="100%"
                                  onClick={async function () {
                                    await supabase
                                      .from("favourite_item")
                                      .delete()
                                      .filter("user_uuid", "eq", user.id)
                                      .filter("event_id", "eq", params.eventID)
                                      .filter(
                                        "item_lot_no",
                                        "eq",
                                        item.item_lot_no
                                      );
                                    setReload(true);
                                    Notifications.show({
                                      color: "orange",
                                      title:
                                        "Item removed from favourite list!",
                                      autoClose: 5000,
                                    });
                                  }}
                                >
                                  Unlike
                                </Button>
                              </Tooltip>
                            </Grid.Col>

                            <Grid.Col span={4}>
                              {item.item_final_end_time >
                              new Date().toISOString() ? (
                                <Button
                                  leftIcon={<IconGavel size="1rem" />}
                                  w="100%"
                                  onClick={async function () {
                                    await fetchMaxBiddingPrice(
                                      item.item_id,
                                      bidderNo
                                    );
                                    openModal();
                                    setModalCurrentBidCount(
                                      totalBid[item.item_id]
                                    );
                                    setModalItemId(item.item_id);
                                  }}
                                >
                                  <Text className={classes.buttonText}>
                                    Bid
                                  </Text>
                                </Button>
                              ) : (
                                <Button
                                  leftIcon={<IconGavel size="1rem" />}
                                  w="100%"
                                  disabled
                                >
                                  <Text className={classes.buttonText}>
                                    Ended
                                  </Text>
                                </Button>
                              )}
                            </Grid.Col>
                          </Grid>
                        </Container>
                      </Card>
                    </Grid.Col>
                  ))}
                </Grid>
              </Tabs.Panel>
            </Tabs>
          </Paper>
        </Center>
      </div>
    );
  } else if (!eventData && errorCode === "22P02") {
    return (
      <div>
        <ErrorPage />
      </div>
    );
  } else if (
    eventData &&
    eventData.length > 0 &&
    !errorCode &&
    itemImages &&
    !joinedEvent.includes(params.eventID)
  ) {
    return (
      <div>
        <AccessDenied />
      </div>
    );
  } else {
    return (
      <div>
        <LoadingScreen />
      </div>
    );
  }
}
