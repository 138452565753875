import {
  createStyles,
  Header,
  Group,
  Button,
  Divider,
  Box,
  Burger,
  Drawer,
  ScrollArea,
  rem,
  Modal,
  Menu,
  Center,
  Collapse,
  UnstyledButton,
  Text,
  ThemeIcon,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import SignIn from "../SignIn";
import Registration from "../Registration";
import { useState } from "react";
import {
  IconChevronDown,
  IconGavel,
  IconBuildingStore,
  IconBusinessplan,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  link: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: "none",
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,

    [theme.fn.smallerThan("sm")]: {
      height: rem(42),
      display: "flex",
      alignItems: "center",
      width: "100%",
    },

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    }),
  },

  hiddenMobile: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  subLink: {
    width: "100%",
    padding: "20px",
    borderRadius: "10px",
    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    }),
  },
}));

export default function Navbar(props) {
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const [signInOpen, setSignInOpen] = useState(false);
  const [registerOpen, setRegisterOpen] = useState(false);
  const { classes, theme } = useStyles();
  const navigate = useNavigate();
  function handleRegistrationSuccess() {
    setRegisterOpen(false); // Close the registration modal
  }

  function handleRegistrationSuccessForSignIn() {
    setSignInOpen(false); // Close the registration modal
  }

  return (
    <div>
      <Modal
        opened={signInOpen}
        onClose={() => setSignInOpen(false)}
        withCloseButton={false}
      >
        <SignIn onSuccess={handleRegistrationSuccessForSignIn} />
      </Modal>
      <Modal
        opened={registerOpen}
        onClose={() => setRegisterOpen(false)}
        withCloseButton={false}
      >
        <Registration onSuccess={handleRegistrationSuccess} />
      </Modal>
      <Box pb={50}>
        <Header height={60} px="md">
          <Group position="apart" sx={{ height: "100%" }}>
            <img
              alt="Logo"
              style={{ width: 228, height: 53 }}
              src="https://i1.wp.com/assethub.sg/wp-content/uploads/2019/02/cropped-AssetHub-Logo-Rec-e1551345217372-1.png?fit=3299%2C802"
            />
            <Group
              sx={{ height: "100%" }}
              spacing={0}
              className={classes.hiddenMobile}
            >
              <Menu trigger="hover" openDelay={100} closeDelay={400}>
                <Menu.Target>
                  <a
                    onClick={(event) => event.preventDefault()}
                    className={classes.link}
                    href="/"
                  >
                    <Center>
                      <span
                        className={classes.link}
                        style={{ paddingRight: "10px" }}
                      >
                        Services
                      </span>
                      <IconChevronDown size={rem(12)} stroke={1.5} />
                    </Center>
                  </a>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    className={classes.link}
                    onClick={() => navigate("/home/auction")}
                  >
                    Auction
                  </Menu.Item>
                  <Menu.Item
                    className={classes.link}
                    onClick={() => navigate("/home/marketplace")}
                  >
                    MarketPlace
                  </Menu.Item>
                  <Menu.Item
                    className={classes.link}
                    onClick={() => navigate("/home/valuation")}
                  >
                    Valuation
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
              <a href="/" className={classes.link}>
                Contact Us
              </a>
            </Group>

            <Group className={classes.hiddenMobile}>
              <Button onClick={() => setSignInOpen(true)} variant="default">
                Log in
              </Button>
              <Button onClick={() => setRegisterOpen(true)}>Sign up</Button>
            </Group>

            <Burger
              opened={drawerOpened}
              onClick={toggleDrawer}
              className={classes.hiddenDesktop}
            />
          </Group>
        </Header>

        <Drawer
          opened={drawerOpened}
          onClose={closeDrawer}
          size="100%"
          padding="md"
          title="Navigation"
          className={classes.hiddenDesktop}
          zIndex={1000000}
        >
          <ScrollArea h={`calc(100vh - ${rem(60)})`} mx="-md">
            <Divider
              my="sm"
              color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
            />
            <UnstyledButton
              type="a"
              className={classes.link}
              onClick={function (event) {
                event.preventDefault();
                toggleLinks();
              }}
              href="/"
            >
              Services
            </UnstyledButton>

            <Collapse in={linksOpened}>
              <UnstyledButton
                className={classes.subLink}
                onClick={() => navigate("/home/auction")}
              >
                <Group wrap="nowrap" align="flex-start">
                  <ThemeIcon size={20} variant="default" radius="md">
                    <IconGavel
                      style={{ width: rem(22), height: rem(22) }}
                      color={theme.colors.blue[6]}
                    />
                  </ThemeIcon>
                  <div>
                    <Text size="sm" fw={500}>
                      Auction
                    </Text>
                  </div>
                </Group>
              </UnstyledButton>
              <UnstyledButton
                className={classes.subLink}
                onClick={() => navigate("/home/marketplace")}
              >
                <Group wrap="nowrap" align="flex-start">
                  <ThemeIcon size={20} variant="default" radius="md">
                    <IconBuildingStore
                      style={{ width: rem(22), height: rem(22) }}
                      color={theme.colors.blue[6]}
                    />
                  </ThemeIcon>
                  <div>
                    <Text size="sm" fw={500}>
                      MarketPlace
                    </Text>
                  </div>
                </Group>
              </UnstyledButton>
              <UnstyledButton
                className={classes.subLink}
                onClick={() => navigate("/home/valuation")}
              >
                <Group wrap="nowrap" align="flex-start">
                  <ThemeIcon size={20} variant="default" radius="md">
                    <IconBusinessplan
                      style={{ width: rem(22), height: rem(22) }}
                      color={theme.colors.blue[6]}
                    />
                  </ThemeIcon>
                  <div>
                    <Text size="sm" fw={500}>
                      Valuation
                    </Text>
                  </div>
                </Group>
              </UnstyledButton>
            </Collapse>
            <a href="/" className={classes.link}>
              Contact Us
            </a>

            <Divider
              my="sm"
              color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
            />

            <Group position="center" grow pb="xl" px="md">
              <Button
                onClick={function () {
                  setSignInOpen(true);
                  closeDrawer();
                }}
                variant="default"
              >
                Log in
              </Button>
              <Button
                onClick={function () {
                  setRegisterOpen(true);
                  closeDrawer();
                }}
              >
                Sign up
              </Button>
            </Group>
          </ScrollArea>
        </Drawer>
      </Box>
    </div>
  );
}
