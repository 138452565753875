import { useEffect, useState } from "react";
import Navbar from "../MainPage/Navbar";
import {
  createStyles,
  Select,
  SimpleGrid,
  Table,
  Center,
  Container,
  Group,
  Paper,
  Text,
  ThemeIcon,
  Tooltip,
  Button,
} from "@mantine/core";
import { supabase } from "../../supabase/supabaseClient";
import {
  IconReportMoney,
  IconHelpHexagonFilled,
  IconPackages,
} from "@tabler/icons-react";
import PDFDocument from "./pdf/InvoicePDF";
import { Notifications } from "@mantine/notifications";
import emailjs from "@emailjs/browser";

const useStyles = createStyles((theme) => ({
  // smaller than sm: Mobile
  // smaller than lg: Tablet, smaller screen PC display
  container: {
    width: "100%",
    margin: "20px",
    maxWidth: "70rem",
    [theme.fn.smallerThan("lg")]: {},
    [theme.fn.smallerThan("sm")]: {},
  },
  select: {
    width: "100%",
    marginTop: "20px",
  },
  table: {
    marginTop: "20px",
  },
}));

const calculateFunc = (data, gst, buyerPremium, consignmentFee) => {
  // Initialize variables to store the results
  let unpaidTotal = 0;

  // Iterate through the data and calculate the total final price
  data.forEach((item) => {
    // Check if payment_status is "unpaid" and final_price is not null
    if (item.payment_status === "unpaid" && item.final_price !== null) {
      unpaidTotal +=
        item.final_price +
        item.final_price * (gst + buyerPremium + consignmentFee) * 0.01;
    }
  });

  // Create a new object to store the results
  const resultObject = {
    unpaidTotal,
  };

  return resultObject;
};

export async function handleInvoiceCompletion(
  actual_event_id,
  actual_bidder_no
) {
  try {
    const { error } = await supabase.rpc("auction_complete_invoice_func", {
      p_event_id: actual_event_id,
      target_bidder_no: actual_bidder_no,
    });
    if (error) {
      console.log("Invoice completion error:", error.message);
    }
  } catch (error) {
    console.error("Error:", error.message);
  }
}

export default function BuyerInvoiceManagement(props) {
  const { classes } = useStyles();
  const [completedEvents, setCompletedEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [buyerEvent, setBuyerEvent] = useState([]);
  const [buyerData, setBuyerData] = useState([]);
  const [selectedBuyer, setSelectedBuyer] = useState(null);
  const [selectedBuyerData, setSelectedBuyerData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [unpaidTotal, setUnpaidTotal] = useState(0);
  const [totalLot, setTotalLot] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [allCompletedData, setAllCompletedData] = useState([]);
  const [gst, setGst] = useState(null);
  const [buyerPremium, setBuyerPremium] = useState(null);
  const [consignmentFee, setConsignmentFee] = useState(null);
  const [selectedEventName, setSelectedEventName] = useState(null);
  const [reload, setReload] = useState(false);

  const fetchBuyerData = async () => {
    const { data, error } = await supabase.rpc("get_buyer_info", {
      event_id: selectedEvent,
    });

    if (data && data.length > 0) {
      const renamedBuyerData = data.map((item) => ({
        value: item.uuid,
        label: item.name,
      }));
      setBuyerData(data);
      setBuyerEvent(renamedBuyerData);
    }

    if (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchCompletedEventData = async () => {
      const { data, error } = await supabase
        .from("event")
        .select(
          "event_id, name, gst_percentage, buyer_premium_percentage, consignment_fee_percentage"
        )
        .eq("status", "completed");

      if (data && data.length > 0) {
        // Rename the keys to value and label to fit the parameters in the select component
        const renamedData = data.map((item) => ({
          value: item.event_id,
          label: item.name,
        }));
        setCompletedEvents(renamedData);
        setAllCompletedData(data);
      }

      if (error) {
        console.log(error);
      }
    };

    const fetchSelectedBuyerData = (uuid) => {
      if (buyerData && buyerData.length > 0) {
        const filterAllCompletedData = allCompletedData.filter(
          (item) => item.event_id === selectedEvent
        );
        setGst(filterAllCompletedData[0].gst_percentage);
        setConsignmentFee(filterAllCompletedData[0].consignment_fee_percentage);
        setBuyerPremium(filterAllCompletedData[0].buyer_premium_percentage);
        setSelectedEventName(filterAllCompletedData[0].name);
        const filteredData = buyerData.filter((item) => item.uuid === uuid);
        setSelectedBuyerData(filteredData);
        if (filteredData) {
          const arrayData = calculateFunc(
            filteredData,
            filterAllCompletedData[0].gst_percentage,
            filterAllCompletedData[0].buyer_premium_percentage,
            filterAllCompletedData[0].consignment_fee_percentage
          );
          setTotalLot(arrayData.totalLot);
          setTotalAmount(arrayData.totalFinalPrice);
          setUnpaidTotal(arrayData.unpaidTotal);

          // Create table data
          const row = filteredData.map((element) => (
            <tr key={element.item_lot_no}>
              <td>{element.item_lot_no}</td>
              <td>{element.item_name}</td>
              <td>{element.category}</td>
              <td>{element.final_price.toFixed(2)}</td>
              <td>{element.payment_status}</td>
            </tr>
          ));
          setTableData(row);
        }
      }
    };

    fetchCompletedEventData();
    fetchBuyerData();
    fetchSelectedBuyerData(selectedBuyer);
    setReload(false);
  }, [selectedEvent, selectedBuyer, reload]);

  return (
    <div>
      <Navbar />
      <Center>
        <Container className={classes.container}>
          <SimpleGrid spacing="md" cols={1}>
            <div>
              <Select
                className={classes.select}
                label="Completed Event:"
                data={completedEvents}
                onChange={setSelectedEvent}
                placeholder="Choose event"
                searchable
                nothingFound="No options"
                maxDropdownHeight={280}
              />
            </div>
            <div>
              <Select
                className={classes.select}
                label="Select Buyer:"
                data={buyerEvent}
                onChange={setSelectedBuyer}
                placeholder="Choose buyer"
                searchable
                nothingFound="No options"
                maxDropdownHeight={280}
              />
            </div>
            <Group grow>
              {PaperStats(
                "Total items",
                totalLot,
                <IconPackages size="1.8rem" stroke={1.5} />,
                "Number of items bought"
              )}
              {PaperStats(
                "Total Amount Sold",
                totalAmount.toFixed(2),
                <IconReportMoney size="1.8rem" stroke={1.5} />,
                "Total price sold excluding gst and fees"
              )}
              {PaperStats(
                "Grand Total",
                (
                  totalAmount +
                  totalAmount * gst * 0.01 +
                  totalAmount * consignmentFee * 0.01 +
                  totalAmount * buyerPremium * 0.01
                ).toFixed(2),
                <IconReportMoney size="1.8rem" stroke={1.5} />,
                `Total price inclusive of ${gst}% GST, ${buyerPremium}% buyer premium and ${consignmentFee}% consignment fee`
              )}
              {PaperStats(
                "Unpaid total",
                unpaidTotal.toFixed(2),
                <IconHelpHexagonFilled size="1.8rem" stroke={1.5} />,
                `Total unpaid inclusive of ${gst}% GST, ${buyerPremium}% buyer premium and ${consignmentFee}% consignment fee`
              )}
            </Group>
          </SimpleGrid>
          {tableData && selectedBuyerData.length > 0 ? (
            <>
              <Group>
                <PDFDocument
                  disable={selectedBuyerData[0].invoice_no === null}
                  buyer_name={selectedBuyerData[0].name}
                  buyer_address={selectedBuyerData[0].address}
                  invoice_no={selectedBuyerData[0].invoice_no}
                  invoice_date={selectedBuyerData[0].extracted_date.substring(
                    0,
                    10
                  )}
                  buyerDataArray={selectedBuyerData.map((item) => ({
                    item_lot_no: item.item_lot_no,
                    item_name: item.item_name,
                    final_price: item.final_price,
                  }))}
                  totalAmountSold={totalAmount}
                  gst={gst}
                  buyerPremium={buyerPremium}
                  consignmentFee={consignmentFee}
                />
                <Button
                  onClick={async function () {
                    await handleInvoiceCompletion(
                      selectedEvent,
                      selectedBuyerData[0].bidder_no
                    );
                    await fetchBuyerData(); // Wait for fetchBuyerData to complete
                    setReload(true);
                    Notifications.show({
                      color: "green",
                      title: "Invoice finalised successfully.",
                      autoClose: 5000,
                    });
                  }}
                  color={selectedBuyerData[0].invoice_no ? "green" : "blue"}
                >
                  {selectedBuyerData[0].invoice_no
                    ? "Invoice Finalised"
                    : "Finalise invoice"}
                </Button>
                <Button
                  disabled={selectedBuyerData[0].invoice_no === null}
                  onClick={async () => {
                    // Email bidder their invoice
                    try {
                      await emailjs.send(
                        "invoice_email",
                        "template_u19h5jl",
                        {
                          bidder_name: selectedBuyerData[0].name,
                          due_date: selectedBuyerData[0].extracted_date,
                          auction_name: selectedEventName,
                          reply_to: selectedBuyerData[0].email,
                        },
                        process.env.REACT_APP_EMAILJS_KEY
                      );
                      // Update the event_items table that an invoice has been emailed
                      await supabase
                        .from("event_items")
                        .update({ invoice_sent: true })
                        .eq("invoice_no", selectedBuyerData[0].invoice_no);

                      Notifications.show({
                        color: "green",
                        title: "Invoice sent to bidder successfully.",
                        autoClose: 5000,
                      });
                    } catch (error) {
                      Notifications.show({
                        color: "red",
                        title: { error },
                        autoClose: 5000,
                      });
                    }
                  }}
                  color={selectedBuyerData[0].invoice_sent ? "green" : "blue"}
                >
                  {selectedBuyerData[0].invoice_sent
                    ? "Invoice Sent"
                    : "Send Invoice"}
                </Button>
              </Group>
              <Table
                className={classes.table}
                verticalSpacing="sm"
                withColumnBorders
                withBorder
              >
                <thead>
                  <tr>
                    <th>Lot Number</th>
                    <th>Name</th>
                    <th>Category</th>
                    <th>Sold Price</th>
                    <th>Paid/Unpaid</th>
                  </tr>
                </thead>
                <tbody>{tableData}</tbody>
              </Table>
            </>
          ) : (
            ""
          )}
        </Container>
      </Center>
    </div>
  );
}

function PaperStats(title, value, icon, label) {
  return (
    <Tooltip label={label} multiline w={150} color="blue">
      <Paper shadow="md" p="md" radius="md" h="100%" withBorder>
        <Group position="apart">
          <div>
            <Text c="dimmed" tt="uppercase" fw={700} fz="xs">
              {title}
            </Text>
            <Text fw={700} fz="xl">
              {value}
            </Text>
          </div>

          <ThemeIcon color="blue" variant="light" size={38} radius="md">
            {icon}
          </ThemeIcon>
        </Group>
      </Paper>
    </Tooltip>
  );
}
