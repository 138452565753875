import { useParams } from "react-router-dom";
import Navbar from "../Navbar";
import { useEffect, useState } from "react";
import { supabase } from "../../../supabase/supabaseClient";
import ErrorPage from "../../404/404";
import {
  Text,
  Paper,
  Center,
  SimpleGrid,
  Button,
  Group,
  Tooltip,
  ScrollArea,
  Table,
} from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import { IconFileDescription, IconBook2 } from "@tabler/icons-react";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import { useViewportSize } from "@mantine/hooks";

function convertTime(time) {
  // UTC timestamp string
  var utcTimestamp = time;

  // Convert UTC timestamp to JavaScript Date object
  var utcDate = new Date(utcTimestamp);

  // Define Singapore timezone offset in minutes (UTC+8)
  var sgTimezoneOffset = 480;

  // Calculate Singapore timestamp by adding the timezone offset
  var sgTimestamp = new Date(utcDate.getTime() + sgTimezoneOffset * 60 * 1000);

  // Format Singapore timestamp as "dd-mm-yyyy HH:MM"
  var formattedTimestamp = sgTimestamp.toLocaleDateString("en-SG", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  // Output the result
  return formattedTimestamp;
}

function eventStatus(item) {
  switch (item) {
    case "not_started":
      return "Not Started";
    case "in_progress":
      return "Live";
    case "completed":
      return "Completed";
    default:
      return "Unknown";
  }
}

export default function ShowEvent(props) {
  let params = useParams();
  const [eventData, setEventData] = useState(null);
  const [catalogData, setCatalogData] = useState(null);
  const [errorCode, setErrorCode] = useState(null);
  const [catalogTable, setCatalogTable] = useState(null);
  const [itemImages, setItemImages] = useState(null);
  const { height, width } = useViewportSize();

  async function fetchImageUrls(itemId) {
    const { data, error } = await supabase
      .from("images")
      .select("url")
      .filter("event_id", "eq", params.eventID)
      .filter("item_lot_no", "eq", itemId);
    if (data && data.length > 0) {
      return data.map((item) => item.url);
    } else {
      return [
        "https://mmbgljfxwvdcgkjwhwre.supabase.co/storage/v1/object/public/Documents/Images/Image_not_available.png",
      ];
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const [eventResponse, catalogResponse, tableResponse] = await Promise.all(
        [
          supabase.from("event").select().eq("event_id", params.eventID),

          supabase
            .from("event_items")
            .select()
            .eq("event_id", params.eventID)
            .order("item_lot_no", { ascending: true }),

          supabase
            .from("event_items")
            .select()
            .eq("event_id", params.eventID)
            .order("item_lot_no", { ascending: true }),
        ]
      );

      const { data: eventData, error: eventError } = eventResponse;
      const { data: catalogData } = catalogResponse;
      const { data: tableResponseData } = tableResponse;

      if (eventData) {
        setEventData(eventData);
      }

      if (eventError) {
        setErrorCode(eventError.code);
      }

      if (catalogData) {
        setCatalogData(catalogData);
      }

      if (tableResponseData) {
        setCatalogTable(tableResponse.data);
        // Fetch image URLs for each item using Promise.all
        const itemPromises = tableResponse.data.map((item) =>
          fetchImageUrls(item.item_lot_no)
        );
        const itemImages = await Promise.all(itemPromises);
        setItemImages(itemImages);
      }
    };

    fetchData();
  }, [params.eventID]);

  if (eventData && !errorCode) {
    const rows = catalogData
      ? catalogData.map((item, index) => (
          <tr key={item.item_id}>
            <td>{item.item_lot_no}</td>
            <td>{item.category}</td>
            <td>{item.name}</td>
            <td>{item.starting_price}</td>
            <td>{convertTime(item.item_end_time)}</td>
            <td>
              {itemImages && itemImages[index] ? (
                <Carousel
                  maw={200}
                  mx="auto"
                  withIndicators
                  styles={{
                    control: {
                      "&[data-inactive]": {
                        opacity: 0,
                        cursor: "default",
                      },
                    },
                  }}
                >
                  {itemImages[index].map((imageUrl, imageIndex) => (
                    <img
                      key={imageIndex}
                      src={
                        imageUrl
                          ? `${imageUrl}?${Date.now()}`
                          : "https://mmbgljfxwvdcgkjwhwre.supabase.co/storage/v1/object/public/Documents/Images/Image_not_available.png"
                      }
                      alt={item.name}
                      style={{ width: "100%", height: "100%" }}
                    />
                  ))}
                </Carousel>
              ) : (
                // Render a placeholder or empty state when itemImages is not available
                <div>No images available</div>
              )}
            </td>
          </tr>
        ))
      : [];

    return (
      <div>
        <Navbar />
        <Center>
          <Paper shadow="md" p="md" radius="md" mb={50} w="70%">
            <Center>
              <h2>{eventData[0].name}</h2>
            </Center>
            <SimpleGrid cols={width > 1080 ? 5 : 3}>
              <div>
                <Text>
                  <b>Start Datetime: </b>
                  {convertTime(eventData[0].start_datetime)}
                </Text>
              </div>
              <div>
                <Text>
                  <b>End Datetime: </b>
                  {convertTime(eventData[0].end_datetime)}
                </Text>
              </div>
              <div>
                <Text>
                  <b>Status: </b>
                  {eventStatus(eventData[0].status)}
                </Text>
              </div>
              <div>
                <Text>
                  <b>Type: </b>
                  {eventData[0].event_type}
                </Text>
              </div>
              <div>
                <Text>
                  <b>Total Lots: </b>
                  {catalogData.length}
                </Text>
              </div>
            </SimpleGrid>
          </Paper>
        </Center>
        <Center>
          <Paper shadow="md" p="md" radius="md" mb={50} w="70%">
            <Center>
              <h2>Information</h2>
            </Center>
            <Group position="center">
              {eventData[0].tos_url ? (
                <Button
                  component="a"
                  href={eventData[0].tos_url}
                  leftIcon={<IconFileDescription />}
                >
                  Terms of Sales
                </Button>
              ) : (
                <Tooltip
                  label="Terms of sales is not uploaded yet. Please come back again later!"
                  color="yellow"
                >
                  <span>
                    <Button
                      disabled
                      component="a"
                      leftIcon={<IconFileDescription />}
                    >
                      Terms of Sales
                    </Button>
                  </span>
                </Tooltip>
              )}

              {eventData[0].catalog_url ? (
                <Button
                  component="a"
                  href={eventData[0].catalog_url}
                  leftIcon={<IconBook2 />}
                >
                  Catalogue
                </Button>
              ) : (
                <Tooltip
                  label="Catalogue is not ready yet. Please come back again later!"
                  color="yellow"
                >
                  <span>
                    <Button disabled component="a" leftIcon={<IconBook2 />}>
                      Catalogue
                    </Button>
                  </span>
                </Tooltip>
              )}
            </Group>
          </Paper>
        </Center>

        <Center>
          <Paper shadow="md" p="md" radius="md" mb={50} w="70%">
            <Center>
              <h2>Catalogue</h2>
            </Center>
            <ScrollArea>
              <Table
                sx={{ minWidth: 800 }}
                verticalSpacing="sm"
                withBorder
                withColumnBorders
                highlightOnHover
              >
                <thead>
                  <tr>
                    <th>LOT_NO</th>
                    <th>CATEGORIES</th>
                    <th>NAME</th>
                    <th>STARTING_PRICE</th>
                    <th>ITEM_ENDTIME</th>
                    <th>IMAGE</th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </Table>
            </ScrollArea>
          </Paper>
        </Center>
      </div>
    );
  } else if (!eventData && errorCode === "22P02") {
    return <ErrorPage />;
  } else {
    return (
      <div>
        <LoadingScreen />
      </div>
    );
  }
}
