import {
  Container,
  Paper,
  Text,
  Card,
  Image,
  Badge,
  Button,
  Grid,
  Group,
  ThemeIcon,
  Modal,
  Table,
  Divider,
  Anchor,
  List,
} from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import classes from "./style.module.css";
import {
  IconRocket,
  IconCalendarTime,
  IconCash,
  IconUserPlus,
  IconEye,
  IconLogin,
  IconUserCheck,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { supabase } from "../../../supabase/supabaseClient";
import { useAuth } from "../../auth";
import { Notifications } from "@mantine/notifications";

const calculateFunc = (data, gst, buyerPremium, consignmentFee) => {
  // Initialize variables to store the results
  let totalFinalPrice = 0;
  let unpaidTotal = 0;
  let totalLot = data.length;

  // Iterate through the data and calculate the total final price
  data.forEach((item) => {
    totalFinalPrice += item.final_price;

    // Check if payment_status is "unpaid" and final_price is not null
    if (item.payment_status === "unpaid" && item.final_price !== null) {
      unpaidTotal +=
        item.final_price +
        item.final_price * (gst + buyerPremium + consignmentFee) * 0.01;
    }
  });

  // Create a new object to store the results
  const resultObject = {
    totalFinalPrice,
    unpaidTotal,
    totalLot,
  };

  return resultObject;
};

function convertTime(time) {
  // UTC timestamp string
  var utcTimestamp = time;

  // Convert UTC timestamp to JavaScript Date object
  var utcDate = new Date(utcTimestamp);

  // Define Singapore timezone offset in minutes (UTC+8)
  var sgTimezoneOffset = 480;

  // Calculate Singapore timestamp by adding the timezone offset
  var sgTimestamp = new Date(utcDate.getTime() + sgTimezoneOffset * 60 * 1000);

  // Format Singapore timestamp as "dd-mm-yyyy HH:MM"
  var formattedTimestamp = sgTimestamp.toLocaleDateString("en-SG", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  // Output the result
  return formattedTimestamp;
}

export default function FrontPage(props) {
  const [ongoingData, setOngoingData] = useState(null);
  const [ongoingDataLength, setOngoingDataLength] = useState(0);
  const [upcomingData, setUpcomingData] = useState(null);
  const [upcomingDataLength, setUpcomingDataLength] = useState(0);
  const [completedData, setCompletedData] = useState(null);
  const [completedDataLength, setCompletedDataLength] = useState(0);
  const [userData, setUserData] = useState(null);
  const [unpaidTotal, setUnpaidTotal] = useState(0);
  const [registeredEvents, setRegisterEvents] = useState([]);
  const { user } = useAuth();
  const [refreshData, setRefreshData] = useState(false);
  const { height, width } = useViewportSize();
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const [rows, setRows] = useState(null);

  useEffect(() => {
    const fetchOngoing = async () => {
      const { data, error } = await supabase
        .from("event")
        .select()
        .filter("status", "in", '("in_progress")')
        .order("start_datetime", { ascending: true });
      if (data) {
        setOngoingData(data);
        setOngoingDataLength(data.length);
      }

      if (error) {
        setOngoingData(null);
      }
    };

    const fetchUpcoming = async () => {
      const { data, error } = await supabase
        .from("event")
        .select()
        .filter("status", "in", '("not_started")')
        .order("start_datetime", { ascending: true });
      if (data) {
        setUpcomingData(data);
        setUpcomingDataLength(data.length);
      }

      if (error) {
        setUpcomingData(null);
      }
    };

    const fetchCompleted = async () => {
      const { data, error } = await supabase
        .from("event")
        .select()
        .filter("status", "in", '("completed")')
        .order("start_datetime", { ascending: true });
      if (data) {
        setCompletedData(data);
        setCompletedDataLength(data.length);
      }

      if (error) {
        setCompletedData(null);
      }
    };

    // Fetch buyer info for unpaid invoice
    const fetchUserData = async () => {
      const { data, error } = await supabase.rpc("get_user_data", {
        user_uuid: user.id,
      });

      if (data && data.length > 0) {
        setUserData(data);
        // Calculate unpaid total
        const arrayData = calculateFunc(
          data,
          data[0].gst_percentage,
          data[0].buyer_premium_percentage,
          data[0].consignment_fee_percentage
        );
        setUnpaidTotal(arrayData.unpaidTotal);

        const rows = data.map((item) => (
          <tr key={item.event_id}>
            <td>{item.event_name}</td>
            <td>{item.name}</td>
            <td>{item.final_price.toFixed(2)}</td>
            <td>
              {(item.final_price * item.gst_percentage * 0.01).toFixed(2)}
            </td>
            <td>
              {(
                item.final_price *
                item.buyer_premium_percentage *
                0.01
              ).toFixed(2)}
            </td>
            <td>
              {(
                item.final_price *
                item.consignment_fee_percentage *
                0.01
              ).toFixed(2)}
            </td>
            <td>
              {(
                item.final_price +
                item.final_price * item.gst_percentage * 0.01 +
                item.final_price * item.buyer_premium_percentage * 0.01 +
                item.final_price * item.consignment_fee_percentage * 0.01
              ).toFixed(2)}
            </td>
          </tr>
        ));
        setRows(rows);
      }

      if (error) {
        console.log(error);
      }
    };

    // Check all the registered events for target user
    const fetchTargetEvent = async () => {
      const { data, error } = await supabase
        .from("bidder")
        .select("bidder_no, event_id")
        .eq("uuid", user.id);

      if (data) {
        const event_id_array = data.map((item) => item.event_id);
        setRegisterEvents(event_id_array);
      }
    };

    fetchOngoing();
    fetchUpcoming();
    fetchCompleted();
    fetchUserData();
    fetchTargetEvent();
  }, [refreshData]);

  return (
    <div>
      <Modal
        opened={openModal}
        onClose={() => setOpenModal(false)}
        title="Unpaid Items"
        size="auto"
      >
        <Table
          sx={{ minWidth: 800 }}
          verticalSpacing="sm"
          withBorder
          withColumnBorders
          highlightOnHover
        >
          <thead>
            <tr>
              <th>Event</th>
              <th>Item</th>
              <th>Sold Price</th>
              <th>GST</th>
              <th>Buyer Premium</th>
              <th>Consignment Fee</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
        <Divider my="sm" size="md" />
        <Text>Payment can be done through:</Text>
        <List>
          <List.Item>
            <Text>Bank Transfer (UOB Bank A/C No. 365 308 582 4)</Text>
          </List.Item>
          <List.Item>
            <Text>PayNow (UEN 201811649D)</Text>
          </List.Item>
        </List>
        <Text>
          Please contact{" "}
          <Anchor
            href="mailto:info@assethubmarketplace.com"
            target="_blank"
            underline="hover"
          >
            us
          </Anchor>{" "}
          once you have made payment.
        </Text>
      </Modal>
      <Container size="xl" className={classes.container}>
        <Grid grow>
          <Grid.Col span={4}>
            <Paper shadow="md" p="md" radius="lg" h="100%" withBorder>
              <Group position="apart">
                <div>
                  <Text c="dimmed" tt="uppercase" fw={700} fz="xs">
                    Ongoing Events
                  </Text>
                  <Text fw={700} fz="xl">
                    {ongoingDataLength}
                  </Text>
                </div>

                <ThemeIcon color="green" variant="light" size={38} radius="md">
                  <IconRocket />
                </ThemeIcon>
              </Group>
            </Paper>
          </Grid.Col>
          <Grid.Col span={4}>
            <Paper shadow="md" p="md" radius="lg" h="100%" withBorder>
              <Group position="apart">
                <div>
                  <Text c="dimmed" tt="uppercase" fw={700} fz="xs">
                    Upcoming Events
                  </Text>
                  <Text fw={700} fz="xl">
                    {upcomingDataLength}
                  </Text>
                </div>

                <ThemeIcon color="yellow" variant="light" size={38} radius="md">
                  <IconCalendarTime />
                </ThemeIcon>
              </Group>
            </Paper>
          </Grid.Col>
          <Grid.Col span={4}>
            <Paper
              shadow="md"
              p="md"
              radius="lg"
              h="100%"
              withBorder
              style={{ cursor: "pointer" }}
              onClick={() => setOpenModal(true)}
            >
              <Group position="apart">
                <div>
                  <Text c="dimmed" tt="uppercase" fw={700} fz="xs">
                    Payment Due
                  </Text>
                  <Text fw={700} fz="xl">
                    {unpaidTotal.toFixed(2)}
                  </Text>
                </div>

                <ThemeIcon color="red" variant="light" size={38} radius="md">
                  <IconCash />
                </ThemeIcon>
              </Group>
            </Paper>
          </Grid.Col>
        </Grid>

        {/* Ongoing Auction */}
        <div className={classes.ongoingAuctionContainer}>
          <Text className={classes.ongoingAuctionTitle}>Ongoing Auction</Text>
          {ongoingData && ongoingData.length > 0 ? (
            <Grid grow={width < 1200}>
              {ongoingData.map((item, index) => (
                <Grid.Col span={4}>
                  <Card
                    shadow="sm"
                    padding="lg"
                    radius="md"
                    h="100%"
                    withBorder
                  >
                    <Card.Section>
                      <Image
                        src={
                          item.event_image_url
                            ? item.event_image_url
                            : "https://mmbgljfxwvdcgkjwhwre.supabase.co/storage/v1/object/public/Documents/Images/Image_not_available.png"
                        }
                        height={200}
                        alt="Event Image"
                        fit="contain"
                      />
                    </Card.Section>

                    <Group justify="space-between" mt="md" mb="xs">
                      <Text fw={500}>{item.name}</Text>
                      <Badge color="red" variant="dot">
                        Live
                      </Badge>
                    </Group>

                    <Text size="sm" c="dimmed">
                      {item.event_description
                        ? item.event_description
                        : "No Description"}
                    </Text>

                    {registeredEvents.includes(item.event_id) ? (
                      <Group grow>
                        <Button
                          variant="filled"
                          fullWidth
                          mt="md"
                          radius="md"
                          leftIcon={<IconLogin />}
                          onClick={function () {
                            navigate(`auction/${item.event_id}`);
                          }}
                        >
                          Enter
                        </Button>
                      </Group>
                    ) : (
                      <>
                        {width > 1100 ? (
                          <Group grow>
                            <Button
                              variant="filled"
                              color="green"
                              fullWidth
                              mt="md"
                              radius="md"
                              leftIcon={<IconUserPlus />}
                              onClick={async function () {
                                const { data } = await supabase
                                  .from("bidder")
                                  .select()
                                  .filter("event_id", "eq", item.event_id)
                                  .order("bidder_no", { ascending: false })
                                  .limit(1);

                                if (data && data.length > 0) {
                                  const nextBidderNo = data[0].bidder_no + 1;
                                  await supabase.from("bidder").insert({
                                    uuid: user.id,
                                    bidder_no: nextBidderNo,
                                    event_id: item.event_id,
                                  });
                                  Notifications.show({
                                    color: "green",
                                    title: "Registration successful!",
                                    autoClose: 5000,
                                  });
                                } else {
                                  await supabase.from("bidder").insert({
                                    uuid: user.id,
                                    bidder_no: 1,
                                    event_id: item.event_id,
                                  });
                                  Notifications.show({
                                    color: "green",
                                    title: "Registration successful!",
                                    autoClose: 5000,
                                  });
                                }
                                // Update the state to trigger a refresh
                                setRefreshData((prev) => !prev);
                              }}
                            >
                              <Text className={classes.registerBtnFont}>
                                Register
                              </Text>
                            </Button>
                            <Button
                              variant="filled"
                              color="yellow"
                              fullWidth
                              mt="md"
                              radius="md"
                              leftIcon={<IconEye />}
                              onClick={function () {
                                navigate(`event/${item.event_id}`);
                              }}
                            >
                              <Text className={classes.viewBtnFont}>View</Text>
                            </Button>
                          </Group>
                        ) : (
                          <>
                            <Button
                              variant="filled"
                              color="green"
                              fullWidth
                              mt="md"
                              radius="md"
                              leftIcon={<IconUserPlus />}
                              onClick={async function () {
                                const { data } = await supabase
                                  .from("bidder")
                                  .select()
                                  .filter("event_id", "eq", item.event_id)
                                  .order("bidder_no", { ascending: false })
                                  .limit(1);

                                if (data && data.length > 0) {
                                  const nextBidderNo = data[0].bidder_no + 1;
                                  await supabase.from("bidder").insert({
                                    uuid: user.id,
                                    bidder_no: nextBidderNo,
                                    event_id: item.event_id,
                                  });
                                  Notifications.show({
                                    color: "green",
                                    title: "Registration successful!",
                                    autoClose: 5000,
                                  });
                                } else {
                                  await supabase.from("bidder").insert({
                                    uuid: user.id,
                                    bidder_no: 1,
                                    event_id: item.event_id,
                                  });
                                  Notifications.show({
                                    color: "green",
                                    title: "Registration successful!",
                                    autoClose: 5000,
                                  });
                                }
                                // Update the state to trigger a refresh
                                setRefreshData((prev) => !prev);
                              }}
                            >
                              <Text className={classes.registerBtnFont}>
                                Register
                              </Text>
                            </Button>
                            <Button
                              variant="filled"
                              color="yellow"
                              fullWidth
                              mt="md"
                              radius="md"
                              leftIcon={<IconEye />}
                              onClick={function () {
                                navigate(`event/${item.event_id}`);
                              }}
                            >
                              <Text className={classes.viewBtnFont}>View</Text>
                            </Button>
                          </>
                        )}
                      </>
                    )}
                  </Card>
                </Grid.Col>
              ))}
            </Grid>
          ) : (
            <Paper shadow="md" radius="lg" p="xl">
              <Text>No ongoing auction at the moment.</Text>
            </Paper>
          )}
        </div>

        {/* Upcoming Auction */}
        <div className={classes.upcomingAuctionContainer}>
          <Text className={classes.upcomingAuctionTitle}>Upcoming Auction</Text>
          {upcomingData && upcomingData.length > 0 ? (
            <Grid grow={width < 1200}>
              {upcomingData.map((item, index) => (
                <Grid.Col span={4}>
                  <Card
                    shadow="sm"
                    padding="lg"
                    radius="md"
                    h="100%"
                    withBorder
                  >
                    <Card.Section>
                      <Image
                        src={
                          item.event_image_url
                            ? item.event_image_url
                            : "https://mmbgljfxwvdcgkjwhwre.supabase.co/storage/v1/object/public/Documents/Images/Image_not_available.png"
                        }
                        height={200}
                        alt="Event Image"
                        fit="contain"
                      />
                    </Card.Section>

                    <Group justify="space-between" mt="md" mb="xs">
                      <Text fw={500}>{item.name}</Text>
                      <Badge color="yellow" variant="light">
                        {convertTime(item.start_datetime)}
                      </Badge>
                    </Group>

                    <Text size="sm" c="dimmed">
                      {item.event_description
                        ? item.event_description
                        : "No Description"}
                    </Text>

                    {registeredEvents.includes(item.event_id) ? (
                      <>
                        {width > 1100 ? (
                          <Group grow>
                            <Button
                              variant="filled"
                              color="gray"
                              fullWidth
                              mt="md"
                              radius="md"
                              leftIcon={<IconUserCheck />}
                              disabled
                            >
                              <Text className={classes.registerBtnFont}>
                                Joined
                              </Text>
                            </Button>
                            <Button
                              variant="filled"
                              color="yellow"
                              fullWidth
                              mt="md"
                              radius="md"
                              leftIcon={<IconEye />}
                              onClick={function () {
                                navigate(`event/${item.event_id}`);
                              }}
                            >
                              <Text className={classes.viewBtnFont}>View</Text>
                            </Button>
                          </Group>
                        ) : (
                          <>
                            <Button
                              variant="filled"
                              color="gray"
                              fullWidth
                              mt="md"
                              radius="md"
                              leftIcon={<IconUserCheck />}
                              disabled
                            >
                              <Text className={classes.registerBtnFont}>
                                Joined
                              </Text>
                            </Button>
                            <Button
                              variant="filled"
                              color="yellow"
                              fullWidth
                              mt="md"
                              radius="md"
                              leftIcon={<IconEye />}
                              onClick={function () {
                                navigate(`event/${item.event_id}`);
                              }}
                            >
                              <Text className={classes.viewBtnFont}>View</Text>
                            </Button>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {width > 1100 ? (
                          <Group grow>
                            <Button
                              variant="filled"
                              color="green"
                              fullWidth
                              mt="md"
                              radius="md"
                              leftIcon={<IconUserPlus />}
                              onClick={async function () {
                                const { data } = await supabase
                                  .from("bidder")
                                  .select()
                                  .filter("event_id", "eq", item.event_id)
                                  .order("bidder_no", { ascending: false })
                                  .limit(1);

                                if (data && data.length > 0) {
                                  const nextBidderNo = data[0].bidder_no + 1;
                                  await supabase.from("bidder").insert({
                                    uuid: user.id,
                                    bidder_no: nextBidderNo,
                                    event_id: item.event_id,
                                  });
                                  Notifications.show({
                                    color: "green",
                                    title: "Registration successful!",
                                    autoClose: 5000,
                                  });
                                } else {
                                  await supabase.from("bidder").insert({
                                    uuid: user.id,
                                    bidder_no: 1,
                                    event_id: item.event_id,
                                  });
                                  Notifications.show({
                                    color: "green",
                                    title: "Registration successful!",
                                    autoClose: 5000,
                                  });
                                }
                                // Update the state to trigger a refresh
                                setRefreshData((prev) => !prev);
                              }}
                            >
                              <Text className={classes.registerBtnFont}>
                                Register
                              </Text>
                            </Button>
                            <Button
                              variant="filled"
                              color="yellow"
                              fullWidth
                              mt="md"
                              radius="md"
                              leftIcon={<IconEye />}
                              onClick={function () {
                                navigate(`event/${item.event_id}`);
                              }}
                            >
                              <Text className={classes.viewBtnFont}>View</Text>
                            </Button>
                          </Group>
                        ) : (
                          <>
                            <Button
                              variant="filled"
                              color="green"
                              fullWidth
                              mt="md"
                              radius="md"
                              leftIcon={<IconUserPlus />}
                              onClick={async function () {
                                const { data } = await supabase
                                  .from("bidder")
                                  .select()
                                  .filter("event_id", "eq", item.event_id)
                                  .order("bidder_no", { ascending: false })
                                  .limit(1);

                                if (data && data.length > 0) {
                                  const nextBidderNo = data[0].bidder_no + 1;
                                  await supabase.from("bidder").insert({
                                    uuid: user.id,
                                    bidder_no: nextBidderNo,
                                    event_id: item.event_id,
                                  });
                                  Notifications.show({
                                    color: "green",
                                    title: "Registration successful!",
                                    autoClose: 5000,
                                  });
                                } else {
                                  await supabase.from("bidder").insert({
                                    uuid: user.id,
                                    bidder_no: 1,
                                    event_id: item.event_id,
                                  });
                                  Notifications.show({
                                    color: "green",
                                    title: "Registration successful!",
                                    autoClose: 5000,
                                  });
                                }
                                // Update the state to trigger a refresh
                                setRefreshData((prev) => !prev);
                              }}
                            >
                              <Text className={classes.registerBtnFont}>
                                Register
                              </Text>
                            </Button>
                            <Button
                              variant="filled"
                              color="yellow"
                              fullWidth
                              mt="md"
                              radius="md"
                              leftIcon={<IconEye />}
                              onClick={function () {
                                navigate(`event/${item.event_id}`);
                              }}
                            >
                              <Text className={classes.viewBtnFont}>View</Text>
                            </Button>
                          </>
                        )}
                      </>
                    )}
                  </Card>
                </Grid.Col>
              ))}
            </Grid>
          ) : (
            <Paper shadow="md" radius="lg" p="xl">
              <Text>No upcoming auction at the moment.</Text>
            </Paper>
          )}
        </div>

        {/* Past Auctions */}
        <div className={classes.pastAuctionContainer}>
          <Text className={classes.pastAuctionTitle}>Past Auction</Text>
          {completedData && completedData.length > 0 ? (
            <Grid grow={width < 1200}>
              {completedData.map((item, index) => (
                <Grid.Col span={4}>
                  <Card
                    shadow="sm"
                    padding="lg"
                    radius="md"
                    h="100%"
                    withBorder
                  >
                    <Card.Section>
                      <Image
                        src={
                          item.event_image_url
                            ? item.event_image_url
                            : "https://mmbgljfxwvdcgkjwhwre.supabase.co/storage/v1/object/public/Documents/Images/Image_not_available.png"
                        }
                        height={200}
                        alt="Event Image"
                        fit="contain"
                      />
                    </Card.Section>

                    <Group justify="space-between" mt="md" mb="xs">
                      <Text fw={500}>{item.name}</Text>
                      <Badge color="gray" variant="light">
                        {convertTime(item.start_datetime)}
                      </Badge>
                    </Group>

                    <Text size="sm" c="dimmed">
                      {item.event_description
                        ? item.event_description
                        : "No Description"}
                    </Text>

                    <Button
                      variant="filled"
                      color="yellow"
                      fullWidth
                      mt="md"
                      radius="md"
                      leftIcon={<IconEye />}
                      onClick={function () {
                        navigate(`event/${item.event_id}`);
                      }}
                    >
                      <Text className={classes.viewBtnFont}>View</Text>
                    </Button>
                  </Card>
                </Grid.Col>
              ))}
            </Grid>
          ) : (
            <Paper shadow="md" radius="lg" p="xl">
              <Text>No past auction</Text>
            </Paper>
          )}
        </div>
      </Container>
    </div>
  );
}
