import { useAuth } from "./components/auth";
import { useEffect, useState } from "react";
import { supabase } from "./supabase/supabaseClient";
import HomePage from "./components/HomePage/HomePage";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";

function App() {
  const { user } = useAuth();
  const [status, setStatus] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      supabase
        .from("users")
        .select()
        .eq("uuid", user.id)
        .then((value) => {
          setStatus(value.data[0].status);
        });
    }
  }, []);

  // Once status state is ready then load Homepage or Mainpage. Else should be loading...
  if (status && user) {
    return (
      <div>
        {user.email_confirmed_at && status === "approved" ? (
          navigate("/front")
        ) : (
          <HomePage />
        )}
      </div>
    );
  } else if (!status && user) {
    return (
      <div>
        <LoadingScreen />
      </div>
    );
    // If No user is NULL:
  } else {
    return (
      <div>
        <HomePage />
      </div>
    );
  }
}

export default App;
