import {
  createStyles,
  Text,
  Container,
  ActionIcon,
  Group,
  rem,
  Anchor,
} from "@mantine/core";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconMail,
  IconPhone,
} from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  footer: {
    position: "relative",
    left: 0,
    bottom: 0,
    width: "100%",
    paddingTop: `calc(${theme.spacing.xl})`,
    paddingBottom: `calc(${theme.spacing.xl})`,
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  logo: {
    maxWidth: rem(200),
    [theme.fn.smallerThan("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },

  description: {
    marginTop: rem(5),
    marginLeft: rem(5),
    [theme.fn.smallerThan("sm")]: {
      marginTop: theme.spacing.xs,
      textAlign: "center",
    },
  },

  inner: {
    display: "flex",
    justifyContent: "space-between",

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },

  mapContainer: {
    marginTop: theme.spacing.md,
    width: "100%",
    height: "300px",
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  groups: {
    display: "flex",
    flexWrap: "wrap",

    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  wrapper: {
    width: rem(160),
  },

  link: {
    display: "block",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[6],
    fontSize: theme.fontSizes.sm,
    paddingTop: rem(3),
    paddingBottom: rem(3),

    "&:hover": {
      textDecoration: "underline",
    },
  },

  contact_text: {
    color: "black",
    fontSize: theme.fontSizes.sm,
    paddingTop: rem(0),
    paddingBottom: rem(0),
  },

  address_text: {
    color: "#1c7ed6",
    fontSize: theme.fontSizes.sm,
    fontWeight: 700,
    paddingTop: rem(1),
    paddingBottom: rem(0),
  },

  title: {
    fontSize: theme.fontSizes.lg,
    fontWeight: 700,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    marginBottom: `calc(${theme.spacing.xs} / 2)`,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },

  afterFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing.xl,
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
    },
  },

  social: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
}));

export default function Footer(props) {
  const { classes } = useStyles();
  const data = [
    {
      title: "About",
      links: [
        {
          label: "What We Do",
          link: "home/about-us",
        },
        {
          label: "FAQ",
          link: "home/faq",
        },
        {
          label: "Terms & Condition",
          link: "home/terms-and-condition",
        },
        {
          label: "Policies",
          link: "home/policies",
        },
      ],
    },
  ];
  const groups = data.map((group) => {
    const links = group.links.map((link, index) => (
      <Text key={index} className={classes.link} component="a" href={link.link}>
        {link.label}
      </Text>
    ));

    return (
      <div className={classes.wrapper} key={group.title}>
        <Text className={classes.title}>{group.title}</Text>
        {links}
      </div>
    );
  });

  return (
    <footer className={classes.footer}>
      <Container className={classes.inner}>
        <div className={classes.logo}>
          <img
            alt="Logo"
            style={{ width: 228, height: 53 }}
            src="https://i1.wp.com/assethub.sg/wp-content/uploads/2019/02/cropped-AssetHub-Logo-Rec-e1551345217372-1.png?fit=3299%2C802"
          />
          <Text size="xs" color="dimmed" className={classes.description}>
            UEN 201811649D
          </Text>
        </div>
        <div className={classes.groups}>{groups}</div>
        <div>
          <Text className={classes.title}>Contact</Text>
          <Text className={classes.contact_text}>
            80 Changi Road (Centropod@Changi)
          </Text>
          <Text className={classes.contact_text}>#02-58</Text>
          <Text className={classes.contact_text}>Singapore 419715</Text>
          <Text className={classes.address_text}>
            Open Monday-Friday, 9am - 5pm
          </Text>
          <div className={classes.mapContainer}>
            <iframe
              title="Google Map - AssetHub PTE LTD Office Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7653877703683!2d103.8956625917739!3d1.316348558265544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19def491201f%3A0x6f4a504972e241fd!2sAssetHub!5e0!3m2!1sen!2ssg!4v1689921366764!5m2!1sen!2ssg"
              width="300"
              height="300"
              allowfullscreen=""
              style={{ border: 0 }}
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </Container>
      <Container className={classes.afterFooter}>
        <Text color="dimmed" size="sm">
          ©{new Date().getFullYear()} ASSETHUB PTE LTD
        </Text>

        <Group spacing={0} className={classes.social} position="right">
          <IconPhone size="1.3rem" stroke={1.5} color="grey" />
          <Text size="1rem" ml="2px" mr="2rem" color="grey">
            6909 5357
          </Text>

          <IconMail size="1.3rem" stroke={1.5} color="grey" />
          <Text size="1rem" ml="2px" mr="2rem" color="grey">
            <Anchor href="mailto:info@assethubmarketplace.com">
              info@assethubmarketplace.com
            </Anchor>
          </Text>

          <ActionIcon size="lg">
            <IconBrandFacebook size="1.05rem" stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="lg">
            <IconBrandInstagram size="1.05rem" stroke={1.5} />
          </ActionIcon>
        </Group>
      </Container>
    </footer>
  );
}
