import { supabase } from "../../supabase/supabaseClient";
import { useEffect, useState } from "react";
import {
  Anchor,
  Text,
  ScrollArea,
  Table,
  Tooltip,
  ActionIcon,
  Group,
  Flex,
  Modal,
  TextInput,
  Select,
  NumberInput,
  Button,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import {
  IconPencil,
  IconSquareRoundedPlus,
  IconPercentage,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import { Notifications } from "@mantine/notifications";
import LoadingScreen from "../LoadingScreen/LoadingScreen";

function convertTime(time) {
  // UTC timestamp string
  var utcTimestamp = time;

  // Convert UTC timestamp to JavaScript Date object
  var utcDate = new Date(utcTimestamp);

  // Define Singapore timezone offset in minutes (UTC+8)
  var sgTimezoneOffset = 480;

  // Calculate Singapore timestamp by adding the timezone offset
  var sgTimestamp = new Date(utcDate.getTime() + sgTimezoneOffset * 60 * 1000);

  // Format Singapore timestamp as "dd-mm-yyyy HH:MM"
  var formattedTimestamp = sgTimestamp.toLocaleDateString("en-SG", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  // Output the result
  return formattedTimestamp;
}

export default function AllTable(props) {
  const [eventData, setEventData] = useState(null);
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);
  const [eventID, setEventID] = useState("");
  const [startDateTime, setStartDateTime] = useState(null);
  const [endDateTime, setEndDateTime] = useState(null);
  const [eventType, setEventType] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [gstPercentage, setGstPercentage] = useState(0);
  const [buyerPremium, setBuyerPremium] = useState(0);
  const [consignmentFee, setConsignmentFee] = useState(0);
  const [overtimeExtension, setOvertimeExtension] = useState(0);
  const [displayReservedNotMet, setDisplayReservedNotMet] = useState(false);

  useEffect(() => {
    const fetchEventData = async () => {
      setReload(true);
      const { data, error } = await supabase
        .from("event")
        .select()
        .eq("status", "void")
        .order("start_datetime", { ascending: true });
      if (data) {
        setEventData(data);
      }

      if (error) {
        setEventData(null);
      }
    };

    fetchEventData();
    setReload(false);
  }, [reload, props.fullReload]);

  if (eventData) {
    const rows = eventData.map((item) => (
      <tr key={item.event_id}>
        <td>
          <Anchor component="button" size="sm">
            {convertTime(item.start_datetime)}
          </Anchor>
        </td>
        <td>
          <Anchor component="button" size="sm">
            {convertTime(item.end_datetime)}
          </Anchor>
        </td>
        <td>
          <Anchor component="button" size="sm">
            {item.name}
          </Anchor>
        </td>
        <td>
          <Anchor component="button" size="sm">
            {item.event_type}
          </Anchor>
        </td>
        <td>
          <Anchor component="button" size="sm">
            {item.status}
          </Anchor>
        </td>
        <td>
          <Anchor component="button" size="sm">
            {item.gst_percentage}
          </Anchor>
        </td>
        <td>
          <Anchor component="button" size="sm">
            {item.buyer_premium_percentage}
          </Anchor>
        </td>
        <td>
          <Anchor component="button" size="sm">
            {item.consignment_fee_percentage}
          </Anchor>
        </td>
        <td>
          <Anchor component="button" size="sm">
            {item.overtime_extension_min}
          </Anchor>
        </td>
        <td>
          <Anchor component="button" size="sm">
            {item.display_reserved_not_met ? "Yes" : "No"}
          </Anchor>
        </td>
        <td>
          <Group spacing={0} position="right">
            <Flex justify="center" align="center" direction="row" gap="sm">
              <Tooltip label="Add items" color="green" openDelay={500}>
                <ActionIcon
                  onClick={function () {
                    navigate(`${item.event_id}`);
                  }}
                >
                  <IconSquareRoundedPlus size="1rem" stroke={1.5} />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Edit" color="yellow" openDelay={500}>
                <ActionIcon
                  onClick={function () {
                    open();
                    setEventID(item.event_id);
                    setStartDateTime(item.start_datetime);
                    setEndDateTime(item.end_datetime);
                    setName(item.name);
                    setEventType(item.event_type);
                    setStatus(item.status);
                    setGstPercentage(item.gst_percentage);
                    setBuyerPremium(item.buyer_premium_percentage);
                    setConsignmentFee(item.consignment_fee_percentage);
                    setOvertimeExtension(item.overtime_extension_min);
                    setDisplayReservedNotMet(item.display_reserved_not_met);
                  }}
                >
                  <IconPencil size="1rem" stroke={1.5} />
                </ActionIcon>
              </Tooltip>
            </Flex>
          </Group>
        </td>
      </tr>
    ));

    return (
      <div>
        <Modal opened={opened} onClose={close} title="Edit">
          <DateTimePicker
            withAsterisk
            required
            dropdownType="modal"
            label="Start Datetime (Leave empty if no change):"
            mx="auto"
            onChange={(e) => setStartDateTime(e)}
          />
          <DateTimePicker
            withAsterisk
            required
            dropdownType="modal"
            label="New End Datetime (Leave empty if no change):"
            mx="auto"
            onChange={(e) => setEndDateTime(e)}
          />
          <TextInput
            label="Event Name:"
            value={name}
            mb={5}
            withAsterisk
            required
            onChange={(e) => setName(e.target.value)}
          />
          <Select
            withAsterisk
            required
            defaultValue={eventType}
            label="Type:"
            pb={10}
            onChange={(e) => setEventType(e)}
            data={[
              { value: "online", label: "Online" },
              { value: "online_and_live", label: "Online and Live" },
            ]}
          />
          <Select
            withAsterisk
            required
            defaultValue={status}
            label="Status:"
            pb={10}
            onChange={(e) => setStatus(e)}
            data={[
              { value: "not_started", label: "Not Started" },
              { value: "in_progress", label: "Ongoing" },
              { value: "completed", label: "Completed" },
              { value: "void", label: "Void" },
            ]}
          />
          <Group>
            <NumberInput
              required
              label="GST:"
              withAsterisk
              hideControls
              defaultValue={gstPercentage}
              onChange={(e) => setGstPercentage(e)}
              rightSection={<IconPercentage size="2rem" stroke={1.5} />}
              maw={200}
            />
            <NumberInput
              required
              label="Buyer Premium:"
              withAsterisk
              hideControls
              defaultValue={buyerPremium}
              onChange={(e) => setBuyerPremium(e)}
              rightSection={<IconPercentage size="2rem" stroke={1.5} />}
              maw={200}
            />
          </Group>

          <NumberInput
            required
            label="Consignment Fee:"
            withAsterisk
            hideControls
            defaultValue={consignmentFee}
            onChange={(e) => setConsignmentFee(e)}
            rightSection={<IconPercentage size="2rem" stroke={1.5} />}
          />
          <NumberInput
            required
            label="Overtime Extension (Minute):"
            withAsterisk
            hideControls
            defaultValue={overtimeExtension}
            onChange={(e) => setOvertimeExtension(e)}
          />
          <Select
            withAsterisk
            required
            defaultValue={displayReservedNotMet}
            label="Display reserved price not met:"
            pb={10}
            onChange={(e) => setDisplayReservedNotMet(e)}
            data={[
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ]}
          />
          <Button
            onClick={async function () {
              const { error } = await supabase
                .from("event")
                .update({
                  name: name,
                  start_datetime: startDateTime,
                  end_datetime: endDateTime,
                  event_type: eventType,
                  status: status,
                  gst_percentage: gstPercentage,
                  buyer_premium_percentage: buyerPremium,
                  consignment_fee_percentage: consignmentFee,
                  overtime_extension_min: overtimeExtension,
                  display_reserved_not_met: displayReservedNotMet,
                })
                .eq("event_id", eventID);

              Notifications.show({
                color: "green",
                title: "Edit saved.",
                autoClose: 5000,
              });
              close();
              props.reload();
              setReload(true);
            }}
            radius="lg"
            fullWidth
          >
            Save
          </Button>
        </Modal>

        <ScrollArea>
          <Text fz="xl" fw={700} mb={25}>
            All Events
          </Text>
          <Table
            sx={{ minWidth: 800 }}
            verticalSpacing="sm"
            withBorder
            withColumnBorders
            highlightOnHover
          >
            <thead>
              <tr>
                <th>Start Datetime</th>
                <th>End Datetime</th>
                <th>Name</th>
                <th>Type</th>
                <th>Status</th>
                <th>GST %</th>
                <th>Buyer Premium %</th>
                <th>Consignment Fee %</th>
                <th>Overtime Extension (Min)</th>
                <th>Display Reserved Price Not Met</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </ScrollArea>
      </div>
    );
  } else {
    return (
      <div>
        <LoadingScreen />
      </div>
    );
  }
}
