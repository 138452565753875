import Navbar from "../MainPage/Navbar";
import {
  Tabs,
  Badge,
  Group,
  Paper,
  Button,
  Modal,
  Container,
} from "@mantine/core";
import AllTable from "./AllTable";
import AddEvent from "./AddEvent";
import OngoingTable from "./OngoingTable";
import NotStartedTable from "./NotStartedTable";
import CompletedTable from "./CompletedTable";
import VoidTable from "./VoidTable";
import { useState, useEffect } from "react";
import { supabase } from "../../supabase/supabaseClient";

export default function EventManagement(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ongoingNo, setOngoingNo] = useState(0);
  const [reload, setReload] = useState(false);
  // State to track changes in AllTable component
  const [allTableChanges, setAllTableChanges] = useState(0);

  // Callback function to trigger when changes occur in AllTable
  const handleAllTableChanges = () => {
    // Increment the changes count
    setAllTableChanges(allTableChanges + 1);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const setReloadTrue = () => {
    setReload(true);
  };

  useEffect(() => {
    const ongoingCount = async () => {
      const { data } = await supabase
        .from("event")
        .select("*")
        .eq("status", "in_progress");

      if (data) {
        setOngoingNo(data.length);
      }
    };

    ongoingCount();
    setReload(false);
  }, [reload]);

  return (
    <div>
      <Navbar />
      <Modal
        opened={isModalOpen}
        onClose={closeModal}
        title="New Event"
        closeOnClickOutside={false}
      >
        <AddEvent closeModal={closeModal} />
      </Modal>
      <Container size="xl" p={40}>
        <Paper shadow="lg" p="md" radius="md" withBorder>
          <Group position="right">
            <Button mb={50} onClick={openModal}>
              Add Event
            </Button>
          </Group>
          <Tabs defaultValue="all">
            <Tabs.List>
              <Tabs.Tab value="all">All</Tabs.Tab>
              <Tabs.Tab value="not_started">Not Started</Tabs.Tab>
              <Tabs.Tab
                rightSection={
                  <Badge
                    w={16}
                    h={16}
                    sx={{ pointerEvents: "none" }}
                    variant="filled"
                    size="xs"
                    p={0}
                  >
                    {ongoingNo}
                  </Badge>
                }
                value="in_progress"
              >
                Ongoing
              </Tabs.Tab>
              <Tabs.Tab value="completed">Completed</Tabs.Tab>
              <Tabs.Tab value="void">Void</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="all" pt="xs">
              <AllTable reload={setReloadTrue} fullReload={reload} />
            </Tabs.Panel>
            <Tabs.Panel value="not_started" pt="xs">
              <NotStartedTable reload={setReloadTrue} fullReload={reload} />
            </Tabs.Panel>
            <Tabs.Panel value="in_progress" pt="xs">
              <OngoingTable reload={setReloadTrue} fullReload={reload} />
            </Tabs.Panel>
            <Tabs.Panel value="completed" pt="xs">
              <CompletedTable />
            </Tabs.Panel>
            <Tabs.Panel value="void" pt="xs">
              <VoidTable reload={setReloadTrue} fullReload={reload} />
            </Tabs.Panel>
          </Tabs>
        </Paper>
      </Container>
    </div>
  );
}
