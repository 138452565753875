import Navbar from "../MainPage/Navbar";
import { Tabs, Container, Paper } from "@mantine/core";
import {
  IconUserCancel,
  IconUserCheck,
  IconUserQuestion,
} from "@tabler/icons-react";
import { IconUserX } from "@tabler/icons-react";
import PendingTable from "./PendingTable";
import ApprovedTable from "./ApproveTable";
import RejectTable from "./RejectTable";
import BlacklistTable from "./BlacklistTable";
import { useState } from "react";

export default function UserManagement(props) {
  return (
    <div>
      <Navbar />
      <Container size="xl" p={30}>
        <Paper shadow="lg" p="md" radius="md">
          <Tabs defaultValue="pending">
            <Tabs.List>
              <Tabs.Tab value="pending">Pending</Tabs.Tab>
              <Tabs.Tab value="approved">Approved</Tabs.Tab>
              <Tabs.Tab value="rejected">Rejected</Tabs.Tab>
              <Tabs.Tab value="blacklisted">Blacklisted</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="pending" pt="xs">
              <PendingTable />
            </Tabs.Panel>
            <Tabs.Panel value="approved" pt="xs">
              <ApprovedTable />
            </Tabs.Panel>
            <Tabs.Panel value="rejected" pt="xs">
              <RejectTable />
            </Tabs.Panel>
            <Tabs.Panel value="blacklisted" pt="xs">
              <BlacklistTable />
            </Tabs.Panel>
          </Tabs>
        </Paper>
      </Container>
    </div>
  );
}
