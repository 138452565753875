import {
  createStyles,
  Header,
  Group,
  Button,
  Divider,
  Box,
  Burger,
  Drawer,
  ScrollArea,
  rem,
  Menu,
  Center,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useAuth } from "../auth";
import { useEffect, useState } from "react";
import { supabase } from "../../supabase/supabaseClient";
import { useNavigate } from "react-router-dom";
import { IconChevronDown, IconLogout } from "@tabler/icons-react";
import { Notifications } from "@mantine/notifications";

const useStyles = createStyles((theme) => ({
  link: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: "none",
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,

    [theme.fn.smallerThan("sm")]: {
      height: rem(42),
      display: "flex",
      alignItems: "center",
      width: "100%",
    },

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    }),
  },

  hover_link: {
    display: "block",
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  linkLabel: {
    marginRight: rem(5),
  },

  hiddenMobile: {
    "@media (max-width: 1270px)": {
      display: "none",
    },
  },

  hiddenDesktop: {
    "@media (min-width: 1271px)": {
      display: "none",
    },
  },
}));

export default function Navbar(props) {
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const { classes, theme } = useStyles();
  const { signOut } = useAuth();
  const [employee, setEmployee] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    supabase.auth
      .getSession()
      .then((user) =>
        // Extract address data from profiles database
        supabase.from("users").select().eq("uuid", user.data.session.user.id)
      )
      .then((value) => {
        setEmployee(value.data[0].employee_bool);
      });
  }, []);

  // Sign out function
  async function handleSignOut() {
    try {
      const { error } = await signOut();
      if (error) {
        alert(error);
      } else {
        navigate("/");
        Notifications.show({
          color: "orange",
          title: "Logged out successfully!",
          autoClose: 3000,
          icon: <IconLogout />,
        });
      }
    } catch (error) {
      alert(error);
    }
  }

  if (employee) {
    return (
      <div>
        <Box pb={0}>
          <Header height={60} px="md">
            <Group position="apart" sx={{ height: "100%" }}>
              <img
                alt="Logo"
                style={{ width: 228, height: 53 }}
                src="https://i1.wp.com/assethub.sg/wp-content/uploads/2019/02/cropped-AssetHub-Logo-Rec-e1551345217372-1.png?fit=3299%2C802"
              />
              <Group
                sx={{ height: "100%" }}
                spacing={0}
                className={classes.hiddenMobile}
              >
                <a href="/front" className={classes.link}>
                  Event
                </a>
                <a href="/front/event-management" className={classes.link}>
                  Event Management
                </a>
                <a href="/front/user-management" className={classes.link}>
                  User Management
                </a>
                <a href="/" className={classes.link}>
                  Analytics
                </a>
                <Menu trigger="hover" openDelay={100} closeDelay={400}>
                  <Menu.Target>
                    <a
                      onClick={(event) => event.preventDefault()}
                      className={classes.link}
                      href="/"
                    >
                      <Center>
                        <span
                          className={classes.link}
                          style={{ paddingRight: "10px" }}
                        >
                          Invoice Management
                        </span>
                        <IconChevronDown size={rem(12)} stroke={1.5} />
                      </Center>
                    </a>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item
                      className={classes.link}
                      onClick={() =>
                        navigate("/front/seller-invoice-management")
                      }
                    >
                      For Seller
                    </Menu.Item>
                    <Menu.Item
                      className={classes.link}
                      onClick={() =>
                        navigate("/front/buyer-invoice-management")
                      }
                    >
                      For Buyer
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>

                <a href="/" className={classes.link}>
                  Settings
                </a>
              </Group>

              <Group className={classes.hiddenMobile}>
                <Button onClick={handleSignOut} color="red">
                  Log Out
                </Button>
              </Group>

              <Burger
                opened={drawerOpened}
                onClick={toggleDrawer}
                className={classes.hiddenDesktop}
              />
            </Group>
          </Header>

          <Drawer
            opened={drawerOpened}
            onClose={closeDrawer}
            size="100%"
            padding="md"
            title="Navigation"
            className={classes.hiddenDesktop}
            zIndex={1000000}
          >
            <ScrollArea h={`calc(100vh - ${rem(60)})`} mx="-md">
              <Divider
                my="sm"
                color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
              />
              <a href="/front" className={classes.link}>
                Event
              </a>
              <a href="/front/event-management" className={classes.link}>
                Event Management
              </a>
              <a href="/front/user-management" className={classes.link}>
                User Management
              </a>
              <a href="/" className={classes.link}>
                Analytics
              </a>
              <a href="/front/invoice-management" className={classes.link}>
                Invoice Management
              </a>
              <a href="/" className={classes.link}>
                Settings
              </a>

              <Divider
                my="sm"
                color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
              />

              <Group position="center" grow pb="xl" px="md">
                <Button onClick={handleSignOut} color="red">
                  Log Out
                </Button>
              </Group>
            </ScrollArea>
          </Drawer>
        </Box>
      </div>
    );
  } else if (employee === false) {
    return (
      <div>
        <Box pb={30}>
          <Header height={60} px="md">
            <Group position="apart" sx={{ height: "100%" }}>
              <img
                alt="Logo"
                style={{ width: 228, height: 53 }}
                src="https://i1.wp.com/assethub.sg/wp-content/uploads/2019/02/cropped-AssetHub-Logo-Rec-e1551345217372-1.png?fit=3299%2C802"
              />
              <Group
                sx={{ height: "100%" }}
                spacing={0}
                className={classes.hiddenMobile}
              >
                <a href="/front" className={classes.link}>
                  Events
                </a>
                <a href="/" className={classes.link}>
                  Watchlist
                </a>
                <a href="/" className={classes.link}>
                  Contact Us
                </a>
                <a href="/" className={classes.link}>
                  Settings
                </a>
              </Group>

              <Group className={classes.hiddenMobile}>
                <Button onClick={handleSignOut} color="red">
                  Log Out
                </Button>
              </Group>

              <Burger
                opened={drawerOpened}
                onClick={toggleDrawer}
                className={classes.hiddenDesktop}
              />
            </Group>
          </Header>

          <Drawer
            opened={drawerOpened}
            onClose={closeDrawer}
            size="100%"
            padding="md"
            title="Navigation"
            className={classes.hiddenDesktop}
            zIndex={1000000}
          >
            <ScrollArea h={`calc(100vh - ${rem(60)})`} mx="-md">
              <Divider
                my="sm"
                color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
              />

              <a href="/front" className={classes.link}>
                Events
              </a>
              <a href="/" className={classes.link}>
                Watchlist
              </a>
              <a href="/" className={classes.link}>
                Contact Us
              </a>
              <a href="/" className={classes.link}>
                Settings
              </a>

              <Divider
                my="sm"
                color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
              />

              <Group position="center" grow pb="xl" px="md">
                <Button onClick={handleSignOut} variant="default">
                  Log Out
                </Button>
              </Group>
            </ScrollArea>
          </Drawer>
        </Box>
      </div>
    );
  }
}
