import classes from "./style.module.css";
import { Title, Text, Center } from "@mantine/core";
import { useAuth } from "../auth";
import HomePageNavBar from "../HomePage/Navbar";
import MainPageNavbar from "../MainPage/Navbar";

export default function AboutUs(props) {
  const { user } = useAuth();
  return (
    <div>
      {user ? <MainPageNavbar /> : <HomePageNavBar />}
      <div className={classes.clientContainer}>
        <div className={classes.aboutUsContainer}>
          <Title>About Us</Title>
          <Text>
            We are an auction and marketplace platform that seamlessly merges
            the timeless allure of auctions with the convenience of online
            commerce.
          </Text>
          <Text>
            Beyond a mere marketplace, we are a distinguished community founded
            on principles of integrity, reliability, and the pursuit of
            unparalleled quality.
          </Text>
        </div>

        <div className={classes.ourClientsContainer}>
          <Title className={classes.title}>Our clients</Title>
          <div className={classes.clientImagesContainer}>
            <img
              className={classes.clientImages}
              src="https://mmbgljfxwvdcgkjwhwre.supabase.co/storage/v1/object/public/Documents/Others/st-engineering.png"
              alt="ST Engineering Logo"
            />
            <img
              className={classes.clientImages}
              src="https://mmbgljfxwvdcgkjwhwre.supabase.co/storage/v1/object/public/Documents/Others/mindeflogo.jpeg"
              alt="MINDEF Logo"
            />
          </div>
        </div>

        <div className={classes.ourServicesContainer}>
          <Title pb={10}>Our Services</Title>

          <Text>
            <Text fw={700}>Dynamic Auction Platform</Text>Immerse yourself in
            the excitement of bidding on a diverse range of items, from rare
            collectibles to everyday essentials.
          </Text>
          <Text>
            Our user-friendly auction platform ensures a seamless and engaging
            bidding process.
          </Text>

          <Text mt={10}>
            <Text fw={700}>Curated Marketplace</Text>Explore an unparalleled
            selection of unique treasures, carefully curated to cater to the
            tastes of discerning buyers.
          </Text>
          <Text>
            Our marketplace is a haven for those seeking quality and
            exclusivity.
          </Text>
        </div>
      </div>
    </div>
  );
}
