import { HashLoader } from "react-spinners";
import { Center, Text } from "@mantine/core";

export default function LoadingScreen(props) {
  return (
    <Center
      style={{
        height: "100vh",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <Text fz={"lg"}>Please Wait...</Text>
        <HashLoader
          color="#3693d6"
          speedMultiplier={1}
          cssOverride={{ display: "block", margin: "0 auto" }}
        />
      </div>
    </Center>
  );
}
